// @flow
import clsx from 'clsx'
import * as React from 'react'
import { Row, Accordion, Button, Col } from 'react-bootstrap'

export type RowedAccordionProps = {
  cols?: number,
  itemsPerRow?: number,
  buttonIcon: 'plus-minus' | 'chevron' | 'element-inside',
  className?: any,
  items: Array<{
    content: React$Node | string,
    title: React$Node | string,
    name: string,
  }>,
  ...
}

const RowedAccordion = ({
  cols,
  items,
  buttonIcon,
  itemsPerRow: rows,
  ...restProps
}: RowedAccordionProps): React$Element<typeof Accordion> => {
  const [keyState, setKeyState] = React.useState(null)
  const clonedItems = [...items]
  const itemsPerRow = !!rows ? rows : parseInt((clonedItems.length / (!!cols ? cols : 2)).toFixed())

  const renderItem = ({ content, title, name }) => (
    <Col sm={12} key={name} className="accordion-item">
      <Accordion.Toggle
        as={Button}
        variant="accordion"
        eventKey={name}
        className={clsx(buttonIcon, keyState === name && 'collapsed')}>
        {title}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={name}>
        <div className="accordion-item__content">{content}</div>
      </Accordion.Collapse>
    </Col>
  )

  return (
    <Accordion
      {...restProps}
      as={Row}
      activeKey={keyState}
      onSelect={setKeyState}
      className={clsx('align-items-start', 'mt-4', restProps?.className)}>
      {itemsPerRow < clonedItems.length
        ? new Array(Math.ceil(clonedItems.length / itemsPerRow)).fill().map((_, key) => (
            <Col as={Row} key={key} noGutters md={!!cols ? 12 / cols : 2}>
              {clonedItems.splice(0, itemsPerRow).map(renderItem)}
            </Col>
          ))
        : clonedItems.map(renderItem)}
    </Accordion>
  )
}

RowedAccordion.defaultProps = ({
  buttonIcon: 'chevron',
  items: [],
}: RowedAccordionProps)

Accordion.Rowed = RowedAccordion

export default Accordion
