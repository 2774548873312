// @flow
import * as React from 'react'
import clsx from 'clsx'
import * as yup from 'yup'
import { has, keys } from 'lodash'
import { Form } from 'components'
import { useForm } from 'react-hook-form'
import { Button, Col } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup'
import { CSSTransition } from 'react-transition-group'
import { useMediaQuery } from 'react-responsive'

import styles from './style.module.scss'

const schema = yup.object().shape({
  password: yup
    .string()
    .min(8, 'Minimal 8 karakter')
    .matches(/^(?=.*[a-z])(?=.*[A-Z]).{1,}$/, {
      name: 'lettercases',
      message: 'Gunakan huruf kapital & kecil (contoh: Aa)',
    })
    .matches(/^(?=.*[0-9]).{1,}$/, {
      name: 'numeric',
      message: 'Gunakan angka (contoh: 1234)',
    })
    .matches(/^(?=.*[~!@#$%^&*()]).{1,}$/, {
      name: 'symbols',
      message: 'Gunakan simbol (contoh: !@#$)',
    }),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Ulangi Kata Sandi harus sama dengan Kata Sandi.'),
})

export const SetPassword = ({
  layoutPath,
  parentPath,
  history,
  title,
  description,
  onSubmit,
}: any): React$Element<'section'> => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const [visiblePasswordCriteria, setVisiblePasswordCriteria] = React.useState(false)
  const { register, handleSubmit, errors, formState, watch } = useForm({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(schema),
  })

  React.useEffect(() => {
    if (!visiblePasswordCriteria && errors.password) setVisiblePasswordCriteria(true)
  }, [errors.password, visiblePasswordCriteria])

  return (
    <section className={clsx('container', 'py-4', styles.container)}>
      <div className="headings">
        <h1 className="display-1">{title}</h1>
        <p className="mt-3 mb-1">{description}</p>
      </div>
      <Form noValidate className={clsx(styles.form)} onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <Form.Input
            hasGrow
            password
            hasValidation
            ref={register}
            name="password"
            label="Kata Sandi"
            isInvalid={errors.password}
            isValid={!has(errors, 'password_confirmation') && !!watch('password')}
            formGroupProps={{ as: Col, sm: 12, className: clsx(visiblePasswordCriteria && 'mb-2') }}
          />

          <CSSTransition
            unmountOnExit
            timeout={500}
            in={visiblePasswordCriteria}
            classNames="transition-fade">
            <Col sm={12}>
              <ul className={clsx('cross-list', 'lg-icon')}>
                {keys(schema.fields.password.exclusiveTests).map(test => (
                  <li
                    key={test}
                    className={clsx(
                      'font-weight-light',
                      !errors.password && 'checked',
                      errors.password && !keys(errors.password.types).includes(test)
                        ? 'checked'
                        : ''
                    )}>
                    {
                      schema.fields.password.tests.find(t => t.OPTIONS.name === test).OPTIONS
                        .message
                    }
                  </li>
                ))}
              </ul>
            </Col>
          </CSSTransition>

          <Form.Input
            hasGrow
            password
            hasValidation
            ref={register}
            name="password_confirmation"
            label="Ulangi Kata Sandi"
            isInvalid={errors.password_confirmation}
            isValid={!has(errors, 'password_confirmation') && formState.isValid}
            formGroupProps={{ as: Col, sm: 12, className: clsx('mt-4') }}
            feedbackMessage={
              errors.password_confirmation
                ? errors.password_confirmation.message
                : 'Kata sandi memenuhi semua kriteria'
            }
          />
          <Col sm={12} xs={12} className="mt-2">
            <Button
              variant="coral"
              type="submit"
              disabled={!formState.isValid}
              block={isTabletOrMobile}>
              Selesai
            </Button>
          </Col>
        </Form.Row>
      </Form>
    </section>
  )
}

SetPassword.defaultProps = {
  title: 'Title',
  description: 'Silahkan membuat kata sandi untuk akun EBClick Anda.',
  onSubmit: console.info,
}

export default SetPassword
