// @flow
import * as React from 'react'
import { SetPassword as BaseSetPassword } from 'views'

export const Password = (props: any): React$Element<typeof BaseSetPassword> => (
  <BaseSetPassword
    {...props}
    title="Atur Ulang Kata Sandi"
    onSubmit={data => props.history.push('/employer/home')}
  />
)

export default Password
