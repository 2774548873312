/* eslint-disable no-empty-pattern */
// @flow
import clsx from 'clsx'
import * as React from 'react'
import { DataTable, Form, Tabs } from 'components'
import { Button, Col, Container, Row, Tab } from 'react-bootstrap'

// Styles
import styles from './style.module.scss'

export type ConversionProps = { ... }

export const Conversion = ({}: ConversionProps): React$Element<'section'> => {
  const dplkConversion = {
    headers: [
      { title: 'Tanggal', name: 'date' },
      { title: 'Beli', name: 'bought' },
      { title: 'Jual', name: 'sell' },
    ],
    data: [
      [
        { content: 1, name: 'id' },
        {
          content: '20/11/2020',
          name: 'date',
        },
        {
          content: '13,721.00',
          name: 'bought',
        },
        {
          content: '14,539.00',
          name: 'sell',
        },
      ],
      [
        { content: 2, name: 'id' },
        {
          content: '19/11/2020',
          name: 'date',
        },
        {
          content: '13,712.00',
          name: 'bought',
        },
        {
          content: '14,519.00',
          name: 'sell',
        },
      ],
    ],
  }

  const groupSavingsConversion = {
    headers: [
      { title: 'Tanggal', name: 'date' },
      { title: 'Beli', name: 'bought' },
      { title: 'Jual', name: 'sell' },
    ],
    data: [
      [
        { content: 1, name: 'id' },
        {
          content: '19/11/2020',
          name: 'date',
        },
        {
          content: '13,712.00',
          name: 'bought',
        },
        {
          content: '14,519.00',
          name: 'sell',
        },
      ],
      [
        { content: 2, name: 'id' },
        {
          content: '20/11/2020',
          name: 'date',
        },
        {
          content: '13,721.00',
          name: 'bought',
        },
        {
          content: '14,539.00',
          name: 'sell',
        },
      ],
    ],
  }

  const [filterDate, setFilterDate] = React.useState({
    from: null,
    to: null,
  })

  return (
    <Container as="section" className={clsx(styles.wrapper, 'mb-5')} fluid>
      <h1 className="font-weight-light display-2 mb-4">
        Nilai Tukar <span className="font-serif">USD-IDR</span>
      </h1>
      <Row className={clsx('mt-3 mb-5')}>
        <Col md={7}>
          <p className="pr-5 mr-5 font-weight-light">
            Enim sagittis risus, diam faucibus a tristique justo, vitae, laoreet. Dui eu, urna
            tellus vitae felis mauris. Molestie purus purus turpis urna ut at sit quam.
          </p>
        </Col>
      </Row>
      <Tabs id="conversion" className={clsx(styles.tabs)} responsiveMode="dropdown">
        <Tab eventKey="dplk" title="Nilai Tukar DPLK">
          <Row className="py-4 justify-content-end">
            <Col md={6} className={clsx('py-2')}>
              <p className="w-100">
                A volutpat massa id interdum facilisis dui amet lectus. Tellus nisl neque, et et
                quam non nibh nulla. Mattis a sed gravida tristique.
              </p>
            </Col>
            <Col md={6} className={clsx('py-2')}>
              <Row className={clsx('align-items-start')}>
                <Col md={8}>
                  <Form.DateRangePicker
                    hasGrow
                    value={filterDate}
                    onChange={setFilterDate}
                    fromInputProps={{ label: 'Dari', placeholder: 'Pilih Tanggal' }}
                    toInputProps={{ label: 'Hingga', placeholder: 'Pilih Tanggal' }}
                  />
                  <div className="d-flex py-2">
                    <span className="text-sm mt-2 d-block">Maksimal periode 1 (satu) tahun</span>
                    <Button
                      variant="icon"
                      className={clsx('ml-auto')}
                      onClick={() =>
                        setFilterDate({
                          from: null,
                          to: null,
                        })
                      }>
                      <i className={clsx('mi mi-reload')}></i> Ulangi
                    </Button>
                  </div>
                </Col>
                <Col className={clsx('d-flex')}>
                  <Button variant="coral" block>
                    Unduh
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col md={12} className={clsx('mt-4')}>
              <DataTable {...dplkConversion} responsiveMode="table" className={clsx('w-100')} />
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="groupSavings" title="Nilai Tukar Group Saving">
          <Row className="py-4 justify-content-end">
            <Col md={6} className={clsx('py-2')}>
              <p className="w-100">
                A volutpat massa id interdum facilisis dui amet lectus. Tellus nisl neque, et et
                quam non nibh nulla. Mattis a sed gravida tristique.
              </p>
            </Col>
            <Col md={6} className={clsx('py-2')}>
              <Row className={clsx('align-items-start')}>
                <Col md={8}>
                  <Form.DateRangePicker
                    hasGrow
                    value={filterDate}
                    onChange={setFilterDate}
                    fromInputProps={{ label: 'Dari', placeholder: 'Pilih Tanggal' }}
                    toInputProps={{ label: 'Hingga', placeholder: 'Pilih Tanggal' }}
                  />
                  <div className="d-flex py-2">
                    <span className="text-sm mt-2 d-block">Maksimal periode 1 (satu) tahun</span>
                    <Button
                      variant="icon"
                      className={clsx('ml-auto')}
                      onClick={() =>
                        setFilterDate({
                          from: null,
                          to: null,
                        })
                      }>
                      <i className={clsx('mi mi-reload')}></i> Ulangi
                    </Button>
                  </div>
                </Col>
                <Col className={clsx('d-flex')}>
                  <Button variant="coral" block>
                    Unduh
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col md={12} className={clsx('mt-4')}>
              <DataTable
                {...groupSavingsConversion}
                responsiveMode="table"
                className={clsx('w-100')}
              />
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </Container>
  )
}

export default Conversion
