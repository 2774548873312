// @flow
import About from './About'
import Features from './Features'

// Types
export type * from './About'
export type * from './Features'

const Guides = {
  About: About,
  Features: Features,
}

export default Guides
