// @flow
import clsx from 'clsx'
import * as React from 'react'
import Form from '..'

export type CheckboxProps = {
  id?: string,
  name?: string,
  checked?: boolean,
  formGroupProps?: any,
  label?: React$Node | string,
  variant?: string,
  className?: string | Array<any> | Object,
  onChange?: (event: SyntheticEvent<HTMLInputElement>) => void,
  ...
}

export const Checkbox: React.AbstractComponent<CheckboxProps, mixed> = React.forwardRef(
  ({ id, className, formGroupProps, label, name, variant, ...restProps }, ref) => {
    return (
      <Form.Group {...formGroupProps}>
        <Form.Check.Label htmlFor={id} className={clsx(!!variant && `-${variant}`, className)}>
          <Form.Check.Input {...restProps} id={id} ref={ref} name={name} type="checkbox" />
          <span className="indicator"></span>
          <span className="indicator__back"></span>
          {!!label && <span className={clsx('description')}>{label}</span>}
        </Form.Check.Label>
      </Form.Group>
    )
  }
)
;(Checkbox: typeof Form.Group).defaultProps = ({}: CheckboxProps)

export default Checkbox
