// @flow
/* eslint-disable no-use-before-define */
import * as React from 'react'
import { Redirect } from 'react-router'
import { Employee, Employer, Guides } from 'pages'

export type Route = {
  component?: React.Node | (any => React$Element<any>),
  exact?: boolean,
  routes?: Routes,
  asLayout?: boolean,
}

export type Routes = {
  [path: string]: Route,
}

const routes: Routes = {
  [Guides.pathname]: Guides.route,
  [Employee.pathname]: Employee.route,
  [Employer.pathname]: Employer.route,
  '/': {
    component: () => <Redirect to={'/employee'} />,
  },
}

export default routes
