/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable no-empty-pattern */
// @flow
import clsx from 'clsx'
import * as React from 'react'
import * as yup from 'yup'
import CountUp from 'react-countup'
import { Link, useParams } from 'react-router-dom'
import { Billboard, Accordion, Form, Badge, StatusText, Pagination } from 'components'
import {
  Col,
  Container,
  Row,
  Button,
  Card,
  ListGroup,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { has } from 'lodash'

// Styles
// import styles from './style.module.scss'

const schema = yup.object().shape({
  attendee_id: yup.number().required().integer(),
})

export type BallanceProps = { ... }

export const Ballance = ({}: BallanceProps): React$Element<'section'> => {
  const { slug } = useParams()
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })
  const initialSearchBy = {
    'company-account': 'total',
    'attendee-account': '',
  }

  const [searchBy, setSearchBy] = React.useState(initialSearchBy[slug])

  const { register, errors, formState } = useForm({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(schema),
  })

  const meta = {
    'company-account': {
      title: (
        <>
          Informasi Saldo <span className="font-serif">Perusahaan</span>
        </>
      ),
      form: (
        <>
          <Form.Select
            value={searchBy}
            onChange={setSearchBy}
            label="Cari Dokumen Berdasarkan"
            placeholder="Pilih Jenis Laporan"
            formGroupProps={{ className: clsx('w-100', 'mb-4 pr-4 pr-md-5') }}
            menuProps={{ className: clsx('w-100') }}
            items={[
              { key: 'total', value: 'Total' },
              { key: 'per_contribution_type', value: 'Per Tipe Kontribusi' },
              { key: 'per_investation_type', value: 'Per Tipe Investasi' },
            ]}
          />
          <Form.DatePicker
            hasGrow
            label="Sampai Dengan Tanggal"
            placeholder="Pilih Tanggal"
            formGroupProps={{ className: clsx('w-100', 'mb-4 pr-4 pr-md-5') }}
          />
        </>
      ),
      documents: [
        {
          label: 'Saldo Rekening_PT-Sejahtera per Investment -010121',
          type: 'XLS',
          date: '24/01/20',
          status: {
            type: 'success',
            label: 'Siap diunduh',
          },
        },
        {
          label: 'Saldo Rekening_PT-Sejahtera per Total- 281220',
          type: 'PDF',
          date: '24/01/20',
          status: {
            type: 'danger',
            label: 'Gagal diproses',
          },
        },
      ],
      faqs: [
        {
          name: 'process',
          title: 'Berapa lama waktu proses dokumen?',
          content: (
            <p>
              In labore ut cupidatat mollit pariatur eu. Sit consequat magna pariatur sunt minim
              sint fugiat sint deserunt aliqua ex sit minim. Eiusmod proident nostrud occaecat
              deserunt ea nisi amet excepteur velit. Commodo aute labore commodo ullamco in
              incididunt ut ex. Esse sunt ipsum enim et pariatur qui eu irure aliquip mollit ex
              excepteur do.
            </p>
          ),
        },
        {
          name: 'difference',
          title: 'Apa perbedaan Saldo Investasi dan Tipe Kontribusi?',
          content: (
            <p>
              Incididunt eu id proident nostrud sunt adipisicing sunt. Laboris aute Lorem aliqua
              voluptate non ipsum nisi est. Et voluptate ex sit laborum.
            </p>
          ),
        },
        {
          name: 'checking',
          title: 'Bagaimana cara cek saldo per peserta?',
          content: (
            <p>
              Eiusmod tempor cupidatat voluptate est quis. Et nostrud velit tempor culpa velit in ad
              ex ut aliqua. Cupidatat occaecat ipsum Lorem voluptate. Eu excepteur incididunt tempor
              incididunt enim. Consequat nisi sint sunt pariatur duis ex sit mollit nulla quis. In
              fugiat adipisicing magna incididunt.
            </p>
          ),
        },
      ],
    },
    'attendee-account': {
      title: (
        <>
          Informasi Saldo <span className="font-serif">Peserta</span>
        </>
      ),
      form: (
        <>
          <Form.Input
            hasGrow
            type="text"
            placeholder="contoh: 1234567"
            label="Masukkan Nomor Peserta*"
            formGroupProps={{ className: clsx('w-100', 'mb-4 pr-4 pr-md-5') }}
            hasValidation
            ref={register}
            name="attendee_id"
            isInvalid={errors.attendee_id}
            isValid={!has(errors, 'attendee_id') && formState.isValid}
            feedbackMessage={errors.attendee_id && '*Nomor Peserta Tidak Terdaftar'}
            append={
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={
                  <Popover>
                    <Popover.Content>
                      Est consectetur sit est enim voluptate ex cupidatat non.
                    </Popover.Content>
                  </Popover>
                }>
                <i className="mi-info"></i>
              </OverlayTrigger>
            }
          />
          <Form.DatePicker
            hasGrow
            label="Sampai Dengan Tanggal"
            placeholder="Pilih Tanggal"
            formGroupProps={{ className: clsx('w-100', 'mb-4 pr-4 pr-md-5') }}
          />
        </>
      ),
      documents: [
        {
          label: 'Saldo Rekening_165245- Vinsensia_200121',
          type: 'PDF',
          date: '24/01/20',
          status: {
            type: 'success',
            label: 'Siap diunduh',
          },
        },
        {
          label: 'Saldo Rekening_253697- Alexander_140121',
          type: 'PDF',
          date: '15/01/21',
          status: {
            type: 'danger',
            label: 'Gagal diproses',
          },
        },
      ],
      faqs: [
        {
          name: 'process',
          title: 'Berapa lama waktu proses dokumen?',
          content: (
            <p>
              In labore ut cupidatat mollit pariatur eu. Sit consequat magna pariatur sunt minim
              sint fugiat sint deserunt aliqua ex sit minim. Eiusmod proident nostrud occaecat
              deserunt ea nisi amet excepteur velit. Commodo aute labore commodo ullamco in
              incididunt ut ex. Esse sunt ipsum enim et pariatur qui eu irure aliquip mollit ex
              excepteur do.
            </p>
          ),
        },
        {
          name: 'attendee_id',
          title: 'Dimana saya bisa mendapatkan nomor peserta?',
          content: (
            <p>
              Incididunt eu id proident nostrud sunt adipisicing sunt. Laboris aute Lorem aliqua
              voluptate non ipsum nisi est. Et voluptate ex sit laborum.
            </p>
          ),
        },
        {
          name: 'information',
          title: 'Informasi apa saja yang akan ada di laporan saldo rekening peserta?',
          content: (
            <p>
              Eiusmod tempor cupidatat voluptate est quis. Et nostrud velit tempor culpa velit in ad
              ex ut aliqua. Cupidatat occaecat ipsum Lorem voluptate. Eu excepteur incididunt tempor
              incididunt enim. Consequat nisi sint sunt pariatur duis ex sit mollit nulla quis. In
              fugiat adipisicing magna incididunt.
            </p>
          ),
        },
      ],
    },
  }

  const responsiveRowRender = content =>
    !isTabletOrMobile ? (
      <Row className={clsx('mb-5')}>
        <Col sm={12}>{content}</Col>
      </Row>
    ) : (
      <div className={clsx('normalize-container-fluid', 'mb-5')}>{content}</div>
    )

  return (
    <Container as="section" fluid>
      <h1 className="font-weight-light display-2 mb-4">{meta[slug].title}</h1>
      <Row className={clsx('mb-4')}>
        <Col md={8}>
          <p className="pr-md-5 mr-md-5 font-weight-light">
            Enim sagittis risus, diam faucibus a tristique justo, vitae, laoreet. Dui eu, urna
            tellus vitae felis mauris. Molestie purus purus turpis urna ut at sit quam.
          </p>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col md={8} className={clsx('my-3 my-md-0')}>
          {slug === 'company-account' && (
            <Card body className={clsx('mb-4', 'on-boarding__target')}>
              <Row className={clsx('justify-content-center', 'py-2')}>
                <Col as={Row} sm={12} lg={10} noGutters>
                  <Col md={12}>
                    <span className="font-weight-light">Saldo Total</span>
                    <CountUp
                      start={0}
                      delay={0}
                      duration={1}
                      end={3154304874.0}
                      decimal="."
                      decimals={2}
                      formattingFn={val => window.numbro(val).format({ mantissa: 2 })}>
                      {({ countUpRef }) => (
                        <h1 className="display-2 font-weight-bold" ref={countUpRef} />
                      )}
                    </CountUp>
                    <span className="d-block text-muted text-sm mb-3">
                      Saldo per tanggal 01 Jan 2021
                    </span>
                  </Col>
                  <Col md={10} xl={8}>
                    <Row>
                      <Col md={6} className={clsx('my-1 my-md-0')}>
                        <span className="text-sm">
                          Akumulasi Dana Masuk <i className="mi-info vertical-align-sub"></i>
                        </span>
                        <CountUp
                          start={0}
                          delay={0}
                          duration={1}
                          end={2963483069.0}
                          decimal="."
                          decimals={2}
                          formattingFn={val => window.numbro(val).format({ mantissa: 2 })}>
                          {({ countUpRef }) => (
                            <h3 className="h6 font-weight-bold mt-1" ref={countUpRef} />
                          )}
                        </CountUp>
                      </Col>
                      <Col md={6} className={clsx('my-1 my-md-0')}>
                        <span className="text-sm">
                          Hasil pengembangan <i className="mi-info vertical-align-sub"></i>
                        </span>
                        <CountUp
                          start={0}
                          delay={0}
                          duration={1}
                          end={36786365.0}
                          decimal="."
                          decimals={2}
                          formattingFn={val => window.numbro(val).format({ mantissa: 2 })}>
                          {({ countUpRef }) => (
                            <h3 className="h6 font-weight-bold mt-1" ref={countUpRef} />
                          )}
                        </CountUp>
                      </Col>
                      <Col md={6} className={clsx('my-1 my-md-0')}>
                        <span className="text-sm">
                          Penarikan Dana <i className="mi-info vertical-align-sub"></i>
                        </span>
                        <CountUp
                          start={10.0}
                          delay={0}
                          duration={1}
                          end={0.0}
                          decimal="."
                          decimals={2}
                          formattingFn={val => window.numbro(val).format({ mantissa: 2 })}>
                          {({ countUpRef }) => (
                            <h3 className="h6 font-weight-bold mt-1" ref={countUpRef} />
                          )}
                        </CountUp>
                      </Col>
                      <Col md={6} className={clsx('my-1 my-md-0')}>
                        <span className="text-sm">
                          Biaya <i className="mi-info vertical-align-sub"></i>
                        </span>
                        <CountUp
                          start={1.0}
                          delay={0}
                          duration={1}
                          end={27646554}
                          decimal="."
                          decimals={2}
                          formattingFn={val => window.numbro(val).format({ mantissa: 2 })}>
                          {({ countUpRef }) => (
                            <h3 className="h6 font-weight-bold mt-1" ref={countUpRef} />
                          )}
                        </CountUp>
                      </Col>
                    </Row>
                  </Col>
                </Col>
              </Row>
            </Card>
          )}
          <Card body>
            <Row className={clsx('py-3')}>
              <Col
                md={{ span: 6, offset: 1 }}
                className="d-flex flex-column align-items-start justify-content-between">
                {meta[slug]?.form}
              </Col>
              <Col md={{ span: 4 }}>
                <span className="font-weight-light">Pilih Tipe Dokumen*</span>
                <fieldset className={clsx('d-flex d-md-block')}>
                  <Form.Radio
                    checked
                    name="direction"
                    value="xls"
                    label="XLS"
                    formGroupProps={{ className: clsx('mt-2', 'mt-md-3') }}
                  />
                  <Form.Radio
                    checked
                    name="direction"
                    value="pdf"
                    label="PDF"
                    className={clsx('ml-4 ml-md-0')}
                    formGroupProps={{ className: clsx('mt-2', 'mt-md-3') }}
                  />
                </fieldset>
              </Col>
              <Col md={{ span: 5, offset: 1 }} className={clsx('d-flex align-items-end')}>
                <Button variant="coral" block>
                  Proses Dokumen
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col md={4} className={clsx('my-3 my-md-0')}>
          <Card>
            <Card.Header>
              <span className="d-flex">
                Riwayat Dokumen
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement="top"
                  overlay={
                    <Popover>
                      <Popover.Content>
                        Est consectetur sit est enim voluptate ex cupidatat non.
                      </Popover.Content>
                    </Popover>
                  }>
                  <i className="mi-info lg ml-1 cursor-pointer"></i>
                </OverlayTrigger>
              </span>
            </Card.Header>
            <ListGroup variant="flush">
              {meta[slug].documents.map((doc, key) => (
                <Card key={key} as={ListGroup.Item}>
                  <Card.Body className={clsx('px-0 py-2')}>
                    <Button as={Link} to="/" variant="anchor" className={clsx('card-anchor')} />
                    <h1 className="d-inline-block h6 font-weight-light mb-0 w-75">
                      <span className="mr-1">{doc.label}</span>
                      <Badge
                        pill
                        size="xs"
                        variant={doc.type.toLowerCase().concat('Color')}
                        className="mt-1 vertical-align-top">
                        {doc.type.toUpperCase()}
                      </Badge>
                    </h1>
                    <div className="d-flex align-items-center mt-4">
                      <p className="mb-0 text-sm mr-2">
                        <strong>{doc.date}</strong>
                      </p>
                      <StatusText {...doc.status} dotSize="sm" />
                    </div>
                  </Card.Body>
                </Card>
              ))}
            </ListGroup>
          </Card>
          <Pagination className="mt-3">
            <Pagination.Prev className="mr-auto" />
            <Pagination.Item active>{1}</Pagination.Item>
            <Pagination.Item>{2}</Pagination.Item>
            <Pagination.Item>{3}</Pagination.Item>
            <Pagination.Next className="ml-auto" />
          </Pagination>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <h1 className="font-weight-light">FAQ Terkait</h1>
        </Col>
      </Row>
      {responsiveRowRender(
        <Accordion.Rowed itemsPerRow={meta[slug].faqs.length} items={meta[slug].faqs} />
      )}
      <div className={clsx('my-4', 'pt-2 pb-3')}>
        <Billboard.Contact />
      </div>
    </Container>
  )
}

export default Ballance
