// @flow
import * as React from 'react'
import { Redirect } from 'react-router'
import { Authentication } from 'layouts'

import SignIn from './SignIn'
import * as Register from './Register'
import * as ResetPassword from './ResetPassword'

import type { Route } from 'router/routes'

export const pathname = '/auth'
export const route: Route = {
  component: Authentication,
  asLayout: true,
  routes: {
    '/signin': {
      component: SignIn,
    },
    '/register': {
      routes: {
        '/set-password': {
          component: Register.Password,
        },
        '/verification': {
          routes: {
            '/:type': {
              component: Register.OTP,
            },
            '/': {
              component: Register.Verification,
            },
          },
        },
        '/': {
          component: Register.Main,
        },
      },
    },
    '/reset-password': {
      routes: {
        '/set-password': {
          component: ResetPassword.Password,
        },
        '/verification': {
          routes: {
            '/:type': {
              component: ResetPassword.OTP,
            },
            '/': {
              component: ResetPassword.Verification,
            },
          },
        },
        '/': {
          component: ResetPassword.Main,
        },
      },
    },
    '/': {
      component: ({ layoutPath }: any) => <Redirect to={`${layoutPath}/signin`} />,
    },
  },
}
