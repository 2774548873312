// @flow
import clsx from 'clsx'
import * as React from 'react'
import BadgeBase from 'react-bootstrap/Badge'

export type BadgeProps = {
  className?: any,
  disabled: boolean,
  size?: 'lg' | 'sm' | 'xs',
  ...
}

const Badge = ({ size, disabled, ...restProps }: BadgeProps): React$Element<typeof BadgeBase> => {
  return (
    <BadgeBase
      {...restProps}
      className={clsx(restProps.className, !!size && `badge-${size}`, disabled && `badge-disabled`)}
    />
  )
}

Badge.defaultProps = ({
  disabled: false,
}: BadgeProps)

export default Badge
