/* eslint-disable react-hooks/exhaustive-deps */
// @flow
import * as React from 'react'

import { getDateAccordingToMonth, shallowClone, getValueType } from 'lib/shared/generalUtils'
import { TYPE_SINGLE_DATE, TYPE_RANGE, TYPE_MUTLI_DATE } from 'lib/shared/constants'
import { useLocaleUtils, useLocaleLanguage } from 'lib/shared/hooks'

import Header from './Header'
import DaysList from './DaysList'
import MonthSelector from './MonthSelector'
import YearSelector from './YearSelector'
import clsx from 'clsx'
import { isEmpty } from 'lodash-es'

const Calendar = ({
  value,
  onChange,
  onDisabledDayError,
  calendarClassName,
  calendarTodayClassName,
  calendarSelectedDayClassName,
  calendarRangeStartClassName,
  calendarRangeBetweenClassName,
  calendarRangeEndClassName,
  disabledDays,
  colorPrimary,
  colorPrimaryLight,
  slideAnimationDuration,
  minimumDate,
  maximumDate,
  selectorStartingYear,
  selectorEndingYear,
  locale,
  shouldHighlightWeekends,
  renderFooter,
  customDaysClassName,
  onStateChange,
  state: stateProp,
}: any): React$Element<'div'> => {
  const calendarElement = React.useRef<HTMLElement | null>(null)
  const [mainState, setMainState] = React.useState({
    activeDate: null,
    monthChangeDirection: '',
    isMonthSelectorOpen: false,
    isYearSelectorOpen: false,
  })

  React.useEffect(() => {
    const handleKeyUp = ({ key }) => {
      if (key === 'Tab') calendarElement.current?.classList.remove('-no-focus-outline')
    }

    ;(calendarElement.current: any).addEventListener('keyup', handleKeyUp, false)

    return () => {
      !!calendarElement.current &&
        (calendarElement.current: any).removeEventListener('keyup', handleKeyUp, false)
    }
  })

  const { getToday } = useLocaleUtils(locale)
  const { weekDays: weekDaysList, isRtl } = useLocaleLanguage(locale)
  const today = getToday()

  const createStateToggler = property => () => {
    setMainState(({ ...mainState, [(property: string)]: !mainState[property] }: any))
  }

  const toggleMonthSelector = createStateToggler('isMonthSelectorOpen')
  const toggleYearSelector = createStateToggler('isYearSelectorOpen')

  const getComputedActiveDate = () => {
    const valueType = getValueType(value)
    if (valueType === TYPE_MUTLI_DATE && value.length) return shallowClone(value[0])
    if (valueType === TYPE_SINGLE_DATE && value) return shallowClone(value)
    if (valueType === TYPE_RANGE && value.from) return shallowClone(value.from)
    return shallowClone(today)
  }

  const activeDate = mainState.activeDate
    ? shallowClone(mainState.activeDate)
    : getComputedActiveDate()

  const weekdays = weekDaysList.map(weekDay => (
    <abbr key={weekDay.name} title={weekDay.name} className="calendar__week-day">
      {weekDay.short}
    </abbr>
  ))

  const handleMonthChange = direction => {
    setMainState({
      ...mainState,
      monthChangeDirection: direction,
    })
  }

  const updateDate = () => {
    setMainState({
      ...mainState,
      activeDate: getDateAccordingToMonth(activeDate, mainState.monthChangeDirection),
      monthChangeDirection: '',
    })
  }

  const selectMonth = newMonthNumber => {
    setMainState({
      ...mainState,
      activeDate: { ...activeDate, month: newMonthNumber },
      isMonthSelectorOpen: false,
    })
  }

  const selectYear = year => {
    setMainState({
      ...mainState,
      activeDate: { ...activeDate, year },
      isYearSelectorOpen: false,
    })
  }

  React.useEffect(() => {
    onStateChange(mainState)
  }, [mainState])

  React.useEffect(() => {
    if (!isEmpty(stateProp))
      setMainState({
        ...mainState,
        ...stateProp,
      })
  }, [stateProp])

  return (
    <div
      role="grid"
      ref={calendarElement}
      className={clsx('calendar', '-no-focus-outline', isRtl ? '-rtl' : '-ltr', calendarClassName)}
      style={{
        '--cl-color-primary': colorPrimary,
        '--cl-color-primary-light': colorPrimaryLight,
        '--animation-duration': slideAnimationDuration,
      }}>
      <Header
        maximumDate={maximumDate}
        minimumDate={minimumDate}
        activeDate={activeDate}
        onMonthChange={handleMonthChange}
        onMonthSelect={toggleMonthSelector}
        onYearSelect={toggleYearSelector}
        monthChangeDirection={mainState.monthChangeDirection}
        isMonthSelectorOpen={mainState.isMonthSelectorOpen}
        isYearSelectorOpen={mainState.isYearSelectorOpen}
        locale={locale}
      />

      <MonthSelector
        isOpen={mainState.isMonthSelectorOpen}
        activeDate={activeDate}
        onMonthSelect={selectMonth}
        maximumDate={maximumDate}
        minimumDate={minimumDate}
        locale={locale}
      />

      <YearSelector
        isOpen={mainState.isYearSelectorOpen}
        activeDate={activeDate}
        onYearSelect={selectYear}
        selectorStartingYear={selectorStartingYear}
        selectorEndingYear={selectorEndingYear}
        maximumDate={maximumDate}
        minimumDate={minimumDate}
        locale={locale}
      />

      <div className="calendar__week-days">{weekdays}</div>

      <DaysList
        activeDate={activeDate}
        value={value}
        monthChangeDirection={mainState.monthChangeDirection}
        onSlideChange={updateDate}
        disabledDays={disabledDays}
        onDisabledDayError={onDisabledDayError}
        minimumDate={minimumDate}
        maximumDate={maximumDate}
        onChange={onChange}
        calendarTodayClassName={calendarTodayClassName}
        calendarSelectedDayClassName={calendarSelectedDayClassName}
        calendarRangeStartClassName={calendarRangeStartClassName}
        calendarRangeEndClassName={calendarRangeEndClassName}
        calendarRangeBetweenClassName={calendarRangeBetweenClassName}
        locale={locale}
        shouldHighlightWeekends={shouldHighlightWeekends}
        customDaysClassName={customDaysClassName}
        isQuickSelectorOpen={mainState.isYearSelectorOpen || mainState.isMonthSelectorOpen}
      />
      <div className="calendar__footer">{renderFooter()}</div>
    </div>
  )
}

Calendar.defaultProps = {
  minimumDate: null,
  maximumDate: null,
  colorPrimary: 'var(--primary)',
  colorPrimaryLight: '#cff4d5',
  slideAnimationDuration: '0.4s',
  calendarClassName: '',
  locale: 'en',
  value: null,
  // eslint-disable-next-line no-empty-pattern
  renderFooter: (): null => null,
  customDaysClassName: ([]: Array<empty>),
  onStateChange: (state: any) => {},
}

Calendar.Header = Header
Calendar.DaysList = DaysList
Calendar.MonthSelector = MonthSelector
Calendar.YearSelector = YearSelector

export default Calendar
