// @flow
import * as React from 'react'
import { Redirect } from 'react-router'

import Prices from './Prices'
import Direction from './Direction'
import Conversion from './Conversion'

import type { Route } from 'router/routes'

export const pathname = '/investment'
export const route: Route = {
  routes: {
    '/direction': {
      component: Direction,
    },
    '/prices': {
      component: Prices,
    },
    '/conversion': {
      component: Conversion,
    },
    '/': {
      component: ({ layoutPath }) => <Redirect to={`${layoutPath}investment/direction`} />,
    },
  },
}
