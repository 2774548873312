// @flow

import * as React from 'react'
import clsx from 'clsx'
import { Header, Footer } from 'components'
import { Col, Container, Row } from 'react-bootstrap'
import sideBg from 'assets/images/featured-waterfall-bg.jpg'
import MediaQuery from 'react-responsive'

// Styles
import styles from './style.module.scss'

type Props = {
  mode: 'employee' | 'employer',
  routesBlock: ({}) => React$Element<any>,
  bgImageSide: any,
  children?: React.Node,
}

export const Authentication = ({
  mode,
  children,
  bgImageSide,
  ...rest
}: Props): React$Element<any> => {
  const child = !!rest.routesBlock ? <rest.routesBlock {...rest} /> : children

  return (
    <Container as="main" className={clsx('px-0')} fluid>
      <Row as="section" className={clsx(styles.wrapper)} noGutters>
        <Col>
          <div className={clsx(styles.content)}>
            {/* mobile/tablet */}
            <MediaQuery maxWidth={768}>
              <Header.Mobile />
              <Col
                md={4}
                className={styles.side__bg}
                style={{ backgroundImage: `url(${sideBg})` }}
              />
            </MediaQuery>
            {/* desktop */}
            <MediaQuery minWidth={768}>
              <Header mode={mode} logoIconColor="#00A758" />
            </MediaQuery>

            <div className={styles.content__inner}>{child}</div>
            <Footer />
          </div>
        </Col>
        {/* desktop */}
        <MediaQuery minWidth={768}>
          <Col
            md={4}
            className={styles.side__bg}
            style={{ backgroundImage: `url(${bgImageSide})` }}
          />
        </MediaQuery>
      </Row>
    </Container>
  )
}

Authentication.defaultProps = {
  mode: 'employee',
  bgImageSide: sideBg,
}

export default Authentication
