/* eslint-disable no-empty-pattern */
// @flow
import clsx from 'clsx'
import * as React from 'react'
import { DataTable, Form, Pagination, Tabs } from 'components'
import { Button, Col, Container, Row, Tab } from 'react-bootstrap'

// Styles
import styles from './style.module.scss'

export type PricesProps = { ... }

export const Prices = ({}: PricesProps): React$Element<'section'> => {
  const performanceMenuItems = [
    {
      name: '1yr',
      content: 'Performance (1 Yr)',
    },
    {
      name: '6mth',
      content: 'Performance (6 Mth)',
    },
    {
      name: '3mth',
      content: 'Performance (3 Mth)',
    },
    {
      name: '1mth',
      content: 'Performance (1 Mth)',
    },
  ]

  const [performanceName, setPerformanceName] = React.useState(performanceMenuItems[0].name)

  const [filterDate, setFilterDate] = React.useState({
    from: { day: 1, month: 7, year: 2020 },
    to: { day: 31, month: 8, year: 2020 },
  })

  const handlePerformanceMenuChange = name => setPerformanceName(name)

  const dplkPrices = {
    headers: [
      { title: 'Fund Name', name: 'name' },
      { title: 'Currency', name: 'currency' },
      { title: 'As of Date', name: 'date' },
      { title: 'Price', name: 'price' },
      {
        title: performanceMenuItems.find(item => item.name === performanceName)?.content,
        name: 'performance',
        sortable: true,
        sortType: 'number',
        attrs: {
          className: clsx(styles.performanceHead),
        },
        dataActions: {
          menu: performanceMenuItems,
          handler: {
            onFireEvent: handlePerformanceMenuChange,
          },
        },
      },
    ],
    data: [
      [
        { content: 1, name: 'id' },
        { content: 'Manulife Dana Ekuitas', name: 'name' },
        { content: 'IDR', name: 'currency' },
        {
          content: '19/02/21',
          name: 'date',
        },
        {
          content: <span className="text-danger">9,198.24</span>,
          name: 'price',
        },
        {
          content: <span className="text-danger">-0.32%</span>,
          name: 'performance',
        },
      ],
      [
        { content: 2, name: 'id' },
        { content: 'Manulife Dana Ekuitas USD', name: 'name' },
        { content: 'USD', name: 'currency' },
        {
          content: '19/02/21',
          name: 'date',
        },
        {
          content: <span className="text-danger">1.028534</span>,
          name: 'price',
        },
        {
          content: <span className="text-danger">-0.34%</span>,
          name: 'performance',
        },
      ],
      [
        { content: 3, name: 'id' },
        { content: 'Manulife Pend. Tetap Korp', name: 'name' },
        { content: 'IDR', name: 'currency' },
        {
          content: '19/02/21',
          name: 'date',
        },
        {
          content: <span className="text-danger">3,038.45</span>,
          name: 'price',
        },
        {
          content: <span className="text-danger">-0.30%</span>,
          name: 'performance',
        },
      ],
      [
        { content: 4, name: 'id' },
        { content: 'Manulife Pend. Tetap Negara', name: 'name' },
        { content: 'IDR', name: 'currency' },
        {
          content: '19/02/21',
          name: 'date',
        },
        {
          content: <span className="text-danger">3,156.23</span>,
          name: 'price',
        },
        {
          content: <span className="text-danger">-0.32%</span>,
          name: 'performance',
        },
      ],
      [
        { content: 5, name: 'id' },
        { content: 'Manulife Pend. Tetap Dollar', name: 'name' },
        { content: 'USD', name: 'currency' },
        {
          content: '19/02/21',
          name: 'date',
        },
        {
          content: <span className="text-success">1.629837</span>,
          name: 'price',
        },
        {
          content: <span className="text-success">1.83%</span>,
          name: 'performance',
        },
      ],
      [
        { content: 6, name: 'id' },
        { content: 'Manulife Pasar Uang', name: 'name' },
        { content: 'IDR', name: 'currency' },
        {
          content: '19/02/21',
          name: 'date',
        },
        {
          content: <span className="text-success">2,022.87</span>,
          name: 'price',
        },
        {
          content: <span className="text-success">1.83%</span>,
          name: 'performance',
        },
      ],
    ],
  }

  const groupSavingsPrices = {
    headers: [
      { title: 'Fund Name', name: 'name' },
      { title: 'Currency', name: 'currency' },
      { title: 'As of Date', name: 'date' },
      { title: 'Price', name: 'price' },
      {
        title: performanceMenuItems.find(item => item.name === performanceName)?.content,
        name: 'performance',
        sortable: true,
        sortType: 'number',
        attrs: {
          className: clsx(styles.performanceHead),
        },
        dataActions: {
          menu: performanceMenuItems,
          handler: {
            onFireEvent: handlePerformanceMenuChange,
          },
        },
      },
    ],
    data: [
      [
        { content: 1, name: 'id' },
        { content: 'Manulife Pend. Tetap Dollar', name: 'name' },
        { content: 'USD', name: 'currency' },
        {
          content: '19/02/21',
          name: 'date',
        },
        {
          content: <span className="text-success">1.629837</span>,
          name: 'price',
        },
        {
          content: <span className="text-success">1.83%</span>,
          name: 'performance',
        },
      ],
      [
        { content: 2, name: 'id' },
        { content: 'Manulife Pasar Uang', name: 'name' },
        { content: 'IDR', name: 'currency' },
        {
          content: '19/02/21',
          name: 'date',
        },
        {
          content: <span className="text-success">2,022.87</span>,
          name: 'price',
        },
        {
          content: <span className="text-success">1.83%</span>,
          name: 'performance',
        },
      ],
      [
        { content: 3, name: 'id' },
        { content: 'Manulife Dana Ekuitas', name: 'name' },
        { content: 'IDR', name: 'currency' },
        {
          content: '19/02/21',
          name: 'date',
        },
        {
          content: <span className="text-danger">9,198.24</span>,
          name: 'price',
        },
        {
          content: <span className="text-danger">-0.32%</span>,
          name: 'performance',
        },
      ],
      [
        { content: 4, name: 'id' },
        { content: 'Manulife Dana Ekuitas USD', name: 'name' },
        { content: 'USD', name: 'currency' },
        {
          content: '19/02/21',
          name: 'date',
        },
        {
          content: <span className="text-danger">1.028534</span>,
          name: 'price',
        },
        {
          content: <span className="text-danger">-0.32%</span>,
          name: 'performance',
        },
      ],
      [
        { content: 5, name: 'id' },
        { content: 'Manulife Pend. Tetap Korp', name: 'name' },
        { content: 'IDR', name: 'currency' },
        {
          content: '19/02/21',
          name: 'date',
        },
        {
          content: <span className="text-danger">3,038.45</span>,
          name: 'price',
        },
        {
          content: <span className="text-danger">-0.32%</span>,
          name: 'performance',
        },
      ],
      [
        { content: 6, name: 'id' },
        { content: 'Manulife Pend. Tetap Negara', name: 'name' },
        { content: 'IDR', name: 'currency' },
        {
          content: '19/02/21',
          name: 'date',
        },
        {
          content: <span className="text-danger">3,156.23</span>,
          name: 'price',
        },
        {
          content: <span className="text-danger">-0.32%</span>,
          name: 'performance',
        },
      ],
    ],
  }

  return (
    <Container as="section" className={clsx(styles.wrapper, 'mb-5')} fluid>
      <h1 className="font-weight-light display-2 mb-4">
        Harga <span className="font-serif">Unit</span>
      </h1>
      <Row className={clsx('mt-3 mb-5')}>
        <Col md={7}>
          <p className="pr-5 mr-5 font-weight-light">
            Enim sagittis risus, diam faucibus a tristique justo, vitae, laoreet. Dui eu, urna
            tellus vitae felis mauris. Molestie purus purus turpis urna ut at sit quam.
          </p>
        </Col>
      </Row>
      <Tabs id="prices" className={clsx(styles.tabs)} responsiveMode="dropdown">
        <Tab eventKey="dplk" title="Harga Unit DPLK">
          <Row className="py-4 justify-content-end">
            <Col md={6} className={clsx('py-2')}>
              <p className="w-100">
                A volutpat massa id interdum facilisis dui amet lectus. Tellus nisl neque, et et
                quam non nibh nulla. Mattis a sed gravida tristique.
              </p>
            </Col>
            <Col md={6} className={clsx('py-2')}>
              <Row className={clsx('align-items-start')}>
                <Col md={8}>
                  <Form.DateRangePicker
                    hasGrow
                    value={filterDate}
                    onChange={setFilterDate}
                    fromInputProps={{ label: 'Dari', placeholder: 'Pilih Tanggal' }}
                    toInputProps={{ label: 'Hingga', placeholder: 'Pilih Tanggal' }}
                  />
                  <div className="d-flex py-2">
                    <span className="text-sm mt-2 d-block">Maksimal periode 1 (satu) tahun</span>
                    <Button
                      variant="icon"
                      className={clsx('ml-auto')}
                      onClick={() =>
                        setFilterDate({
                          from: null,
                          to: null,
                        })
                      }>
                      <i className={clsx('mi mi-reload')}></i> Ulangi
                    </Button>
                  </div>
                </Col>
                <Col className={clsx('d-flex')}>
                  <Button variant="coral" block>
                    Unduh
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col md={12} className={clsx('mt-4')}>
              <DataTable {...dplkPrices} responsiveMode="table" />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Pagination className="mt-3">
                <Pagination.Prev className="mr-auto" />
                <Pagination.Item active>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Item>{3}</Pagination.Item>
                <Pagination.Item>{4}</Pagination.Item>
                <Pagination.Ellipsis />
                <Pagination.Item>{7}</Pagination.Item>
                <Pagination.Next className="ml-auto" />
              </Pagination>
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="groupSavings" title="Harga Unit Group Savings">
          <Row className="py-4 justify-content-end">
            <Col md={6} className={clsx('py-2')}>
              <p className="w-100">
                A volutpat massa id interdum facilisis dui amet lectus. Tellus nisl neque, et et
                quam non nibh nulla. Mattis a sed gravida tristique.
              </p>
            </Col>
            <Col md={6} className={clsx('py-2')}>
              <Row className={clsx('align-items-start')}>
                <Col md={8}>
                  <Form.DateRangePicker
                    hasGrow
                    value={filterDate}
                    onChange={setFilterDate}
                    fromInputProps={{ label: 'Dari', placeholder: 'Pilih Tanggal' }}
                    toInputProps={{ label: 'Hingga', placeholder: 'Pilih Tanggal' }}
                  />
                  <div className="d-flex py-2">
                    <span className="text-sm mt-2 d-block">Maksimal periode 1 (satu) tahun</span>
                    <Button
                      variant="icon"
                      className={clsx('ml-auto')}
                      onClick={() =>
                        setFilterDate({
                          from: null,
                          to: null,
                        })
                      }>
                      <i className={clsx('mi mi-reload')}></i> Ulangi
                    </Button>
                  </div>
                </Col>
                <Col className={clsx('d-flex')}>
                  <Button variant="coral" block>
                    Unduh
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col md={12} className={clsx('mt-4')}>
              <DataTable {...groupSavingsPrices} responsiveMode="table" />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Pagination className="mt-3">
                <Pagination.Prev className="mr-auto" />
                <Pagination.Item active>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Item>{3}</Pagination.Item>
                <Pagination.Item>{4}</Pagination.Item>
                <Pagination.Ellipsis />
                <Pagination.Item>{7}</Pagination.Item>
                <Pagination.Next className="ml-auto" />
              </Pagination>
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </Container>
  )
}

export default Prices
