// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { Col, Container, Nav, Row } from 'react-bootstrap'
import { ReactComponent as LogoIcon } from 'assets/images/svgs/logo/icon.svg'
import { ReactComponent as LogoBrand } from 'assets/images/svgs/logo/brand.svg'
import { useMediaQuery } from 'react-responsive'

// Styles
import styles from './style.module.scss'

const layoutTypes = ['common', 'dashboard']
const layoutTypesObj: any = layoutTypes.reduce(
  (agg, next) => ({ ...(agg: any), [(next: string)]: true }),
  {}
)

type MenuItem = {
  href: string,
  label: string,
  layouts: Array<$Keys<typeof layoutTypesObj>>,
}

type MenuSocialItem = {
  href: string,
  label: string,
  icon: string,
}

type Props = {
  layoutType: $Keys<typeof layoutTypesObj>,
  className?: string | Array<any> | { ... },
}

const menuItems: Array<MenuItem> = [
  {
    href: '/guides',
    label: 'Panduan EBClick',
    layouts: ['common'],
  },
  {
    href: '/',
    label: 'Syarat Penggunaan',
    layouts: ['common', 'dashboard'],
  },
  {
    href: '/',
    label: 'Kebijakan Privasi',
    layouts: ['common', 'dashboard'],
  },
  {
    href: '/',
    label: 'Hubungi Kami',
    layouts: ['common', 'dashboard'],
  },
]

const menuSocials: Array<MenuSocialItem> = [
  {
    label: 'Facebook',
    href: '//facebook.com',
    icon: 'facebook',
  },
  {
    label: 'YouTube',
    href: '//youtube.com',
    icon: 'youtube',
  },
  {
    label: 'Twitter',
    href: '//twitter.com',
    icon: 'twitter',
  },
  {
    label: 'Instagram',
    href: '//instagram.com',
    icon: 'instagram',
  },
  // {
  //   label: 'LinkedIn',
  //   href: '//linkedin.com',
  //   icon: 'linkedin',
  // },
]

const Footer = ({ layoutType, className }: Props): React$Element<'footer'> => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767.98px)' })
  const isTypeDashboard = layoutType === 'dashboard'

  const renderMenus = () => (
    <Nav as="ul" className={clsx(styles.footer__nav, styles.footer__nav__links)}>
      {menuItems
        .filter(menu => menu.layouts.includes(layoutType))
        .map(({ href, label }, key) => (
          <Nav.Item as="li" key={key} className={clsx('nav-item', styles.footer__nav__item)}>
            <Nav.Link as={Link} to={href} className={clsx('nav-link', styles.footer__nav__link)}>
              {label}
            </Nav.Link>
          </Nav.Item>
        ))}
    </Nav>
  )

  const renderSocials = () => (
    <Nav as="ul" className={clsx(styles.footer__nav)}>
      {menuSocials.map(({ href, label, icon }, key) => (
        <Nav.Item
          as="li"
          key={key}
          className={clsx('nav-item', styles.footer__nav__item, styles.footer__nav__item__icon)}>
          <Nav.Link
            href={href}
            aria-label={label}
            className={clsx('nav-link', styles.footer__nav__link)}>
            <i className={clsx(`mi-${icon}`)}></i>
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  )

  return (
    <footer
      className={clsx(
        styles.footer,
        (isTypeDashboard || isTabletOrMobile) && styles['footer--dark'],
        className
      )}
      data-type={layoutType}>
      {isTypeDashboard || isTabletOrMobile ? (
        <>
          <div className={styles.footer__top}>
            <Container fluid>
              <Row className={clsx('align-items-center')}>
                <Col md={6}>{renderMenus()}</Col>
                <Col>{renderSocials()}</Col>
              </Row>
            </Container>
          </div>
          <div className={styles.footer__bottom}>
            <Container fluid>
              <Row>
                <Col sm={12} md={6}>
                  <Link className={clsx(styles.footer__brand)} to="/">
                    <div className={clsx(styles.footer__brand__icon)}>
                      <LogoIcon fill="#00A758" width={24.266666666667} height={26} />
                    </div>
                    <LogoBrand fill="#FFF" width={89.375} height={26} />
                  </Link>
                </Col>
                <Col sm={12} md={6}>
                  <div className={styles.footer__copy}>
                    <p>
                      ©2020 PT Asuransi Jiwa Manulife Indonesia. Head Office : Sampoerna Strategic
                      Square, North Tower Lantai GF and South Tower Lantai 3-14. Jl. Jenderal
                      Sudirman Kav.45-46, Jakarta 12930. Tel : (021) 2555 7777 / Fax : (021) 2555
                      2226 / Bebas Pulsa : 0-800-1-606060
                    </p>
                    <p>
                      PT Asuransi Jiwa Manulife Indonesia terdaftar dan diawasi oleh Otoritas Jasa
                      Keuangan.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      ) : (
        <Container>{renderMenus()}</Container>
      )}
    </footer>
  )
}

Footer.defaultProps = ({
  layoutType: 'common',
}: Props)

export default Footer
