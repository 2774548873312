// @flow

import * as React from 'react'
import clsx from 'clsx'
import { Header, Footer } from 'components'
import { Container, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import MediaQuery from 'react-responsive'

// Styles
import styles from './style.module.scss'

type Props = {
  routesBlock: ({}) => React$Element<any>,
  children?: React.Node,
}

export const Guest = ({ children, ...rest }: Props): React$Element<any> => {
  const child = !!rest.routesBlock ? <rest.routesBlock {...rest} /> : children

  return (
    <Container as="main" className={clsx('px-0', styles.wrapper)} fluid>
      <div className={clsx(styles.content)}>
        {/* mobile/tablet */}
        <MediaQuery maxWidth={768}>
          <Header.Mobile />
        </MediaQuery>

        {/* desktop */}
        <MediaQuery minWidth={768}>
          <Header
            logoIconColor="#00A758"
            langSelector
            appendToNav={
              <Button
                as={Link}
                to="/employee/auth"
                variant="outline-coral"
                className={clsx('ml-4')}
                size="sm">
                <strong>Masuk</strong>
              </Button>
            }
          />
        </MediaQuery>
        <div className={styles.content__inner}>{child}</div>
        <Footer />
      </div>
    </Container>
  )
}

export default Guest
