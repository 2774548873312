/* eslint-disable no-empty-pattern */
// @flow
import clsx from 'clsx'
import * as React from 'react'
import * as yup from 'yup'
import { Link, useParams } from 'react-router-dom'
import { Billboard, Accordion, Form, Badge, StatusText } from 'components'
import {
  Col,
  Container,
  Row,
  Button,
  Card,
  ListGroup,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { has } from 'lodash'

// Styles
// import styles from './style.module.scss'

const schema = yup.object().shape({
  attendee_id: yup.number().required().integer(),
})

export type ClaimProps = { ... }

export const Claim = ({}: ClaimProps): React$Element<'section'> => {
  const { slug } = useParams()
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })
  const initialSearchBy = {
    'incomplete-submission': 'attendee_id',
    'payment-report': 'short',
    'pension-notice': '1month',
  }

  const [searchBy, setSearchBy] = React.useState(initialSearchBy[slug])

  const { register, errors, formState } = useForm({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(schema),
  })

  const meta = {
    'incomplete-submission': {
      title: 'Pengajuan Klaim Tidak Lengkap',
      form: (
        <>
          <Form.Select
            value={searchBy}
            onChange={setSearchBy}
            label="Cari Dokumen Berdasarkan"
            formGroupProps={{ className: clsx('w-100', 'mb-4 pr-4 pr-md-5') }}
            menuProps={{ className: clsx('w-100') }}
            items={[
              { key: 'all', value: 'Semua Peserta' },
              { key: 'attendee_id', value: 'Nomor Peserta' },
            ]}
          />
          <Form.Input
            hasGrow
            type="text"
            disabled={searchBy !== 'attendee_id'}
            placeholder="contoh: 1234567"
            label="Masukkan Nomor Peserta*"
            formGroupProps={{ className: clsx('w-100', 'mb-4 pr-4 pr-md-5') }}
            hasValidation
            ref={register}
            name="attendee_id"
            isInvalid={errors.attendee_id}
            isValid={!has(errors, 'attendee_id') && formState.isValid}
            feedbackMessage={errors.attendee_id && '*Nomor Peserta Tidak Terdaftar'}
            append={
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={
                  <Popover>
                    <Popover.Content>
                      Est consectetur sit est enim voluptate ex cupidatat non.
                    </Popover.Content>
                  </Popover>
                }>
                <i className="mi-info"></i>
              </OverlayTrigger>
            }
          />
        </>
      ),
      documents: [
        {
          label: 'Pengajuan Klaim Tidak Lengkap _All-240121',
          type: 'XLS',
          date: '24/01/20',
          status: {
            type: 'success',
            label: 'Siap diunduh',
          },
        },
        {
          label: 'Pengajuan Klaim Tidak Lengkap _All-240121',
          type: 'PDF',
          date: '24/01/20',
          status: {
            type: 'danger',
            label: 'Gagal diproses',
          },
        },
      ],
      faqs: [
        {
          name: 'process',
          title: 'Berapa lama waktu proses klaim?',
          content: (
            <p>
              In labore ut cupidatat mollit pariatur eu. Sit consequat magna pariatur sunt minim
              sint fugiat sint deserunt aliqua ex sit minim. Eiusmod proident nostrud occaecat
              deserunt ea nisi amet excepteur velit. Commodo aute labore commodo ullamco in
              incididunt ut ex. Esse sunt ipsum enim et pariatur qui eu irure aliquip mollit ex
              excepteur do.
            </p>
          ),
        },
        {
          name: 'completetion',
          title: 'Bagaimana cara melengkapi dokumen tidak lengkap?',
          content: (
            <p>
              Incididunt eu id proident nostrud sunt adipisicing sunt. Laboris aute Lorem aliqua
              voluptate non ipsum nisi est. Et voluptate ex sit laborum.
            </p>
          ),
        },
        {
          name: 'daring',
          title: 'Bisakah mengunggah dokumen secara daring?',
          content: (
            <p>
              Eiusmod tempor cupidatat voluptate est quis. Et nostrud velit tempor culpa velit in ad
              ex ut aliqua. Cupidatat occaecat ipsum Lorem voluptate. Eu excepteur incididunt tempor
              incididunt enim. Consequat nisi sint sunt pariatur duis ex sit mollit nulla quis. In
              fugiat adipisicing magna incididunt.
            </p>
          ),
        },
      ],
    },
    'payment-report': {
      title: 'Laporan Pembayaran Klaim',
      form: (
        <>
          <Form.Select
            value={searchBy}
            onChange={setSearchBy}
            label="Cari Dokumen Berdasarkan"
            formGroupProps={{ className: clsx('w-100', 'mb-4 pr-4 pr-md-5') }}
            menuProps={{ className: clsx('w-100') }}
            items={[
              { key: 'short', value: 'Ringkasan Singkat' },
              { key: 'detailed', value: 'Rincian Detail' },
            ]}
          />
          <Form.DateRangePicker
            hasGrow
            fromInputProps={{
              label: 'Dari',
              placeholder: 'Pilih Tanggal',
              formGroupProps: { className: clsx('pr-4 pr-md-5') },
            }}
            toInputProps={{
              label: 'Hingga',
              placeholder: 'Pilih Tanggal',
              formGroupProps: { className: clsx('pr-4 pr-md-5') },
            }}
            colProps={{ className: clsx('mb-4'), xs: 12 }}
          />
        </>
      ),
      documents: [
        {
          label: 'Laporan Klaim_Ringkasan_ 240121-240221',
          type: 'XLS',
          date: '24/01/20',
          status: {
            type: 'success',
            label: 'Siap diunduh',
          },
        },
        {
          label: 'Laporan Klaim_Detail_ 241220- 240121',
          type: 'PDF',
          date: '24/01/20',
          status: {
            type: 'success',
            label: 'Siap diunduh',
          },
        },
      ],
      faqs: [
        {
          name: 'process',
          title: 'Berapa lama waktu proses dokumen?',
          content: (
            <p>
              In labore ut cupidatat mollit pariatur eu. Sit consequat magna pariatur sunt minim
              sint fugiat sint deserunt aliqua ex sit minim. Eiusmod proident nostrud occaecat
              deserunt ea nisi amet excepteur velit. Commodo aute labore commodo ullamco in
              incididunt ut ex. Esse sunt ipsum enim et pariatur qui eu irure aliquip mollit ex
              excepteur do.
            </p>
          ),
        },
        {
          name: 'difference',
          title: 'Apa perbedaan jenis dokumen ringkasan dan rincian?',
          content: (
            <p>
              Incididunt eu id proident nostrud sunt adipisicing sunt. Laboris aute Lorem aliqua
              voluptate non ipsum nisi est. Et voluptate ex sit laborum.
            </p>
          ),
        },
        {
          name: 'daring',
          title: 'Bagaimana cara mengetahui pengajuan klaim tidak lengkap?',
          content: (
            <p>
              Eiusmod tempor cupidatat voluptate est quis. Et nostrud velit tempor culpa velit in ad
              ex ut aliqua. Cupidatat occaecat ipsum Lorem voluptate. Eu excepteur incididunt tempor
              incididunt enim. Consequat nisi sint sunt pariatur duis ex sit mollit nulla quis. In
              fugiat adipisicing magna incididunt.
            </p>
          ),
        },
      ],
    },
    'pension-notice': {
      title: 'Pemberitahuan Masa Pensiun',
      form: (
        <>
          <Form.Select
            value={searchBy}
            onChange={setSearchBy}
            label="Cari Dokumen Berdasarkan"
            formGroupProps={{ className: clsx('w-100', 'mb-4 pr-4 pr-md-5') }}
            menuProps={{ className: clsx('w-100') }}
            items={[
              { key: '1month', value: 'Pemberitahuan 1 bulan' },
              { key: '3months', value: 'Pemberitahuan 3 bulan' },
            ]}
          />
          <Form.DatePicker
            hasGrow
            label="Tanggal Laporan"
            placeholder="Pilih Tanggal"
            formGroupProps={{ className: clsx('w-100', 'mb-4 pr-4 pr-md-5') }}
          />
        </>
      ),
      documents: [
        {
          label: 'Pemberitahuan Masa Pensiun_ 3bulan_010221',
          type: 'XLS',
          date: '24/01/20',
          status: {
            type: 'success',
            label: 'Siap diunduh',
          },
        },
      ],
      faqs: [
        {
          name: 'process',
          title: 'Berapa lama waktu proses dokumen?',
          content: (
            <p>
              In labore ut cupidatat mollit pariatur eu. Sit consequat magna pariatur sunt minim
              sint fugiat sint deserunt aliqua ex sit minim. Eiusmod proident nostrud occaecat
              deserunt ea nisi amet excepteur velit. Commodo aute labore commodo ullamco in
              incididunt ut ex. Esse sunt ipsum enim et pariatur qui eu irure aliquip mollit ex
              excepteur do.
            </p>
          ),
        },
        {
          name: 'notice',
          title: 'Berapa lama periode pemberitahuan masa pensiun dapat diinformasikan?',
          content: (
            <p>
              Incididunt eu id proident nostrud sunt adipisicing sunt. Laboris aute Lorem aliqua
              voluptate non ipsum nisi est. Et voluptate ex sit laborum.
            </p>
          ),
        },
        {
          name: 'pension',
          title: 'Dapatkah Manfaat Pensiun diterima sebelum mencapai Usia Pensiun Normal?',
          content: (
            <p>
              Eiusmod tempor cupidatat voluptate est quis. Et nostrud velit tempor culpa velit in ad
              ex ut aliqua. Cupidatat occaecat ipsum Lorem voluptate. Eu excepteur incididunt tempor
              incididunt enim. Consequat nisi sint sunt pariatur duis ex sit mollit nulla quis. In
              fugiat adipisicing magna incididunt.
            </p>
          ),
        },
      ],
    },
  }

  const responsiveRowRender = content =>
    !isTabletOrMobile ? (
      <Row className={clsx('mb-5')}>
        <Col sm={12}>{content}</Col>
      </Row>
    ) : (
      <div className={clsx('normalize-container-fluid', 'mb-5')}>{content}</div>
    )

  return (
    <Container as="section" fluid>
      <h1 className="font-weight-light display-2 mb-4">{meta[slug].title}</h1>
      <Row className={clsx('mb-4')}>
        <Col md={8}>
          <p className="pr-4 pr-md-5 mr-md-5 font-weight-light">
            Enim sagittis risus, diam faucibus a tristique justo, vitae, laoreet. Dui eu, urna
            tellus vitae felis mauris. Molestie purus purus turpis urna ut at sit quam.
          </p>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col md={8} className={clsx('my-3 my-md-0')}>
          <Card body className={clsx('on-boarding__target')} style={{ height: '100%' }}>
            <Row style={{ height: '100%' }} className={clsx('py-3')}>
              <Col
                md={{ span: 6, offset: 1 }}
                className="d-flex flex-column align-items-start justify-content-between">
                {meta[slug]?.form}
              </Col>
              <Col md={{ span: 4 }}>
                <span className="font-weight-light">Pilih Tipe Dokumen*</span>
                <fieldset className={clsx('d-flex d-md-block')}>
                  <Form.Radio
                    checked
                    name="direction"
                    value="xls"
                    label="XLS"
                    formGroupProps={{ className: clsx('mt-2', 'mt-md-3') }}
                  />
                  <Form.Radio
                    checked
                    name="direction"
                    value="pdf"
                    label="PDF"
                    className={clsx('ml-4 ml-md-0')}
                    formGroupProps={{ className: clsx('mt-2', 'mt-md-3') }}
                  />
                </fieldset>
              </Col>
              <Col md={{ span: 5, offset: 1 }} className={clsx('d-flex align-items-end')}>
                <Button variant="coral" block>
                  Proses Dokumen
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col md={4} className={clsx('my-3 my-md-0')}>
          <Card className={clsx('on-boarding__target-2')}>
            <Card.Header>
              <span className="d-flex">
                Riwayat Dokumen
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement="top"
                  overlay={
                    <Popover>
                      <Popover.Content>
                        Est consectetur sit est enim voluptate ex cupidatat non.
                      </Popover.Content>
                    </Popover>
                  }>
                  <i className="mi-info lg ml-1 cursor-pointer"></i>
                </OverlayTrigger>
              </span>
            </Card.Header>
            <ListGroup variant="flush">
              {meta[slug].documents.map((doc, key) => (
                <Card key={key} as={ListGroup.Item}>
                  <Card.Body className={clsx('px-0 py-2')}>
                    <Button as={Link} to="/" variant="anchor" className={clsx('card-anchor')} />
                    <h1 className="d-inline-block h6 font-weight-light mb-0 w-75">
                      <span className="mr-1">{doc.label}</span>
                      <Badge
                        pill
                        size="xs"
                        variant={doc.type.toLowerCase().concat('Color')}
                        className="mt-1 vertical-align-top">
                        {doc.type.toUpperCase()}
                      </Badge>
                    </h1>
                    <div className="d-flex align-items-center mt-4">
                      <p className="mb-0 text-sm mr-2">
                        <strong>{doc.date}</strong>
                      </p>
                      <StatusText {...doc.status} dotSize="sm" />
                    </div>
                  </Card.Body>
                </Card>
              ))}
            </ListGroup>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <h1 className="font-weight-light">FAQ Terkait</h1>
        </Col>
      </Row>
      {responsiveRowRender(
        <Accordion.Rowed itemsPerRow={meta[slug].faqs.length} items={meta[slug].faqs} />
      )}
      <div className={clsx('my-4', 'pt-2 pb-3')}>
        <Billboard.Contact />
      </div>
    </Container>
  )
}

export default Claim
