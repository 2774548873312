// @flow
import * as React from 'react'
import { Billboard } from 'components'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import { keys } from 'lodash'
import clsx from 'clsx'
import DummyUser from 'assets/images/dummy-user.png'

// Styles
import styles from './style.module.scss'

const Profile = (): React$Element<'section'> => {
  const data = {
    details: [
      {
        label: 'No. Peserta',
        value: 30667803,
      },
      {
        label: 'No. Karyawan',
        value: '-',
      },
      {
        label: 'Kode Pelanggan',
        value: 30668759,
      },
      {
        label: 'No. Identitas',
        value: 11918910,
      },
      {
        label: 'No. Grup',
        value: 13579,
      },
      {
        label: 'Tanggal Lahir',
        value: window.moment(['1', '1', '1990']).format('DD MMMM YYYY'),
      },
      {
        label: 'Nama Lengkap',
        value: 'MLI_11918910',
      },
      {
        label: 'Usia Pensiun Normal',
        value: 57,
      },
    ],
    heirs: [
      {
        label: 'Nama Ahli Waris 1',
        value: '-',
      },
      {
        label: 'Nama Ahli Waris 2',
        value: '-',
      },
      {
        label: 'Nama Ahli Waris 3',
        value: '-',
      },
      {
        label: 'Nama Ahli Waris 4',
        value: '-',
      },
    ],
    address: {
      work: {
        label: 'Alamat Kantor',
        value: '-',
        details: [
          {
            label: 'Kota',
            value: '-',
          },
          {
            label: 'Kode Pos',
            value: '-',
          },
        ],
      },
      home: {
        label: 'Alamat Rumah',
        value: 'Sampoerna Strategic Square, South Tower, Jl. Jend. Sudirman Kav 45-46',
        details: [
          {
            label: 'Kota',
            value: '-',
          },
          {
            label: 'Kode Pos',
            value: '-',
          },
        ],
      },
    },
    contact: [
      {
        label: 'Kantor',
        value: '-',
      },
      {
        label: 'Handphone',
        value: '-',
      },
      {
        label: 'Rumah',
        value: '-',
      },
      {
        label: 'Email',
        value: '-',
      },
    ],
  }
  return (
    <Container as="section" fluid>
      <Row className="mb-5 mt-3">
        <Col as={Row} md={12} xs={12} className="align-items-center mb-3">
          <Col className={clsx(styles.avatar__wrapper)}>
            <Image src={DummyUser} roundedCircle />
            <Button variant="coral-icon" className={clsx('mi-pencil', styles.avatar__button)} />
          </Col>
          <Col xs={12}>
            <div className="hidden-lg hidden-md hidden-sm">&nbsp;</div>
            <h1 className="mb-1">John Doe</h1>
            <h2 className="h5 font-weight-light">johndoe12345</h2>
          </Col>
        </Col>
        <Col as={Row} md={12} className="pt-5">
          <Col md={12}>
            <h2 className="mb-4">Data Pribadi</h2>
          </Col>
          {data.details.map(({ label, value }, index) => (
            <Col key={index} md={6} xs={6} className="mb-4">
              <span className="d-block font-weight-light mb-1">{label}</span>
              <h3 className="h5">{value}</h3>
            </Col>
          ))}
        </Col>
        <Col as={Row} md={12} className="pt-5">
          <Col md={12}>
            <h2 className="mb-4">Ahli Waris</h2>
          </Col>
          {data.heirs.map(({ label, value }, index) => (
            <Col key={index} md={6} className="mb-4">
              <span className="d-block font-weight-light mb-1">{label}</span>
              <h3 className="h5">{value}</h3>
            </Col>
          ))}
        </Col>
        <Col as={Row} md={12} className="pt-5">
          <Col md={12}>
            <h2 className="mb-4">Alamat</h2>
          </Col>
          {keys(data.address).map((key, i) => (
            <Col as={Row} key={key} md={12}>
              <Col md={6}>
                <span className="d-block font-weight-light mb-1">{data.address[key].label}</span>
                <h3 className="h5 w-75">{data.address[key].value}</h3>
              </Col>
              <Col as={Row} md={6}>
                {data.address[key].details.map(({ label, value }, index) => (
                  <Col key={key.concat(index.toString())} sm={12} xs={6} className="mb-4">
                    <span className="d-block font-weight-light mb-1">{label}</span>
                    <h3 className="h5">{value}</h3>
                  </Col>
                ))}
              </Col>
              {!(i % 2) && <Col sm={12} as="hr" className="mb-5" />}
            </Col>
          ))}
        </Col>
        <Col as={Row} md={12} className="pt-5">
          <Col md={12}>
            <h2 className="mb-4">Telepon & Email</h2>
          </Col>
          {data.contact.map(({ label, value }, index) => (
            <Col key={index} md={6} className="mb-4">
              <span className="d-block font-weight-light mb-1">{label}</span>
              <h3 className="h5">{value}</h3>
            </Col>
          ))}
        </Col>
      </Row>
      <div className={clsx('my-4', 'pt-2 pb-3')}>
        <Billboard.Contact
          label={
            <>
              Pengajuan <br />
              Perubahan Data
            </>
          }
        />
      </div>
    </Container>
  )
}

export default Profile
