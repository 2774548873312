// @flow
import * as React from 'react'
import clsx from 'clsx'
import { Col, Container, Row } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'

// Styles
import styles from './style.module.scss'
import { Accordion } from 'components'

export type AboutProps = { ... }

export const About = ({ ...restProps }: AboutProps): React$Element<'section'> => {
  const guides = [
    {
      title: 'Pendaftaran Online di EBClick',
      content: (
        <ol className="pl-4 pr-md-5">
          <li>Pergi ke ke halaman utama Manulife Indonesia di www.manulife.co.id</li>
          <li>Klik e-EB Services</li>
          <li>Pilih Layanan Online, klik Layanan Kumpulan, pilih Produk dan klik LOGIN</li>
          <li>Pilih Pengelolaan Kata Sandi dan klik Tautan Permohonan Kata Sandi</li>
          <li>
            Lengkapi informasi pada Form Permohonan Kata Sandi dan klik Serahkan untuk mendaftar
          </li>
        </ol>
      ),
    },
    {
      title: 'Mengaktifkan Akun EBClick',
      content: (
        <ol className="pl-4 pr-md-5">
          <li>Est ex dolore dolor esse mollit laboris do do proident nisi.</li>
          <li>Laboris amet id nostrud laborum sit cillum eiusmod.</li>
          <li>
            Ullamco duis reprehenderit duis quis quis exercitation minim nulla nulla exercitation.
          </li>
        </ol>
      ),
    },
    {
      title: 'Cara Login di EBClick',
      content: (
        <ol className="pl-4 pr-md-5">
          <li>Est ex dolore dolor esse mollit laboris do do proident nisi.</li>
          <li>Laboris amet id nostrud laborum sit cillum eiusmod.</li>
          <li>
            Ullamco duis reprehenderit duis quis quis exercitation minim nulla nulla exercitation.
          </li>
        </ol>
      ),
    },
    {
      title: 'Lupa Kata Sandi',
      content: (
        <ol className="pl-4 pr-md-5">
          <li>Est ex dolore dolor esse mollit laboris do do proident nisi.</li>
          <li>Laboris amet id nostrud laborum sit cillum eiusmod.</li>
          <li>
            Ullamco duis reprehenderit duis quis quis exercitation minim nulla nulla exercitation.
          </li>
        </ol>
      ),
    },
    {
      title: 'Mengubah Kata Sandi',
      content: (
        <ol className="pl-4 pr-md-5">
          <li>Est ex dolore dolor esse mollit laboris do do proident nisi.</li>
          <li>Laboris amet id nostrud laborum sit cillum eiusmod.</li>
          <li>
            Ullamco duis reprehenderit duis quis quis exercitation minim nulla nulla exercitation.
          </li>
        </ol>
      ),
    },
  ].map((item, index) => ({ ...item, name: index.toString() }))

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })

  const responsiveRowRender = content =>
    !isTabletOrMobile ? (
      <Row className={clsx('mb-5')}>
        <Col sm={12}>{content}</Col>
      </Row>
    ) : (
      <div className={clsx('normalize-container-fluid', 'mb-5 pb-5')}>{content}</div>
    )

  return (
    <Container as="section" className={clsx(styles.wrapper)} fluid>
      <h1 className="font-weight-light display-2 mb-4">
        Tentang <span className="font-serif">EBClick</span>
      </h1>
      <Row className={clsx('mt-3')}>
        <Col md={7}>
          <p className="pr-md-5 mr-md-5 font-weight-light">
            Enim sagittis risus, diam faucibus a tristique justo, vitae, laoreet. Dui eu, urna
            tellus vitae felis mauris. Molestie purus purus turpis urna ut at sit quam.
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <h3 className="font-weight-light mt-5">Panduan Akun EBClick</h3>
        </Col>
      </Row>
      {responsiveRowRender(
        <Accordion.Rowed cols={2} itemsPerRow={(guides.length / 2).toFixed()} items={guides} />,
      )}
    </Container>
  )
}

export default About
