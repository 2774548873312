// @flow
import * as React from 'react'
import { Redirect } from 'react-router'

import About from './About'
import Features from './Features'

import type { Route } from 'router/routes'

export const pathname = '/guide'
export const route: Route = {
  routes: {
    '/about': {
      component: About,
    },
    '/features': {
      component: Features,
    },
    '/': {
      component: ({ layoutPath }) => <Redirect to={`${layoutPath}guide/about`} />,
    },
  },
}
