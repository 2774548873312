// @flow
import * as React from 'react'
import { Dashboard } from 'layouts'
import { Redirect } from 'react-router'

import * as Auth from './Auth'
import * as Guide from './Guide'
import * as Profile from './Profile'
import * as Investment from './Investment'
import Home from './Home'
import Ballance from './Ballance'
import Claim from './Claim'

import type { Route } from 'router/routes'

export const pathname = '/employee'
export const route: Route = {
  routes: {
    [Auth.pathname]: Auth.route,
    '/': {
      component: props => <Dashboard {...props} mode="employee" />,
      asLayout: true,
      routes: {
        '/home': {
          component: Home,
        },
        '/ballance': {
          component: Ballance,
        },
        '/claim': {
          component: Claim,
        },
        [Investment.pathname]: Investment.route,
        [Profile.pathname]: Profile.route,
        [Guide.pathname]: Guide.route,
        '/': {
          component: _ => <Redirect to={`${pathname}/home`} />,
        },
      },
    },
  },
}
