/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable no-empty-pattern */
// @flow
import clsx from 'clsx'
import * as React from 'react'
import * as yup from 'yup'
import {
  Col,
  Container,
  Row,
  Button,
  Tab,
  Card,
  ListGroup,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Billboard, Accordion, ECard, Form, Badge, Tabs, StatusText } from 'components'
import { useMediaQuery } from 'react-responsive'
import dummyDPLKCard from 'assets/images/dummy-dplk-card.png'

// Styles
import styles from './style.module.scss'
import { has } from 'lodash'

const schema = yup.object().shape({
  attendee_id: yup.number().required().integer(),
})

export type AttendeeProps = { ... }

export const Attendee = ({}: AttendeeProps): React$Element<'section'> => {
  const FAQs = [
    {
      name: 'process',
      title: 'Berapa lama waktu proses dokumen?',
      content: (
        <p>
          In labore ut cupidatat mollit pariatur eu. Sit consequat magna pariatur sunt minim sint
          fugiat sint deserunt aliqua ex sit minim. Eiusmod proident nostrud occaecat deserunt ea
          nisi amet excepteur velit. Commodo aute labore commodo ullamco in incididunt ut ex. Esse
          sunt ipsum enim et pariatur qui eu irure aliquip mollit ex excepteur do.
        </p>
      ),
    },
    {
      name: 'saldo',
      title: 'Bagaimana cara mendapatkan saldo rekening peserta?',
      content: (
        <p>
          Incididunt eu id proident nostrud sunt adipisicing sunt. Laboris aute Lorem aliqua
          voluptate non ipsum nisi est. Et voluptate ex sit laborum.
        </p>
      ),
    },
    {
      name: 'status',
      title: 'Apa perbedaan status karyawan tidak aktif dan berhenti?',
      content: (
        <p>
          Eiusmod tempor cupidatat voluptate est quis. Et nostrud velit tempor culpa velit in ad ex
          ut aliqua. Cupidatat occaecat ipsum Lorem voluptate. Eu excepteur incididunt tempor
          incididunt enim. Consequat nisi sint sunt pariatur duis ex sit mollit nulla quis. In
          fugiat adipisicing magna incididunt.
        </p>
      ),
    },
  ]

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })
  const responsiveRowRender = content =>
    !isTabletOrMobile ? (
      <Row className={clsx('mb-5')}>
        <Col sm={12}>{content}</Col>
      </Row>
    ) : (
      <div className={clsx('normalize-container-fluid', 'mb-5 pb-5')}>{content}</div>
    )

  const [status, setStatus] = React.useState('active')
  const [eCardVisible, setECardVisible] = React.useState(false)

  const { register, errors, formState } = useForm({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(schema),
  })

  return (
    <Container as="section" fluid>
      <h1 className="font-weight-light display-2 mb-4 mt-3 mt-md-5">
        Informasi <span className="font-serif">Peserta</span>
      </h1>
      <Row className={clsx('pb-5 mb-md-3')}>
        <Col md={6}>
          <p className="pr-md-5 mr-md-5 font-weight-light">
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
            laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
            architecto beatae vitae.
          </p>
        </Col>
        <Col md={6}>
          <Row className={clsx('mt-3 mt-md-0')}>
            <Col xs={4} sm={2} className="d-flex justify-content-center">
              <i className="mi-id-card xxl2"></i>
            </Col>
            <Col>
              <h2 className="h4 mt-1">Tanya Jawab Kepesertaan DPLK</h2>
              <p>Tanya jawab mengenai kepesertaan DPLK Manulife</p>
              <Button as={Link} to="/" variant="outline-anchor">
                Selengkapnya
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Tabs id="attendee" className={clsx(styles.tabs)} responsiveMode="dropdown">
        <Tab eventKey="attendee" title="Daftar Peserta">
          <Row className={clsx('py-4 mt-3')}>
            <Col md={6} className={clsx('my-3 my-md-0')}>
              <Card body style={{ height: '100%' }}>
                <Row style={{ height: '100%' }}>
                  <Col
                    md={{ span: 5, offset: 1 }}
                    className="d-flex flex-column align-items-start justify-content-between mb-2 mb-md-0">
                    <Form.Select
                      value={status}
                      onChange={setStatus}
                      label="Status Peserta"
                      formGroupProps={{ className: clsx('w-100') }}
                      menuProps={{ className: clsx('w-100') }}
                      items={[
                        { key: 'active', value: 'Aktif' },
                        { key: 'inactive', value: 'Tidak Aktif' },
                        { key: 'ended', value: 'Berhenti' },
                      ]}
                    />
                  </Col>
                  <Col md={{ span: 5, offset: 1 }}>
                    <span className="font-weight-light">Pilih Tipe Dokumen*</span>
                    <fieldset className={clsx('d-flex d-md-block')}>
                      <Form.Radio
                        checked
                        name="direction"
                        value="xls"
                        label="XLS"
                        formGroupProps={{ className: clsx('mt-2', 'mt-md-3') }}
                      />
                      <Form.Radio
                        checked
                        name="direction"
                        value="pdf"
                        label="PDF"
                        className={clsx('ml-4 ml-md-0')}
                        formGroupProps={{ className: clsx('mt-2', 'mt-md-3') }}
                      />
                    </fieldset>
                  </Col>
                  <Col md={{ span: 5, offset: 1 }} className={clsx('d-flex align-items-end')}>
                    <Button variant="coral" block>
                      Proses Dokumen
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col md={6} className={clsx('my-3 my-md-0')}>
              <Card>
                <Card.Header>
                  <span className="d-flex">
                    Riwayat Dokumen
                    <OverlayTrigger
                      trigger={['hover', 'focus']}
                      placement="top"
                      overlay={
                        <Popover>
                          <Popover.Content>
                            Est consectetur sit est enim voluptate ex cupidatat non.
                          </Popover.Content>
                        </Popover>
                      }>
                      <i className="mi-info lg ml-1 cursor-pointer"></i>
                    </OverlayTrigger>
                  </span>
                </Card.Header>
                <ListGroup variant="flush">
                  <Card as={ListGroup.Item}>
                    <Card.Body className={clsx('px-0 py-2')}>
                      <Button as={Link} to="/" variant="anchor" className={clsx('card-anchor')} />
                      <h1 className="d-inline-block h6 font-weight-light mb-0 w-75">
                        <span className="mr-1">Daftar Karyawan__Aktif_240121</span>
                        <Badge
                          pill
                          size="xs"
                          variant="xlsColor"
                          className="mt-1 vertical-align-top">
                          XLS
                        </Badge>
                      </h1>
                      <div className="d-flex align-items-center mt-4">
                        <p className="mb-0 text-sm mr-2">
                          <strong>24/01/20</strong>
                        </p>
                        <StatusText dotSize="sm" type="success" label="Siap Diunduh" />
                      </div>
                    </Card.Body>
                  </Card>
                  <Card as={ListGroup.Item}>
                    <Card.Body className={clsx('px-0 py-2')}>
                      <Button as={Link} to="/" variant="anchor" className={clsx('card-anchor')} />
                      <h1 className="d-inline-block h6 font-weight-light mb-0 w-75">
                        <span className="mr-1">Daftar Karyawan__Tidak-Aktif_240121</span>
                        <Badge
                          pill
                          size="xs"
                          variant="pdfColor"
                          className="mt-1 vertical-align-top">
                          PDF
                        </Badge>
                      </h1>
                      <div className="d-flex align-items-center mt-4">
                        <p className="mb-0 text-sm mr-2">
                          <strong>24/01/20</strong>
                        </p>
                        <StatusText dotSize="sm" type="danger" label="Gagal diproses" />
                      </div>
                    </Card.Body>
                  </Card>
                </ListGroup>
              </Card>
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="attendee_displacement" title="Daftar Perpindahan Peserta">
          <Row className={clsx('py-4 mt-3')}>
            <Col md={6} className={clsx('my-3 my-md-0')}>
              <Card body style={{ height: '100%' }}>
                <Row style={{ height: '100%' }}>
                  <Col
                    md={{ span: 5, offset: 1 }}
                    className="d-flex flex-column align-items-start justify-content-between mb-2 mb-md-0">
                    <Form.DateRangePicker
                      hasGrow
                      fromInputProps={{ label: 'Dari', placeholder: 'Pilih Tanggal' }}
                      toInputProps={{ label: 'Hingga', placeholder: 'Pilih Tanggal' }}
                      colProps={{ sm: 12, className: clsx('mb-4') }}
                    />
                  </Col>
                  <Col md={{ span: 5, offset: 1 }}>
                    <span className="font-weight-light">Pilih Tipe Dokumen*</span>
                    <fieldset className={clsx('d-flex d-md-block')}>
                      <Form.Radio
                        checked
                        name="direction"
                        value="xls"
                        label="XLS"
                        formGroupProps={{ className: clsx('mt-2', 'mt-md-3') }}
                      />
                      <Form.Radio
                        checked
                        name="direction"
                        value="pdf"
                        label="PDF"
                        className={clsx('ml-4 ml-md-0')}
                        formGroupProps={{ className: clsx('mt-2', 'mt-md-3') }}
                      />
                    </fieldset>
                  </Col>
                  <Col md={{ span: 5, offset: 1 }} className={clsx('d-flex align-items-end')}>
                    <Button variant="coral" block>
                      Proses Dokumen
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col md={6} className={clsx('my-3 my-md-0')}>
              <Card>
                <Card.Header>
                  <span className="d-flex">
                    Riwayat Dokumen
                    <OverlayTrigger
                      trigger={['hover', 'focus']}
                      placement="top"
                      overlay={
                        <Popover>
                          <Popover.Content>
                            Est consectetur sit est enim voluptate ex cupidatat non.
                          </Popover.Content>
                        </Popover>
                      }>
                      <i className="mi-info lg ml-1 cursor-pointer"></i>
                    </OverlayTrigger>
                  </span>
                </Card.Header>
                <ListGroup variant="flush">
                  <Card as={ListGroup.Item}>
                    <Card.Body className={clsx('px-0 py-2')}>
                      <Button as={Link} to="/" variant="anchor" className={clsx('card-anchor')} />
                      <h1 className="d-inline-block h6 font-weight-light mb-0 w-75">
                        <span className="mr-1">Daftar Karyawan__Aktif_240121</span>
                        <Badge
                          pill
                          size="xs"
                          variant="xlsColor"
                          className="mt-1 vertical-align-top">
                          XLS
                        </Badge>
                      </h1>
                      <div className="d-flex align-items-center mt-4">
                        <p className="mb-0 text-sm mr-2">
                          <strong>24/01/20</strong>
                        </p>
                        <StatusText dotSize="sm" type="success" label="Siap Diunduh" />
                      </div>
                    </Card.Body>
                  </Card>
                  <Card as={ListGroup.Item}>
                    <Card.Body className={clsx('px-0 py-2')}>
                      <Button as={Link} to="/" variant="anchor" className={clsx('card-anchor')} />
                      <h1 className="d-inline-block h6 font-weight-light mb-0 w-75">
                        <span className="mr-1">Daftar Karyawan__Tidak-Aktif_240121</span>
                        <Badge
                          pill
                          size="xs"
                          variant="pdfColor"
                          className="mt-1 vertical-align-top">
                          PDF
                        </Badge>
                      </h1>
                      <div className="d-flex align-items-center mt-4">
                        <p className="mb-0 text-sm mr-2">
                          <strong>24/01/20</strong>
                        </p>
                        <StatusText dotSize="sm" type="danger" label="Gagal diproses" />
                      </div>
                    </Card.Body>
                  </Card>
                </ListGroup>
              </Card>
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="ppukp" title="Kartu Peserta PPUKP">
          <Row className={clsx('py-4 mt-3')}>
            <Col md={12} className={clsx('my-3 my-md-0')}>
              <Card body>
                <Row>
                  <Col md={6}>
                    <Row style={{ height: '100%' }}>
                      <Col md={{ span: 5, offset: 1 }} className="d-flex flex-column mb-2 mb-md-0">
                        <Form.Select
                          value={status}
                          onChange={setStatus}
                          label="Status Peserta"
                          formGroupProps={{ className: clsx('w-100') }}
                          menuProps={{ className: clsx('w-100') }}
                          items={[
                            { key: 'active', value: 'Aktif' },
                            { key: 'inactive', value: 'Tidak Aktif' },
                            { key: 'ended', value: 'Berhenti' },
                          ]}
                        />
                        <Form.Input
                          hasGrow
                          type="text"
                          placeholder="contoh: 1234567"
                          label="Masukkan Nomor Peserta*"
                          formGroupProps={{ className: clsx('w-100', 'mb-4') }}
                          hasValidation
                          ref={register}
                          name="attendee_id"
                          isInvalid={errors.attendee_id}
                          isValid={!has(errors, 'attendee_id') && formState.isValid}
                          feedbackMessage={errors.attendee_id && '*Nomor Peserta Tidak Terdaftar'}
                          append={
                            <OverlayTrigger
                              trigger={['hover', 'focus']}
                              placement="top"
                              overlay={
                                <Popover>
                                  <Popover.Content>
                                    Est consectetur sit est enim voluptate ex cupidatat non.
                                  </Popover.Content>
                                </Popover>
                              }>
                              <i className="mi-info"></i>
                            </OverlayTrigger>
                          }
                        />
                      </Col>
                      <Col md={{ span: 5, offset: 1 }}>
                        <span className="font-weight-light">Pilih Tipe Dokumen*</span>
                        <fieldset className={clsx('d-flex d-md-block')}>
                          <Form.Radio
                            checked
                            name="file_type"
                            value="jpg"
                            label="JPG"
                            formGroupProps={{ className: clsx('mt-2', 'mt-md-3') }}
                          />
                        </fieldset>
                      </Col>
                      <Col md={{ span: 5, offset: 1 }} className={clsx('d-flex align-items-end')}>
                        <Button
                          variant="coral"
                          block
                          onClick={() => setECardVisible(!eCardVisible)}>
                          Proses Dokumen
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={11}>
                        <Card>
                          <Card.Body className={styles.ecard__wrapper}>
                            <Button
                              disabled={!eCardVisible}
                              variant={eCardVisible ? 'outline-coral' : 'coral'}
                              className={styles.ecard__button}>
                              <i className={clsx('mi mi-download')}></i> Unduh
                            </Button>
                            <ECard imgSrc={eCardVisible ? dummyDPLKCard : null} />
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Tab>
      </Tabs>
      <Row className="mt-5">
        <Col md={12}>
          <h1 className="font-weight-light">FAQ Terkait</h1>
        </Col>
      </Row>
      {responsiveRowRender(<Accordion.Rowed itemsPerRow={FAQs.length} items={FAQs} />)}
      <div className={clsx('my-4', 'pt-2 pb-3')}>
        <Billboard.Contact />
      </div>
    </Container>
  )
}

export default Attendee
