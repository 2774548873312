// @flow
import * as React from 'react'
import { Redirect } from 'react-router'

import Me from './Me'
import ECard from './ECard'
import ChangePassword from './ChangePassword'

import type { Route } from 'router/routes'

export const pathname = '/profile'
export const route: Route = {
  routes: {
    '/me': {
      component: Me,
    },
    '/e-card': {
      component: ECard,
    },
    '/change-password': {
      component: ChangePassword,
    },
    '/': {
      component: ({ layoutPath }) => <Redirect to={`${layoutPath}profile/me`} />,
    },
  },
}
