// @flow
import * as React from 'react'
import { Dashboard } from 'layouts'
import { Redirect } from 'react-router'

import * as Auth from './Auth'
import * as Guide from './Guide'
import * as Profile from './Profile'
import * as Investment from './Investment'
import Home from './Home'
import Attendee from './Attendee'
import Claim from './Claim'
import Contribution from './Contribution'
import Ballance from './Ballance'

import type { Route } from 'router/routes'

export const pathname = '/employer'
export const route: Route = {
  routes: {
    [Auth.pathname]: Auth.route,
    '/': {
      component: props => <Dashboard {...props} mode="employer" />,
      asLayout: true,
      routes: {
        '/home': {
          component: Home,
        },
        '/attendee': {
          component: Attendee,
        },
        '/claim': {
          routes: {
            '/:slug': {
              component: Claim,
            },
            '/': {
              component: () => <Redirect to={`${pathname}/claim/incomplete-submission`} />,
            },
          },
        },
        '/contribution': {
          routes: {
            '/:slug': {
              component: Contribution,
            },
            '/': {
              component: () => <Redirect to={`${pathname}/contribution/transactions`} />,
            },
          },
        },
        '/ballance': {
          routes: {
            '/:slug': {
              component: Ballance,
            },
            '/': {
              component: () => <Redirect to={`${pathname}/ballance/company-account`} />,
            },
          },
        },
        [Investment.pathname]: Investment.route,
        [Profile.pathname]: Profile.route,
        [Guide.pathname]: Guide.route,
        '/': {
          component: _ => <Redirect to={`${pathname}/home`} />,
        },
      },
    },
  },
}
