/* eslint-disable jsx-a11y/anchor-is-valid */
// @flow
import * as React from 'react'
import clsx from 'clsx'
import { Form } from 'components'
import { useForm } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEqual } from 'lodash'
import * as yup from 'yup'

import styles from './style.module.scss'

const schema = yup.object().shape({
  otpCode: yup.number().positive().max(6).required(),
})

export const OTP = ({
  layoutPath,
  parentPath,
  history,
  title,
  nextPath,
  ...rest
}: any): React$Element<'section'> => {
  const { type } = useParams()
  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit = data => console.log(data)

  const userInfo = type === 'sms' ? '+62 812 3*** **90' : 'johndo*****il.com'

  return (
    <section className={clsx('container', 'py-4', styles.container)}>
      <h1 className="display-1">{title}</h1>
      <form className="mb-auto" onSubmit={handleSubmit(onSubmit)}>
        <h2 className={clsx(styles.subheading, 'text-darkNavy', 'my-5')}>
          Silahkan masukkan kode verifikasi (OTP) yang Anda terima{' '}
          <Form.Input
            type="number"
            name="otp_code"
            underlined="dashed"
            placeholder="123456"
            ref={register('otpCode')}
            formGroupProps={{ className: clsx(styles.otpInput) }}
            onChange={({ currentTarget: { value } }) =>
              isEqual(value, '123456') && history.push(nextPath)
            }
            onKeyDown={e =>
              ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) &&
              e.currentTarget.value.length >= 6 &&
              e.preventDefault()
            }
          />
        </h2>
        <h3 className={clsx('h5', 'font-weight-light', 'my-4')}>
          Kami telah mengirimkan {type === 'sms' ? 'SMS' : type} berisi 6 digit <br />
          kode verifikasi (OTP) ke <strong>{userInfo}</strong>
        </h3>
        <ul className={clsx('union-list', 'font-weight-light')}>
          <li>
            Kode OTP akan kadaluarsa dalam <strong>00:59</strong>
          </li>
          <li>
            Tidak menerima kode OTP?{' '}
            <a href="#" className={clsx('decoration-underlined')}>
              <strong>Kirim Ulang</strong>
            </a>
          </li>
          <li>
            Kirim ke nomor lain?{' '}
            <Link to={`${layoutPath}/register`} className={clsx('decoration-underlined')}>
              <strong>Ubah Data</strong>
            </Link>
          </li>
        </ul>
      </form>
    </section>
  )
}

OTP.defaultProps = {
  title: 'Title',
  nextPath: '/set-password',
}

export default OTP
