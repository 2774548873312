// @flow
import * as React from 'react'
import clsx from 'clsx'
import { Form } from 'components'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Button, Col, OverlayTrigger, Popover } from 'react-bootstrap'

import styles from './style.module.scss'

export const Register = ({ layoutPath, parentPath }: any): React$Element<'section'> => {
  const { register, handleSubmit, errors } = useForm()
  const onSubmit = data => console.log(data)

  return (
    <section className={clsx('container', 'py-4', styles.container)}>
      <h1 className="display-1">Registrasi</h1>
      <Form noValidate className={clsx(styles.form)} onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <Form.Input
            hasGrow
            type="text"
            hasValidation
            appendCursorHelp
            name="attendee_id"
            label="Nomor Peserta"
            isInvalid={!!errors.attendee_id}
            ref={register({ required: true })}
            formGroupProps={{ as: Col, sm: 12 }}
            append={
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={
                  <Popover>
                    <Popover.Content>
                      Sunt veniam excepteur amet tempor deserunt voluptate sint.
                    </Popover.Content>
                  </Popover>
                }>
                <i className="mi-info px-1"></i>
              </OverlayTrigger>
            }
          />
          <Col sm={12}>
            <Form.Label className="mb-0 label-grow">Tanggal Lahir</Form.Label>
            <Form.Row>
              <Form.Select
                name="date"
                placeholder="Tanggal"
                formGroupProps={{ as: Col, xs: 4, className: 'mt-0' }}
                menuProps={{ className: clsx('w-100') }}
                items={Array.from(Array(31).keys()).map(i => ({ key: i, value: i + 1 }))}
              />
              <Form.Select
                name="month"
                placeholder="Bulan"
                formGroupProps={{ as: Col, xs: 4, className: 'mt-0' }}
                menuProps={{ className: clsx('w-100') }}
                items={[
                  'Januari',
                  'Februari',
                  'Maret',
                  'April',
                  'Mei',
                  'Juni',
                  'Juli',
                  'Agustus',
                  'September',
                  'Oktober',
                  'November',
                  'Desember',
                ].map(month => ({ key: month, value: month }))}
              />
              <Form.Select
                name="year"
                placeholder="Tahun"
                formGroupProps={{ as: Col, xs: 4, className: 'mt-0' }}
                menuProps={{ className: clsx('w-100') }}
                items={Array.from(Array(22).keys()).map(i => ({
                  key: i,
                  value: `20${i.toString().length >= 2 ? i : `0${i}`}`,
                }))}
              />
            </Form.Row>
          </Col>
          <Col sm={12} className={clsx(['mt-2', styles.form__actions])}>
            <Button as={Link} to={`${layoutPath}/signin`} variant="outline-coral" className="mr-2">
              Batal
            </Button>
            <Button as={Link} to={`${parentPath}/verification`} variant="coral" type="submit">
              Lanjut
            </Button>
          </Col>
        </Form.Row>
      </Form>
      <p className={styles.text__signin}>
        Sudah punya akun EBClick?{' '}
        <Link to={`${layoutPath}/signin`} className={clsx('decoration-underlined')}>
          <strong>Masuk</strong>
        </Link>
      </p>
    </section>
  )
}

export default Register
