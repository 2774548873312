// @flow
import * as React from 'react'
import clsx from 'clsx'
import { Form } from 'components'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Button, Col, OverlayTrigger, Popover } from 'react-bootstrap'

import styles from './style.module.scss'

export const SignIn = ({
  layoutPath,
  history,
  registerable,
  labelId,
}: any): React$Element<'section'> => {
  const { register, handleSubmit, errors } = useForm()
  const onSubmit = _data => history.push('/employee/home')

  return (
    <section className={clsx('container', 'py-4', styles.container)}>
      <h1 className="display-1">Sign In</h1>
      <Form noValidate className={clsx(styles.form)} onSubmit={handleSubmit(onSubmit)}>
        <Form.Row className="align-items-center">
          <Form.Input
            hasGrow
            type="text"
            hasValidation
            appendCursorHelp
            name="attendee_id"
            label={labelId}
            isInvalid={!!errors.attendee_id}
            ref={register({ required: true })}
            formGroupProps={{ as: Col, sm: 12 }}
            append={
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={
                  <Popover>
                    <Popover.Content>
                      Sunt veniam excepteur amet tempor deserunt voluptate sint.
                    </Popover.Content>
                  </Popover>
                }>
                <i className="mi-info px-1"></i>
              </OverlayTrigger>
            }
          />
          <Form.Input
            hasGrow
            password
            name="password"
            label="Kata Sandi"
            ref={register({ required: true })}
            formGroupProps={{ as: Col, sm: 12 }}
          />
          <Form.Checkbox
            label="Ingat Saya"
            formGroupProps={{ as: Col, xs: 6, className: 'my-0' }}
          />
          <Col xs={6} className="text-right">
            <Link to={`${layoutPath}/reset-password`} className={clsx('decoration-underlined')}>
              <strong>Lupa Kata Sandi</strong>
            </Link>
          </Col>
          <Col xs={12} className="mt-5">
            <Button as="button" variant="coral" type="submit">
              Masuk
            </Button>
          </Col>
        </Form.Row>
      </Form>
      {registerable && (
        <p className={styles.text__register}>
          Belum punya akun EBClick?{' '}
          <Link to={`${layoutPath}/register`} className={clsx('decoration-underlined')}>
            <strong>Daftar Sekarang</strong>
          </Link>
        </p>
      )}
    </section>
  )
}

SignIn.defaultProps = {
  registerable: true,
  labelId: 'Nomor Peserta',
}

export default SignIn
