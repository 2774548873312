// @flow
import * as React from 'react'
import clsx from 'clsx'
import { ReactComponent as LogoIcon } from 'assets/images/svgs/logo/icon.svg'
import { ReactComponent as LogoBrand } from 'assets/images/svgs/logo/brand.svg'
import { Container, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Form } from 'components'

// Styles
import styles from './style.module.scss'

// Sub-Components
import Mobile from './Mobile'

type Props = {
  mode: 'employee' | 'employer',
  langSelector?: boolean,
  appendToNav?: React$Node,
  hideBrand?: boolean,
  logoIcon?: boolean,
  className?: Array<any> | Object | String,
  fluid?: boolean,
  headerRef?: React.Ref<any>,
  logoIconColor: string,
  ...
}

// eslint-disable-next-line no-empty-pattern
const Header = ({
  langSelector,
  appendToNav,
  logoIcon,
  fluid,
  headerRef,
  hideBrand,
  mode,
  logoIconColor,
  ...rest
}: Props): React$Element<'header'> => {
  const [lang, setLang] = React.useState('id')

  return (
    <header {...rest} ref={headerRef} className={clsx(styles.header, rest.className)}>
      <Nav className="navbar navbar-light px-0">
        <Container fluid={fluid}>
          {!hideBrand ? (
            <Link className={clsx(['navbar-brand', styles.header__brand])} to={`/${mode}`}>
              {logoIcon && (
                <div className={clsx(styles.header__brand__icon)}>
                  <LogoIcon fill={logoIconColor} />
                </div>
              )}
              <LogoBrand fill="#000" />
            </Link>
          ) : (
            <div></div>
          )}
          <Nav className={clsx('d-flex', 'align-items-center')}>
            {langSelector && (
              <Form.Select
                name="date"
                value={lang}
                underlined={false}
                onChange={setLang}
                formGroupProps={{ className: 'm-0' }}
                inputGroupProps={{
                  className: clsx(styles.header__langDropdown),
                  formControlProps: { className: clsx('text-center') },
                }}
                items={[
                  { key: 'id', value: 'ID' },
                  { key: 'en', value: 'EN' },
                ]}
              />
            )}
            {appendToNav}
          </Nav>
        </Container>
      </Nav>
    </header>
  )
}

Header.Mobile = Mobile

Header.defaultProps = ({
  mode: 'employee',
  langSelector: false,
  appendToNav: null,
  logoIcon: true,
  hideBrand: false,
  fluid: false,
  logoIconColor: '#000',
}: Props)

export default Header
