// @flow

import * as React from 'react'
import clsx from 'clsx'
import { has, isEmpty } from 'lodash'
import { Link } from 'react-router-dom'
import { Button, Container } from 'react-bootstrap'
import { Header, Sidebar, SearchBar, Footer, Navigation } from 'components'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { store } from 'react-notifications-component'
import MediaQuery, { useMediaQuery } from 'react-responsive'

// Styles
import styles from './style.module.scss'

// Routes
import navigations from './navigations'
import { matchPath } from 'react-router-dom/cjs/react-router-dom.min'

type Props = {
  mode: $Keys<typeof navigations>,
  routesBlock: ({}) => React$Element<any> | null,
  children?: React.Node,
  layoutPath?: string,
  location?: any,
  notification: boolean,
  ...
}

class Pages extends React.PureComponent<{
  routesBlock: ({}) => React$Element<any> | null,
  children?: React.Node,
  layoutPath?: string,
  location?: any,
  ...
}> {
  render() {
    const RouteBlock = this.props.routesBlock

    return !!RouteBlock ? (
      <SwitchTransition>
        <CSSTransition
          key={this.props.location?.pathname}
          timeout={300}
          classNames="transition-slide"
          unmountOnExit>
          <div className={clsx(styles.content)}>
            <RouteBlock
              {...this.props}
              renderRoute={(RouteComponent, route, path) => {
                const TransitionedComponent = routeProps => <route.component {...routeProps} />

                return (
                  <RouteComponent
                    {...route}
                    key={path}
                    path={path}
                    component={TransitionedComponent}
                    layoutPath={
                      !!route.asLayout
                        ? path
                        : !!this.props.layoutPath && !isEmpty(this.props?.layoutPath)
                        ? this.props?.layoutPath
                        : ''
                    }
                  />
                )
              }}
            />
            <Footer className={clsx(styles.footer)} layoutType="dashboard" />
          </div>
        </CSSTransition>
      </SwitchTransition>
    ) : (
      !!this.props.children && this.props.children
    )
  }
}

export const Dashboard = ({ mode, notification, ...rest }: Props): React$Element<any> => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })

  const headerRef = React.useRef<HTMLElement | null>(null)
  const [currLocation, setCurrLocation] = React.useState<string>((rest.location: any).pathname)
  const [hasShowedSearchBar, setShowSearchBar] = React.useState<boolean>(false)
  const [hasShowedNavigation, setShowNavigation] = React.useState<boolean>(false)
  const [hasSidebarChildNavOpen, setHasSidebarChildNavOpen] = React.useState<boolean>(false)

  const handleOnScroll = ({ target: { scrollingElement } }) => {
    const height = headerRef.current?.clientHeight
    const headerClassList: any = headerRef.current?.classList

    if (!!height && !isTabletOrMobile) {
      const toggle = scrollingElement.scrollTop >= height ? 'add' : 'remove'

      headerClassList[toggle]('opacity-90')
      headerClassList[toggle]('bg-white')
      headerClassList[toggle]('shadow-sm')
    }
  }

  React.useLayoutEffect(() => {
    window.addEventListener('scroll', handleOnScroll)

    return () => {
      window.removeEventListener('scroll', handleOnScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    const pathname = (rest.location: any).pathname
    if (currLocation !== pathname) {
      setCurrLocation(pathname)

      const hasChildsNavs = navigations[mode].filter(
        item =>
          !!matchPath(pathname, { path: item.to }) && has(item, 'submenu') && !!item.submenu?.length
      )

      if (!hasChildsNavs.length && hasShowedNavigation) setShowNavigation(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.location, currLocation])

  return (
    <Container
      as="main"
      id="main-dashboard"
      className={clsx('px-0', styles.wrapper, hasSidebarChildNavOpen && styles.showSidebarChild)}
      fluid>
      {/* mobile/tablet */}
      <MediaQuery maxWidth={992}>
        <Header.Mobile
          fluid
          layoutType="dashboard"
          mode={mode}
          logoIcon={true}
          headerRef={headerRef}
          className={clsx(styles.header)}
          hideBrand={hasSidebarChildNavOpen}
          langSelector={false}
          appendToNav={
            <>
              {notification && (
                <Button
                  variant="icon"
                  className="mr-3 mi-notification lg"
                  onClick={() => {
                    store.addNotification({
                      content: notifProps => (
                        <div
                          {...notifProps}
                          className="notification__item notification__item--success">
                          <div className="notification__icon">
                            <i className="mi-badge-ok"></i>
                          </div>
                          <div className="notification__content">
                            <div
                              className="notification__close"
                              onClick={() => store.removeNotification(notifProps.id)}></div>
                            <div className="notification__title">
                              <strong>Dokumen Siap Diunduh</strong> (Info
                              Peserta_Alexander-12345_020221)
                            </div>
                            <div className="notification__message">
                              Sit egestas elementum dignissim eros, bibendum adipiscing non sapien
                              viverra. Enim elit nulla in rhoncus.
                            </div>
                          </div>
                        </div>
                      ),
                      insert: 'bottom',
                      container: 'bottom-full',
                      dismiss: {
                        duration: 100000,
                        click: false,
                        touch: false,
                      },
                    })
                  }}
                />
              )}
            </>
          }
          onNavToggleClick={setShowNavigation}
          navShowed={hasShowedNavigation}
        />

        <Navigation.Mobile
          position="fixed"
          homeLink={`/${mode}/home`}
          navItems={navigations[mode]}
          onChildNavToggleState={setHasSidebarChildNavOpen}
          childNavSelector="#main-dashboard"
          onClose={setShowNavigation}
          showed={hasShowedNavigation}
        />
      </MediaQuery>

      {/* desktop/laptop */}
      <MediaQuery minWidth={992}>
        <SearchBar onClose={setShowSearchBar} showed={hasShowedSearchBar} />
        <Header
          mode={mode}
          logoIcon={false}
          headerRef={headerRef}
          className={clsx(styles.header)}
          hideBrand={hasSidebarChildNavOpen}
          langSelector
          appendToNav={
            <>
              <Button
                variant="icon"
                className="mx-3 mi-search lg"
                onClick={() => setShowSearchBar(!hasShowedSearchBar)}
              />
              {notification && (
                <Button
                  variant="icon"
                  className="mr-3 mi-notification lg"
                  onClick={() => {
                    store.addNotification({
                      content: notifProps => (
                        <div
                          {...notifProps}
                          className="notification__item notification__item--success">
                          <div className="notification__icon">
                            <i className="mi-badge-ok"></i>
                          </div>
                          <div className="notification__content">
                            <div
                              className="notification__close"
                              onClick={() => store.removeNotification(notifProps.id)}></div>
                            <div className="notification__title">
                              <strong>Dokumen Siap Diunduh</strong> (Info
                              Peserta_Alexander-12345_020221)
                            </div>
                            <div className="notification__message">
                              Sit egestas elementum dignissim eros, bibendum adipiscing non sapien
                              viverra. Enim elit nulla in rhoncus.
                            </div>
                          </div>
                        </div>
                      ),
                      insert: 'bottom',
                      container: 'bottom-full',
                      dismiss: {
                        duration: 100000,
                        click: false,
                        touch: false,
                      },
                    })
                  }}
                />
              )}
              <Button
                as={Link}
                to={`${(rest.layoutPath: any)}auth`}
                variant="outline-coral"
                size="sm">
                <strong>Keluar</strong>
              </Button>
            </>
          }
          fluid
        />
        <Sidebar
          position="fixed"
          homeLink={`/${mode}/home`}
          navItems={navigations[mode]}
          onChildNavToggleState={setHasSidebarChildNavOpen}
          childNavSelector="#main-dashboard"
        />
      </MediaQuery>
      <Pages {...rest} />
    </Container>
  )
}

Dashboard.defaultProps = ({
  mode: 'employee',
  notification: false,
  routesBlock: () => null,
}: Props)

export default Dashboard
