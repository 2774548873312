// @flow

import * as React from 'react'
import ReactDOM from 'react-dom'
import clsx from 'clsx'
import { Form } from 'components'
import { Button, Card, Col, Fade, Row } from 'react-bootstrap'
import { usePopper } from 'react-popper'
import { useMediaQuery } from 'react-responsive'
import { isArray, isEqual, isNull, isString } from 'lodash'

const INITIAL_STATE = {
  sortBy: null,
  documentTypes: ([]: Array<any>),
  statusses: ([]: Array<any>),
}

export type FilterableDataProps = {
  children: ({
    events: { ... },
    props: { ref: React.Ref<any> },
    countedFuncs: number,
  }) => React$Element<any>,
  initialState: typeof INITIAL_STATE,
  onChanges: (newData: Array<any>) => void,
  data: Array<any>,
  ...
}

const FilterableData = ({
  data: dataProp,
  initialState,
  children,
  onChanges,
  ...restProps
}: FilterableDataProps): React$Element<typeof React.Fragment> => {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
  const [visibility, setVisibility] = React.useState(false)
  const [referenceElement, setReferenceElement] = React.useState(null)
  const [popperElement, setPopperElement] = React.useState(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  })

  const [prevState, setPrevState] = React.useState(initialState)
  const [state, setState] = React.useState(initialState)
  const initialData = React.useRef(dataProp)
  const [data, setData] = React.useState(dataProp)

  const [countedFuncs, setCountedFuncs] = React.useState(0)

  const getColor = name => {
    let color = 'white'
    switch (name) {
      case 'attendee':
        color = 'warning'
        break
      case 'contribution':
        color = 'scarletGum'
        break
      case 'claim':
        color = 'eggBlue'
        break
      default:
        color = 'white'
        break
    }

    return color
  }

  const onClick = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setVisibility(!visibility)
  }

  const handleSortByElemClick = (by: 'date' | 'name') => (e: SyntheticEvent<HTMLElement>) => {
    setState({ ...state, sortBy: state.sortBy !== by ? by : null })
  }

  const handleDocTypesElemClick = (type: string) => (e: SyntheticEvent<HTMLElement>) => {
    const documentTypes = [...state.documentTypes]

    if (documentTypes.includes(type))
      documentTypes.splice(
        documentTypes.findIndex(T => T === type),
        1
      )
    else documentTypes.push(type)

    setState({ ...state, documentTypes })
  }

  const handleStatusElemChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const statusses = [...state.statusses]

    if (statusses.includes(name))
      statusses.splice(
        statusses.findIndex(T => T === name),
        1
      )
    else statusses.push(name)

    setState({ ...state, statusses })
  }

  const findValue = (dataItem: any, target = 'content') => {
    const content = dataItem[target]

    if (!!content) {
      if (typeof content === 'string') {
        return content
      } else if (typeof content === 'object' && !!content.props) {
        const props: any = content.props

        if (isArray(props.children)) {
          if (typeof props.children[0] === 'string') {
            return props.children[0]
          }
        } else if (typeof props.children === 'string') {
          return props.children
        } else return props
      } else {
        return parseFloat(content).toPrecision(6)
      }
    }

    return ''
  }

  React.useEffect(() => {
    let count = state.documentTypes.length + state.statusses.length

    if (isString(state.sortBy) && ['date', 'name'].includes(state.sortBy)) {
      count += 1
    }

    setCountedFuncs(count)

    if (!isEqual(prevState, state)) setPrevState(state)

    return () => {
      setCountedFuncs(0)
    }
  }, [prevState, state])

  React.useEffect(() => {
    let newData = [...data]

    if (!isNull(prevState.sortBy)) {
      newData = newData.sort((a, b) => {
        const valueA: any = findValue(a.find(d => d.name === prevState.sortBy))
        const valueB: any = findValue(b.find(d => d.name === prevState.sortBy))

        if (prevState.sortBy === 'date')
          return window.moment(new Date(valueA)).diff(window.moment(new Date(valueB)))

        return valueA.toUpperCase().localeCompare(valueB.toUpperCase())
      })
    } else {
      newData = [...initialData.current]
    }

    if (prevState.documentTypes.length > 0) {
      newData = newData.filter(item =>
        prevState.documentTypes.includes(
          findValue(
            item.find(d => d.name === 'name'),
            'as'
          )
        )
      )
    }

    if (prevState.statusses.length > 0) {
      newData = newData.filter(item =>
        prevState.statusses.includes((findValue(item.find(d => d.name === 'status')): any).type)
      )
    }

    if (!isEqual(data, newData)) setData(newData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, prevState])

  React.useEffect(() => {
    onChanges(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <>
      {children({ events: { onClick }, props: { ref: setReferenceElement }, countedFuncs })}
      {ReactDOM.createPortal(
        <Fade in={visibility} unmountOnExit>
          <div
            {...attributes.popper}
            ref={setPopperElement}
            style={{ ...styles.popper, width: isMobile ? '92vw' : '22rem', zIndex: 9999 }}>
            <Card className={clsx('shadow-lg')}>
              <Card.Body>
                <Row>
                  <Col xs={12} className={clsx('mb-3')}>
                    <span className="text-sm">Urutkan Berdasarkan</span>
                    <div className="d-flex mt-2">
                      <Button
                        size="sm"
                        className={clsx('mr-1')}
                        variant={`rounded-${state.sortBy === 'date' ? 'primary' : 'white'}`}
                        onClick={handleSortByElemClick('date')}>
                        Tanggal Pengajuan
                      </Button>
                      <Button
                        size="sm"
                        className={clsx('mx-1')}
                        variant={`rounded-${state.sortBy === 'name' ? 'primary' : 'white'}`}
                        onClick={handleSortByElemClick('name')}>
                        Nama
                      </Button>
                    </div>
                  </Col>
                  <Col xs={12} className={clsx('mb-3')}>
                    <span className="text-sm">Berdasarkan Jenis Document</span>
                    <div className="d-flex mt-2">
                      <Button
                        size="sm"
                        className={clsx('mr-1')}
                        onClick={handleDocTypesElemClick('attendee')}
                        variant={`rounded-${
                          state.documentTypes.includes('attendee') ? getColor('attendee') : 'white'
                        }`}>
                        Peserta
                      </Button>
                      <Button
                        size="sm"
                        className={clsx('mx-1')}
                        onClick={handleDocTypesElemClick('contribution')}
                        variant={`rounded-${
                          state.documentTypes.includes('contribution')
                            ? getColor('contribution')
                            : 'white'
                        }`}>
                        Iuran
                      </Button>
                      <Button
                        size="sm"
                        className={clsx('mx-1')}
                        onClick={handleDocTypesElemClick('claim')}
                        variant={`rounded-${
                          state.documentTypes.includes('claim') ? getColor('claim') : 'white'
                        }`}>
                        Klaim
                      </Button>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <span className="text-sm">Berdasarkan Status</span>
                    <div className="mt-3">
                      <Form.Checkbox
                        name="success"
                        checked={state.statusses.includes('success')}
                        label="Siap Diunduh"
                        variant="success"
                        formGroupProps={{ className: 'mb-4' }}
                        onChange={handleStatusElemChange}
                      />
                      <Form.Checkbox
                        name="warning"
                        checked={state.statusses.includes('warning')}
                        label="Dalam Proses"
                        variant="warning"
                        formGroupProps={{ className: 'mb-4' }}
                        onChange={handleStatusElemChange}
                      />
                      <Form.Checkbox
                        name="danger"
                        checked={state.statusses.includes('danger')}
                        label="Gagal"
                        variant="danger"
                        formGroupProps={{ className: 'mb-0' }}
                        onChange={handleStatusElemChange}
                      />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className={clsx('py-2')}>
                <Button
                  size="sm"
                  variant="white"
                  className={clsx('d-block ml-auto py-2 px-3')}
                  onClick={() => setState(initialState)}>
                  Reset Filter
                </Button>
              </Card.Footer>
            </Card>
          </div>
        </Fade>,
        document.querySelector('#__manulifeApp')
      )}
    </>
  )
}

FilterableData.defaultProps = ({
  data: [],
  initialState: INITIAL_STATE,
  onChanges: newData => {
    console.info(newData)
  },
  children: ({ events, props, countedFuncs }) => (
    <Button
      {...events}
      {...props}
      size="sm"
      variant="rounded-white"
      className="d-flex align-items-center white-space-nowrap py-2 px-3 px-md-4 ml-3 ml-md-4">
      <i className={clsx('mi-filter md')}></i>({countedFuncs})
    </Button>
  ),
}: FilterableDataProps)

export default FilterableData
