/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable no-empty-pattern */
// @flow
import clsx from 'clsx'
import * as React from 'react'
import CountUp from 'react-countup'
import { Link } from 'react-router-dom'
import { DataTable, Form, Tabs } from 'components'
import {
  Col,
  Container,
  Row,
  Button,
  Card,
  Tab,
  Modal,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap'

// Styles
import styles from './style.module.scss'
import { useLocaleUtils } from 'lib/shared/hooks'

export type BallanceProps = { ... }

export const Ballance = ({}: BallanceProps): React$Element<'section'> => {
  const { getToday } = useLocaleUtils('en')
  const [show, setShow] = React.useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const transactions = {
    headers: [
      { title: 'Tanggal', name: 'date' },
      { title: 'Keterangan', name: 'description' },
      { title: 'Debet', name: 'debit' },
      { title: 'Kredit', name: 'credit' },
    ],
    data: [
      [
        { content: '31-08-2020', name: 'date' },
        { content: 'Biaya', name: 'description' },
        { content: '5,973.00', name: 'debit' },
        { content: '0.00', name: 'credit' },
      ],
      [
        { content: '25-08-2020', name: 'date' },
        { content: 'Iuran Peserta', name: 'description' },
        { content: '0.00', name: 'debit' },
        { content: '562,200.00', name: 'credit' },
      ],
      [
        { content: '31-07-2020', name: 'date' },
        { content: 'Biaya', name: 'description' },
        { content: '5,411.00', name: 'debit' },
        { content: '0.00', name: 'credit' },
      ],
      [
        { content: '23-08-2020', name: 'date' },
        { content: 'Iuran Peserta', name: 'description' },
        { content: '0.00', name: 'debit' },
        { content: '562,200.00', name: 'credit' },
      ],
    ],
  }

  const shm = {
    headers: [
      {
        title: (
          <span className="h5 mb-0 d-flex align-items-center font-weight-light">
            SHM <i className="mi-info md"></i>
          </span>
        ),
        name: 'title',
      },
      {
        title: null,
        name: 'value',
        attrs: { width: '50%' },
      },
    ],
    data: [
      [
        {
          content: <strong>Saldo Pemberi Kerja</strong>,
          name: 'title',
        },
        {
          content: '0.00',
          name: 'value',
        },
      ],
      [
        {
          content: <strong>Saldo Pekerja</strong>,
          name: 'title',
        },
        {
          content: '15,816,415,15',
          name: 'value',
        },
      ],
      [
        {
          content: <strong>Saldo Tambahan</strong>,
          name: 'title',
        },
        {
          content: '0.00',
          name: 'value',
        },
      ],
      [
        {
          content: <strong>Pengalihan Dana</strong>,
          name: 'title',
        },
        {
          content: '0.00',
          name: 'value',
        },
      ],
    ],
  }

  const syh = {
    headers: [
      {
        title: (
          <span className="h5 mb-0 d-flex align-items-center font-weight-light">
            SYH <i className="mi-info md"></i>
          </span>
        ),
        name: 'title',
      },
      {
        title: null,
        name: 'value',
        attrs: { width: '50%' },
      },
    ],
    data: [
      [
        {
          content: <strong>Saldo Pemberi Kerja</strong>,
          name: 'title',
        },
        {
          content: '0.00',
          name: 'value',
        },
      ],
      [
        {
          content: <strong>Saldo Pekerja</strong>,
          name: 'title',
        },
        {
          content: '500,000.00',
          name: 'value',
        },
      ],
      [
        {
          content: <strong>Saldo Tambahan</strong>,
          name: 'title',
        },
        {
          content: '0.00',
          name: 'value',
        },
      ],
      [
        {
          content: <strong>Pengalihan Dana</strong>,
          name: 'title',
        },
        {
          content: '0.00',
          name: 'value',
        },
      ],
    ],
  }

  const renderInfoPopover = () => (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="top"
      overlay={
        <Popover>
          <Popover.Content>
            Est consectetur sit est enim voluptate ex cupidatat non.
          </Popover.Content>
        </Popover>
      }>
      <i className="mi-info vertical-align-sub cursor-pointer"></i>
    </OverlayTrigger>
  )

  return (
    <section>
      <Container fluid>
        <h1 className="font-weight-light display-2 mb-4 mt-5">
          Informasi <span className="font-serif">Saldo</span>
        </h1>
        <Row className={clsx('pb-5 mb-3')}>
          <Col md={6}>
            <p className="pr-5 font-weight-light">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
              laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
              architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas
              sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
              voluptatem sequi nesciunt.
            </p>
            <Button as={Link} to="/" variant="anchor">
              Selengkapnya
            </Button>
            <div className="hidden-lg hidden-md hidden-sm">&nbsp;</div>
          </Col>
          <Col md={6}>
            <Card body className={clsx('mb-4')}>
              <Row>
                <Col md={12}>
                  <span>Saldo rekening Anda</span>
                  <CountUp
                    start={0}
                    delay={0}
                    duration={1}
                    end={16316415.0}
                    decimal="."
                    decimals={2}
                    formattingFn={val => window.numbro(val).format({ mantissa: 2 })}>
                    {({ countUpRef }) => (
                      <h2 className="h1 mt-1 font-weight-bold" ref={countUpRef} />
                    )}
                  </CountUp>
                  <span className="d-block text-muted text-sm mb-3">
                    Saldo per tanggal 27 Jan 2021
                  </span>
                </Col>
                <Col md={6}>
                  <span className="text-sm">Akumulasi Dana Masuk {renderInfoPopover()}</span>
                  <CountUp
                    start={0}
                    delay={0}
                    duration={1}
                    end={16844600.0}
                    decimal="."
                    decimals={2}
                    formattingFn={val => window.numbro(val).format({ mantissa: 2 })}>
                    {({ countUpRef }) => (
                      <h3 className="h6 font-weight-bold mt-1" ref={countUpRef} />
                    )}
                  </CountUp>
                </Col>
                <Col md={6}>
                  <span className="text-sm">Hasil pengembangan {renderInfoPopover()}</span>
                  <CountUp
                    start={0}
                    delay={0}
                    duration={1}
                    end={-415565.0}
                    decimal="."
                    decimals={2}
                    formattingFn={val => window.numbro(val).format({ mantissa: 2 })}>
                    {({ countUpRef }) => (
                      <h3 className="h6 font-weight-bold mt-1" ref={countUpRef} />
                    )}
                  </CountUp>
                </Col>
                <Col md={6}>
                  <span className="text-sm">Penarikan Dana {renderInfoPopover()}</span>
                  <CountUp
                    start={1.0}
                    delay={0}
                    duration={1}
                    end={0.0}
                    decimal="."
                    decimals={2}
                    formattingFn={val => window.numbro(val).format({ mantissa: 2 })}>
                    {({ countUpRef }) => (
                      <h3 className="h6 font-weight-bold mt-1" ref={countUpRef} />
                    )}
                  </CountUp>
                </Col>
                <Col md={6}>
                  <span className="text-sm">Biaya {renderInfoPopover()}</span>
                  <CountUp
                    start={1.0}
                    delay={0}
                    duration={1}
                    end={-112620.0}
                    decimal="."
                    decimals={2}
                    formattingFn={val => window.numbro(val).format({ mantissa: 2 })}>
                    {({ countUpRef }) => (
                      <h3 className="h6 font-weight-bold mt-1" ref={countUpRef} />
                    )}
                  </CountUp>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Tabs id="ballance" className={clsx(styles.tabs)} responsiveMode="dropdown">
          <Tab eventKey="transaction" title="Rincian Transaksi">
            <Row className={clsx('py-4')}>
              <Col md={6} className={clsx('py-2')}>
                <p className="w-75">
                  Fitur ini membantu Anda untuk mengetahui dan memeriksa seluruh rinciann transaksi
                  yang berkaitan dengan dana pensiun Anda dalam kurun waktu maksimal 6 (enam) bulan
                  sesuai keinginan Anda.
                </p>
              </Col>
              <Col md={6} className={clsx('py-2')}>
                <Row className={clsx('align-items-center')}>
                  <Col md={8}>
                    <Form.DateRangePicker
                      hasGrow
                      fromInputProps={{ label: 'Dari', placeholder: 'Pilih Tanggal' }}
                      toInputProps={{ label: 'Hingga', placeholder: 'Pilih Tanggal' }}
                    />
                    <span className="text-sm mt-3 d-block">Maksimal periode 6 (enam) bulan</span>
                  </Col>
                  <Col className={clsx('d-flex')}>
                    <Button variant="coral" block>
                      Tampilkan
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col md={12} className={clsx('pt-4')}>
                <DataTable className={clsx('mb-0')} {...transactions} />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="investation" title="Rincian Investasi">
            <Row className={clsx('py-4 mt-3')}>
              <Col md={12}>
                <DataTable className={clsx('mb-0')} {...shm} />
              </Col>
              <Col md={12}>
                <DataTable className={clsx('mb-0')} {...syh} />
              </Col>
            </Row>
          </Tab>
        </Tabs>
        <div className={clsx('my-4', 'pt-2 pb-5')}>
          <Card body className="bg-primary text-white">
            <Row className={clsx('px-3', 'py-2')}>
              <Col md={2}>
                <i className="mi-download ultra"></i>
              </Col>
              <Col>
                <h1 className="h3 font-weight-bold mb-4">
                  Dapatkan Laporan Keuangan Pribadi berisi <br /> rincian akumulasi saldo dan
                  transaksi
                </h1>
                <Button variant="white" onClick={handleShow}>
                  <strong>Unduh Sekarang</strong>
                </Button>
              </Col>
            </Row>
          </Card>
        </div>
      </Container>
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title as="h1" className={clsx('h3')}>
            Laporan Keuangan Pribadi
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Laporan Keuangan Pribadi adalah laporan mengenai akumulasi saldo dan transaksi yang
            terjadi pada periode per 6 bulan. Rentang periode maksimum laporan keuangan pribadi yang
            dapat diminta adalah 2 tahun ke belakang.
          </p>
          <Row>
            <Col md={6}>
              <Form.Select
                hasGrow
                label="Periode"
                placeholder="Tentukan Periode"
                menuProps={{ className: clsx('w-100') }}
                items={[
                  {
                    key: 'Jan - Jun',
                    value: 'Januari - Juli',
                  },
                  {
                    key: 'Jul - Dec',
                    value: 'Juli - Desember',
                  },
                ]}
              />
            </Col>
            <Col md={6}>
              <Form.Select
                hasGrow
                label="Tahun"
                // value={getToday().year.toString()}
                placeholder="Pilih Tahun"
                menuProps={{ className: clsx('w-100') }}
                items={Array.from(Array(2).keys())
                  .reverse()
                  .map(i => ({
                    key: getToday().year - 2 + (i + 1),
                    value: getToday().year - 2 + (i + 1),
                  }))}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-coral" onClick={handleClose}>
            Batal
          </Button>
          <Button variant="coral" onClick={handleClose}>
            Minta
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  )
}

export default Ballance
