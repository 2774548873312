// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react'

// Styles
// import styles from './style.module.scss'

// Sub-Components
import Mobile from './Mobile'

type NavItem = {
  to: string,
  label: string,
  icon?: string,
  submenu?: Array<NavItem>,
}

type Props = { ... }

// eslint-disable-next-line no-empty-pattern
const Navigation = ({}: Props): React$Element<'nav'> => {
  return <nav></nav>
}

Navigation.Mobile = Mobile

Navigation.defaultProps = ({
  //
}: Props)

export default Navigation
