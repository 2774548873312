// @flow
import * as React from 'react'
import clsx from 'clsx'
import { has, isEmpty } from 'lodash'
import Form, { InputGroup } from '..'
import NumberFormat from 'react-number-format'

export type InputProps = {
  append?: React$Node | string,
  appendCursorHelp?: boolean,
  className?: string | Array<any> | Object,
  feedbackMessage?: string,
  formGroupProps?: any,
  hasGrow?: boolean,
  hasValidation?: boolean,
  inputGroupProps?: any,
  label?: React$Node | string,
  name?: string,
  onChange?: (event: SyntheticEvent<HTMLInputElement>) => void,
  isAllowed?: (values: any) => boolean,
  password?: boolean,
  percentage?: boolean,
  placeholder?: string,
  prepend?: React$Node | string,
  type?: 'text' | 'password' | 'number' | 'textarea' | 'email',
  underlined?: boolean | 'dashed' | 'solid',
  value?: string | number,
  ...
}

export const Input: React.AbstractComponent<InputProps, mixed> = React.forwardRef(
  (
    {
      append,
      appendCursorHelp,
      className,
      feedbackMessage,
      formGroupProps,
      hasGrow,
      hasValidation,
      inputGroupProps,
      isAllowed,
      label: Label,
      name,
      onChange,
      password,
      percentage,
      prepend,
      type,
      underlined,
      value: valueProp,
      ...restProps
    },
    ref
  ) => {
    const [hasVisible, setHasVisible] = React.useState<boolean>(false)
    const [value, setValue] = React.useState<string | number | null | typeof undefined>(
      !valueProp && percentage ? 0 : valueProp
    )

    React.useEffect(() => {
      typeof valueProp !== 'undefined' && valueProp !== value && setValue(valueProp)
    }, [valueProp, value])

    const handleOnChange = (e: SyntheticEvent<HTMLInputElement>): void => {
      e.preventDefault()
      setValue(e.currentTarget.value)
      typeof onChange === 'function' && onChange(e)
    }

    const inputType = password ? (hasVisible ? 'text' : 'password') : type

    const renderInput = props =>
      percentage ? (
        <>
          <NumberFormat
            {...props}
            value={value}
            customInput={Form.Control}
            decimalScale={0}
            onChange={handleOnChange}
            fixedDecimalScale
            isNumericString
            isAllowed={
              typeof isAllowed === 'function'
                ? isAllowed
                : val => {
                    const { value } = val
                    if (value <= 100) return val
                  }
            }
          />
        </>
      ) : (
        <Form.Control {...props} defaultValue={value} onChange={handleOnChange} type={inputType} />
      )

    return (
      <Form.Group
        {...formGroupProps}
        className={clsx(
          percentage && 'has-percentage',
          hasValidation && 'has-validation',
          has(formGroupProps, 'className') && (formGroupProps: Object).className
        )}>
        <InputGroup {...inputGroupProps} hasValidation={hasValidation}>
          {!!prepend && (
            <InputGroup.Prepend>
              <InputGroup.Text className={clsx('icon')}>{prepend}</InputGroup.Text>
            </InputGroup.Prepend>
          )}
          {renderInput({
            ...restProps,
            ref,
            name,
            className: clsx(
              !isEmpty(value) || hasGrow ? 'has-grow' : '',
              !!underlined && 'underlined',
              !!underlined &&
                (typeof underlined === 'string'
                  ? `underlined--${underlined}`
                  : `underlined--solid`),
              className
            ),
          })}
          {!!Label &&
            (typeof Label === 'string' ? (
              <Form.Label htmlFor={name}>{Label}</Form.Label>
            ) : (
              (Label: React$Node)
            ))}
          {percentage ? (
            <InputGroup.Append>
              <InputGroup.Text className="icon">%</InputGroup.Text>
            </InputGroup.Append>
          ) : password ? (
            <InputGroup.Append>
              <InputGroup.Text className="icon" onClick={() => setHasVisible(!hasVisible)}>
                <i className={clsx(hasVisible ? 'mi-eye-close' : 'mi-eye-open', 'px-1')}></i>
              </InputGroup.Text>
            </InputGroup.Append>
          ) : (
            !!append && (
              <InputGroup.Append>
                <InputGroup.Text className={clsx('icon', appendCursorHelp && 'icon--as-help')}>
                  {append}
                </InputGroup.Text>
              </InputGroup.Append>
            )
          )}
          {hasValidation && !isEmpty(value) && !!feedbackMessage && (
            <Form.Control.Feedback type="valid">{feedbackMessage}</Form.Control.Feedback>
          )}
          {hasValidation && !!feedbackMessage && (
            <Form.Control.Feedback type="invalid">{feedbackMessage}</Form.Control.Feedback>
          )}
        </InputGroup>
      </Form.Group>
    )
  }
)
;(Input: typeof Form.Group).defaultProps = ({
  type: 'text',
  password: false,
  underlined: 'solid',
  appendCursorHelp: false,
}: InputProps)

export default Input
