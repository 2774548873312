/* eslint-disable no-lone-blocks */
// @flow
import * as React from 'react'
import { isEmpty, isFunction, keys } from 'lodash'
import { Switch, Route } from 'react-router-dom'

// Routes
import routesList from './routes'
import type { Route as RouteType, Routes } from './routes'

const RouteWithSubroutes = ({ path, routes, layoutPath, ...rest }): React$Element<typeof Route> => {
  return (
    <Route
      path={path}
      render={props => (
        <rest.component
          {...props}
          routes={routes}
          rootPath={path}
          layoutPath={layoutPath}
          routesBlock={RoutesBlock}
          parentPath={path
            .split('/')
            .slice(0, path.split('/').length - 1)
            .join('/')}
        />
      )}
    />
  )
}

const RoutesBlock = ({ routes, rootPath, routesBlock, layoutPath, renderRoute }) => (
  <Switch>
    {keys((routes: Routes)).map((key: string) => {
      const route: RouteType = routes[key]
      const routesBlockComponent = (routesBlock: React$Element<typeof RoutesBlock>)
      if (typeof route.component === 'undefined') {
        route.component = routesBlockComponent
      }

      const path = `${rootPath}${key}`.replace(/(\/\/)/gi, '/')

      return !!renderRoute && isFunction(renderRoute) ? (
        renderRoute(RouteWithSubroutes, route, path)
      ) : (
        <RouteWithSubroutes
          key={key}
          path={path}
          layoutPath={!!route.asLayout ? path : !isEmpty(layoutPath) ? layoutPath : ''}
          {...(route: any)}
        />
      )
    })}
  </Switch>
)

const RouterView = ({ routes, ...rest }: any): React$Element<typeof Switch> => (
  <RoutesBlock routes={routes} rootPath="" routesBlock={RoutesBlock} renderRoute={null} />
)

RouterView.defaultProps = {
  routes: routesList,
}

export default RouterView
