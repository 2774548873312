/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import styles from './style.module.scss'
import { Form } from 'components'
import { isEqual } from 'lodash-es'
import { Col, Row } from 'react-bootstrap'

const schema = yup.object().shape({
  otpCode: yup.number().positive().max(6).required(),
})

export const OTP = ({ type, onSubmit }) => {
  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  })

  return (
    <Row as={Form} className="pb-5 mb-5" onSubmit={handleSubmit(console.log)}>
      <Col xl={6} md={6}>
        <h2 className="font-weight-light mb-5 mt-2 h3">
          Silahkan masukkan kode verifikasi (OTP) yang Anda terima{' '}
          <Form.Input
            type="number"
            name="otp_code"
            underlined="dashed"
            placeholder="123456"
            ref={register('otpCode')}
            formGroupProps={{ className: clsx(styles.otpInput) }}
            onChange={({ currentTarget: { value } }) => isEqual(value, '123456') && onSubmit()}
            onKeyDown={e =>
              ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) &&
              e.currentTarget.value.length >= 6 &&
              e.preventDefault()
            }
          />
        </h2>
      </Col>
      {/* <Col xs={12}>
        <h3 className={clsx('h5', 'font-weight-light', 'my-4')}>
          Kami telah mengirimkan {type === 'sms' ? 'SMS' : type} berisi 6 digit <br />
          kode verifikasi (OTP) ke <strong>{userInfo}</strong>
        </h3>
      </Col> */}
      <Col xs={12}>
        <ul className={clsx('union-list', 'font-weight-light')}>
          <li>
            Kode OTP akan kadaluarsa dalam <strong>00:59</strong>
          </li>
          <li>
            Tidak menerima kode OTP?{' '}
            <a href="#" className={clsx('decoration-underlined')}>
              <strong>Kirim Ulang</strong>
            </a>
          </li>
          <li>
            Kirim ke nomor lain?{' '}
            <a href="#" className={clsx('decoration-underlined')}>
              <strong>Ubah Data</strong>
            </a>
          </li>
        </ul>
      </Col>
    </Row>
  )
}

OTP.defaultProps = {
  type: 'email',
  onSubmit: () => {},
}

export default OTP
