// @flow
import * as React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { Card, Col, Row } from 'react-bootstrap'
import { Alert } from 'components'

import styles from './style.module.scss'

export const Verification = ({ parentPath, title, alertShown }: any): React$Element<'section'> => {
  return (
    <section className={clsx('container', 'py-4', styles.container)}>
      <h1 className="display-1 mb-5">{title}</h1>
      <Alert initialShow={alertShown} closable className="mb-4 mr-lg-5">
        <p className="mb-0">
          Email Anda belum terdaftar di sistem kami. Segera lengkapi data diri Anda atau hubungi{' '}
          <b>Customer Service Manulife 021 2555 7777</b>
        </p>
      </Alert>
      <h2 className={clsx(styles.subheading, 'text-darkNavy', 'mb-5')}>
        Pilih salah satu metode dibawah ini untuk mendapatkan kode verifikasi
      </h2>
      <Row className="mb-auto w-100">
        <Col sm={12} md={6} xl={5}>
          <Row>
            <Col xs={6}>
              <Card body as={Link} className={styles.cardLink} to={`${parentPath}/email`}>
                <i className="mi-envelope xxl d-block"></i>
                <span className="d-block">Email</span>
              </Card>
            </Col>
            <Col xs={6}>
              <Card body as={Link} className={styles.cardLink} to={`${parentPath}/sms`}>
                <i className="mi-phone xxl d-block"></i>
                <span className="d-block">SMS</span>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  )
}

Verification.defaultProps = {
  title: 'Title',
}

export default Verification
