/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable no-empty-pattern */
// @flow
import clsx from 'clsx'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { Billboard, DataTable, Accordion, StatusText, Tabs } from 'components'
import { Col, Container, Row, Button, Tab, OverlayTrigger, Popover, Modal } from 'react-bootstrap'

// Styles
import styles from './style.module.scss'

export type ClaimProps = { ... }

export const Claim = ({}: ClaimProps): React$Element<'section'> => {
  const [claimDetailModalShown, setClaimDetailModalShown] = React.useState(false)
  const claimsList = {
    headers: [
      { title: 'Nama Transaksi', name: 'name' },
      { title: 'Estimasi Selesai', name: 'estimation' },
      { title: 'Status', name: 'status' },
      { title: 'Keterangan', name: 'description' },
    ],
    data: [
      [
        { content: 1, name: 'id' },
        { content: 'Manfaat Cacat Tetap', name: 'name' },
        { content: '24/11/20', name: 'estimation' },
        {
          content: <StatusText type="danger" label="Tidak Lengkap" />,
          name: 'status',
        },
        {
          content: (
            <span className="d-flex">
              Formulir Klaim tidak lengkap
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={
                  <Popover>
                    <Popover.Content>Formulir Klaim tidak lengkap</Popover.Content>
                  </Popover>
                }>
                <i className="mi-alert lg ml-2 cursor-pointer"></i>
              </OverlayTrigger>
            </span>
          ),
          name: 'description',
        },
      ],
      [
        { content: 2, name: 'id' },
        { content: 'Manfaat Kecelakaan', name: 'name' },
        { content: '24/11/20', name: 'estimation' },
        {
          content: <StatusText type="warning" label="Dalam proses" />,
          name: 'status',
        },
        {
          content: null,
          name: 'description',
        },
      ],
    ],
  }

  const claimsHistories = {
    headers: [
      { title: 'Nama Transaksi', name: 'name' },
      { title: 'Waktu Selesai', name: 'end_date' },
      {
        title: (
          <span className="d-flex">
            Bukti Potong Pajak
            <OverlayTrigger
              trigger={['hover', 'focus']}
              placement="top"
              overlay={
                <Popover>
                  <Popover.Content>
                    Irure deserunt duis qui cillum mollit proident sunt nulla.
                  </Popover.Content>
                </Popover>
              }>
              <i className="mi-info lg ml-2 cursor-pointer"></i>
            </OverlayTrigger>
          </span>
        ),
        name: 'proof',
      },
    ],
    data: [
      [
        { content: 1, name: 'id' },
        { content: 'Manfaat Rawat Inap', name: 'name' },
        { content: '24/11/20', name: 'end_date' },
        {
          content: (
            <StatusText
              type="success"
              label="Siap diunduh"
              append={<Button variant="icon" className={clsx('mi-download lg')} />}
            />
          ),
          name: 'proof',
        },
      ],
      [
        { content: 2, name: 'id' },
        { content: 'Manfaat Rawat Jalan', name: 'name' },
        { content: '24/11/20', name: 'end_date' },
        {
          content: (
            <StatusText
              type="success"
              label="Siap diunduh"
              append={<Button variant="icon" className={clsx('mi-download lg')} />}
            />
          ),
          name: 'proof',
        },
      ],
    ],
  }

  const claimsListFAQs = [
    {
      name: 'claim_process',
      title: 'Berapa lama waktu proses klaim?',
      content: (
        <p>
          In labore ut cupidatat mollit pariatur eu. Sit consequat magna pariatur sunt minim sint
          fugiat sint deserunt aliqua ex sit minim. Eiusmod proident nostrud occaecat deserunt ea
          nisi amet excepteur velit. Commodo aute labore commodo ullamco in incididunt ut ex. Esse
          sunt ipsum enim et pariatur qui eu irure aliquip mollit ex excepteur do.
        </p>
      ),
    },
    {
      name: 'completion_documents',
      title: 'Bagaimana cara melengkapi dokumen tidak lengkap?',
      content: (
        <p>
          Incididunt eu id proident nostrud sunt adipisicing sunt. Laboris aute Lorem aliqua
          voluptate non ipsum nisi est. Et voluptate ex sit laborum.
        </p>
      ),
    },
    {
      name: 'daring_upload',
      title: 'Bisakah mengunggah dokumen secara daring?',
      content: (
        <p>
          Eiusmod tempor cupidatat voluptate est quis. Et nostrud velit tempor culpa velit in ad ex
          ut aliqua. Cupidatat occaecat ipsum Lorem voluptate. Eu excepteur incididunt tempor
          incididunt enim. Consequat nisi sint sunt pariatur duis ex sit mollit nulla quis. In
          fugiat adipisicing magna incididunt.
        </p>
      ),
    },
  ]

  const claimsHistoryFAQs = [
    {
      name: 'proof_of_tax',
      title: 'Apa itu Bukti Potong Pajak?',
      content: (
        <p>
          In labore ut cupidatat mollit pariatur eu. Sit consequat magna pariatur sunt minim sint
          fugiat sint deserunt aliqua ex sit minim. Eiusmod proident nostrud occaecat deserunt ea
          nisi amet excepteur velit. Commodo aute labore commodo ullamco in incididunt ut ex. Esse
          sunt ipsum enim et pariatur qui eu irure aliquip mollit ex excepteur do.
        </p>
      ),
    },
    {
      name: 'mechanism_of_tax_charge',
      title: 'Bagaimana mekanisme pemotongan pajak DPLK?',
      content: (
        <p>
          Incididunt eu id proident nostrud sunt adipisicing sunt. Laboris aute Lorem aliqua
          voluptate non ipsum nisi est. Et voluptate ex sit laborum.
        </p>
      ),
    },
    {
      name: 'how_to_see_claims',
      title: 'Bagaimana cara melihat klaim?',
      content: (
        <p>
          Eiusmod tempor cupidatat voluptate est quis. Et nostrud velit tempor culpa velit in ad ex
          ut aliqua. Cupidatat occaecat ipsum Lorem voluptate. Eu excepteur incididunt tempor
          incididunt enim. Consequat nisi sint sunt pariatur duis ex sit mollit nulla quis. In
          fugiat adipisicing magna incididunt.
        </p>
      ),
    },
  ]

  const claimDetails = {
    headers: [
      { title: 'Nama', name: 'name' },
      { title: 'Nominal', name: 'amount' },
    ],
    data: [
      [
        { content: 'Jumlah Penarikan Dana', name: 'name' },
        { content: '1,775,000.00', name: 'amount' },
      ],
      [
        { content: 'Pajak', name: 'name' },
        { content: '88,750.00', name: 'amount' },
      ],
      [
        { content: 'Biaya Administrasi Penarikan Dana/Pengalihan Dana', name: 'name' },
        { content: '0.00', name: 'amount' },
      ],
      [
        { content: 'Biaya Penarikan Dana/Pengalihan Dana', name: 'name' },
        { content: '0.00', name: 'amount' },
      ],
      [
        { content: 'Biaya Administrasi', name: 'name' },
        { content: '0.00', name: 'amount' },
      ],
      [
        { content: 'Biaya Investasi', name: 'name' },
        { content: '0.00', name: 'amount' },
      ],
      [
        { content: 'Biaya Lain-lain', name: 'name' },
        { content: '0.00', name: 'amount' },
      ],
      [
        { content: 'Lumpsum', name: 'name' },
        { content: '1,686,250.00', name: 'amount' },
      ],
      [
        { content: 'Anuitas', name: 'name' },
        { content: '0.00', name: 'amount' },
      ],
      [
        { content: <strong>Jumlah Dana Setelah Pajak dan Biaya</strong>, name: 'name' },
        { content: <strong>1,686,250.00</strong>, name: 'amount' },
      ],
    ],
  }

  const handleClaimDetailToggle = () => setClaimDetailModalShown(!claimDetailModalShown)
  const handleClaimDetailClose = () => setClaimDetailModalShown(false)

  return (
    <section>
      <Container fluid>
        <h1 className="font-weight-light display-2 mb-4 mt-5">
          Informasi <span className="font-serif">Klaim</span>
        </h1>
        <Row className={clsx('pb-5 mb-3')}>
          <Col md={6}>
            <p className="pr-5 mr-5 font-weight-light">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
              laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
              architecto beatae vitae.
            </p>
          </Col>
          <Col as={Row} md={6}>
            <Col xs={4} sm={2} className="d-flex justify-content-center">
              <i className="mi-form-completed xxl2"></i>
            </Col>
            <Col>
              <h2 className="h4 mt-1">Tanya Jawab Pengajuan Klaim</h2>
              <p>Tanya jawab pengajuan klaim DPLK Manulife</p>
              <Button as={Link} to="/" variant="outline-anchor">
                Selengkapnya
              </Button>
            </Col>
          </Col>
        </Row>
        <Tabs id="claim" className={clsx(styles.tabs)} responsiveMode="dropdown">
          <Tab eventKey="claimsList" title="Daftar Klaim">
            <Row className="py-4">
              <Col md={12} className={clsx('py-4')}>
                <DataTable
                  {...claimsList}
                  checkable
                  dataActions={{
                    menu: [
                      {
                        name: 'details',
                        content: (
                          <span>
                            <i className="mi-info lg vertical-align-sub"></i> Lihat Rincian
                          </span>
                        ),
                      },
                    ],
                    handler: {
                      onFireEvent: name => {
                        if (name === 'details') handleClaimDetailToggle()
                      },
                    },
                  }}
                />
              </Col>
              <Col md={12}>
                <h1 className="mt-5">FAQ Terkait</h1>
                <Accordion.Rowed itemsPerRow={claimsListFAQs.length} items={claimsListFAQs} />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="claimsHistory" title="Riwayat Klaim">
            <Row className="py-4">
              <Col md={12} className={clsx('py-4')}>
                <DataTable
                  {...claimsHistories}
                  checkable
                  dataActions={{
                    menu: [
                      {
                        name: 'details',
                        content: (
                          <span>
                            <i className="mi-info lg vertical-align-sub"></i> Lihat Rincian
                          </span>
                        ),
                      },
                    ],
                    handler: {
                      onFireEvent: name => {
                        if (name === 'details') handleClaimDetailToggle()
                      },
                    },
                  }}
                />
              </Col>
              <Col md={12}>
                <h1 className="mt-5">FAQ Terkait</h1>
                <Accordion.Rowed itemsPerRow={claimsHistoryFAQs.length} items={claimsHistoryFAQs} />
              </Col>
            </Row>
          </Tab>
        </Tabs>
        <div className={clsx('my-4', 'pt-2 pb-3')}>
          <Billboard.Contact />
        </div>
      </Container>
      <Modal show={claimDetailModalShown} onHide={handleClaimDetailClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title as="h1" className={clsx('h2')}>
            Rincian Klaim
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6} className={clsx('py-2')}>
              <span className="d-block font-weight-light mb-1">Nama Transaksi</span>
              <h3 className="h6 font-weight-bold">Manfaat Rawat Jalan</h3>
            </Col>
            <Col md={6} className={clsx('py-2')}>
              <span className="d-block font-weight-light mb-1">Status</span>
              <StatusText
                label={<h3 className="h6 font-weight-bold mb-0">Selesai</h3>}
                type="success"
              />
            </Col>
            <Col md={6} className={clsx('py-2')}>
              <span className="d-block font-weight-light mb-1">Waktu Pengajuan</span>
              <h3 className="h6 font-weight-bold">20/11/20</h3>
            </Col>
            <Col md={6} className={clsx('py-2')}>
              <span className="d-block font-weight-light mb-1">Waktu Selesai</span>
              <h3 className="h6 font-weight-bold">24/11/20</h3>
            </Col>
            <Col md={6} className={clsx('py-2')}>
              <span className="d-block font-weight-light mb-1">Bukti Potong Pajak</span>
              <a href="#" className="h6 font-weight-bold">
                <i className="mi-download lg vertical-align-sub"></i> Unduh Sekarang
              </a>
            </Col>
            <Col md={12} className={clsx('py-4')}>
              <DataTable {...claimDetails} />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </section>
  )
}

export default Claim
