// @flow
import * as React from 'react'
import clsx from 'clsx'
import DummyDPLKCard from 'assets/images/dummy-dplk-card.png'
import { Button, Col, Container, Image, Jumbotron, Row } from 'react-bootstrap'
import { useLocation } from 'react-router'

// Styles
import styles from './style.module.scss'

const ECard = (): React$Element<'section'> => {
  const query = new URLSearchParams(useLocation().search)

  return (
    <Container as="section" className={clsx(styles.wrapper)} fluid>
      <Row className="justify-content-center align-self-center text-center">
        <Col xl={5} md={6}>
          {!query.has('blank') ? (
            <>
              <Image src={DummyDPLKCard} className={clsx(styles.ecardImage)} fluid />
              <Button variant="coral" className="mt-4">
                Unduh
              </Button>
            </>
          ) : (
            <Jumbotron className={clsx(styles.ecardPlaceholder)}>
              <i className={clsx('mi mi-alert', styles.ecardPlaceholder__icon)}></i>
              <p className="mb-0">
                Kartu belum tersedia karena formulir belum lengkap dan iuran belum diterima
              </p>
            </Jumbotron>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default ECard
