// @flow
import * as React from 'react'
import clsx from 'clsx'
import { Accordion, Tabs } from 'components'
import { Tab } from 'react-bootstrap'

import styles from './style.module.scss'

const AboutEBClick = (): React$Element<typeof React.Fragment> => {
  const guides = [
    {
      name: 'online_register',
      title: 'Pendaftaran Online di EBClick',
      content: (
        <p>
          In labore ut cupidatat mollit pariatur eu. Sit consequat magna pariatur sunt minim sint
          fugiat sint deserunt aliqua ex sit minim. Eiusmod proident nostrud occaecat deserunt ea
          nisi amet excepteur velit. Commodo aute labore commodo ullamco in incididunt ut ex. Esse
          sunt ipsum enim et pariatur qui eu irure aliquip mollit ex excepteur do.
        </p>
      ),
    },
    {
      name: 'account_activation',
      title: 'Mengaktifkan Akun EBClick',
      content: (
        <p>
          Incididunt eu id proident nostrud sunt adipisicing sunt. Laboris aute Lorem aliqua
          voluptate non ipsum nisi est. Et voluptate ex sit laborum.
        </p>
      ),
    },
    {
      name: 'login_guide',
      title: 'Cara Login di EBClick',
      content: (
        <p>
          Eiusmod tempor cupidatat voluptate est quis. Et nostrud velit tempor culpa velit in ad ex
          ut aliqua. Cupidatat occaecat ipsum Lorem voluptate. Eu excepteur incididunt tempor
          incididunt enim. Consequat nisi sint sunt pariatur duis ex sit mollit nulla quis. In
          fugiat adipisicing magna incididunt.
        </p>
      ),
    },
    {
      name: 'forgot_password',
      title: 'Lupa Kata Sandi',
      content: (
        <p>
          Cillum eiusmod deserunt occaecat nulla. Mollit quis fugiat id occaecat exercitation quis
          aliquip quis voluptate. Est sunt enim cillum commodo dolor aliquip sit ea nulla dolore
          consequat reprehenderit occaecat. Nisi laboris incididunt consequat deserunt laboris Lorem
          aliqua.
        </p>
      ),
    },
    {
      name: 'change_password',
      title: 'Mengubah Kata Sandi',
      content: (
        <p>
          Incididunt sit reprehenderit id ea occaecat non consectetur eiusmod et. Labore aliquip
          occaecat irure mollit Lorem deserunt sint do do magna mollit enim. Veniam dolor elit
          pariatur excepteur do irure ipsum commodo enim officia magna aliqua est.
        </p>
      ),
    },
  ]

  return (
    <>
      <p>
        Enim sagittis risus, diam faucibus a tristique justo, vitae, laoreet. Dui eu, urna <br />{' '}
        tellus vitae felis mauris. Molestie purus purus turpis urna ut at sit quam.
      </p>
      <Accordion.Rowed cols={2} itemsPerRow={3} items={guides} />
    </>
  )
}

export const EBClickGuide = ({ layoutPath }: any): React$Element<'section'> => {
  const tabs = [
    {
      name: 'about',
      title: 'Tentang EBClick',
      content: <AboutEBClick />,
    },
    {
      name: 'feature',
      title: 'Fitur EBClick',
      content: (
        <p>
          Magna nostrud adipisicing reprehenderit cupidatat occaecat dolor do aliquip sit. Mollit
          elit deserunt sit exercitation culpa et. Consequat sunt aute proident Lorem anim
          adipisicing consequat id mollit. Do aute aliqua ad nisi deserunt duis dolor ex pariatur
          culpa ipsum. Tempor ullamco non elit pariatur et mollit. Cupidatat est adipisicing culpa
          occaecat officia irure.
        </p>
      ),
    },
    {
      name: 'faq',
      title: 'Pertanyaan (FAQ)',
      content: (
        <p>
          Consectetur ea ex ad labore quis occaecat veniam magna magna quis. Et ullamco aute tempor
          quis qui aute amet commodo magna. Ea anim dolor voluptate aliquip.
        </p>
      ),
    },
    {
      name: 'form',
      title: 'Form/Download',
      content: (
        <p>
          Et fugiat ex veniam incididunt officia cillum Lorem nostrud occaecat consectetur duis.
          Eiusmod ipsum non reprehenderit eu ea. Esse qui sint eu pariatur consectetur ex enim
          reprehenderit amet ad irure excepteur velit. In in do Lorem quis ad cupidatat eiusmod est
          tempor commodo dolor. Consequat commodo laboris ut Lorem ut labore consequat eiusmod.
          Proident quis cillum ea reprehenderit.
        </p>
      ),
    },
  ]

  return (
    <section className={clsx('container', 'py-4', styles.container)}>
      <h1 className="display-1">Panduan EBClick</h1>
      <Tabs id="guides" className={clsx('mt-3', styles.tabs)} responsiveMode="dropdown">
        {tabs.map(({ content, title, name }) => (
          <Tab key={name} eventKey={name} title={title}>
            <div className={clsx('my-4', 'font-weight-light')}>{content}</div>
          </Tab>
        ))}
      </Tabs>
    </section>
  )
}

export default EBClickGuide
