// @flow
import * as React from 'react'
import ReactDOM from 'react-dom'
import { Calendar } from 'components'
import { Col, Fade, Row } from 'react-bootstrap'
import { has, isEqual, isFunction, isNull } from 'lodash-es'
import { useLocaleUtils } from 'lib/shared/hooks'
import { usePopper } from 'react-popper'
import clsx from 'clsx'
import Form from '..'

import type { InputProps } from '..'

export type DateRangePickerProps = {
  hasGrow: boolean,
  value?: any,
  colProps?: any,
  calendarProps?: any,
  onChange?: (event: any) => void,
  selectorStartingYear?: number,
  selectorEndingYear?: number,
  fromInputProps?: InputProps,
  toInputProps?: InputProps,
  ...
}

export const DateRangePicker: React.AbstractComponent<
  DateRangePickerProps,
  mixed
> = React.forwardRef(
  (
    {
      hasGrow,
      colProps,
      calendarProps,
      fromInputProps,
      selectorEndingYear,
      selectorStartingYear,
      toInputProps,
      value: valueProp,
      ...restProps
    },
    ref
  ) => {
    const { getToday } = useLocaleUtils(calendarProps?.locale || 'en')
    const startingYear = selectorStartingYear || 2000
    const endingYear = selectorEndingYear || getToday().year

    const [calendarState, setCalendarState] = React.useState({
      activeDate: {
        year: getToday().year,
        month: getToday().month,
        day: getToday().day,
      },
    })

    const [nextCalendarState, setNextCalendarState] = React.useState({
      ...calendarState,
      activeDate: {
        year: calendarState.activeDate.year,
        month: calendarState.activeDate.month + 1,
        day: 1,
      },
    })

    const [value, setValue] = React.useState<any>(
      valueProp || {
        from: null,
        to: null,
      }
    )

    const [parsedValue, setParsedValue] = React.useState<any>({ from: null, to: null })

    const [calendarVisibility, setCalendarVisiblity] = React.useState(false)

    const [inputElement, setInputElement] = React.useState(null)
    const [referenceElement, setReferenceElement] = React.useState(null)
    const [popperElement, setPopperElement] = React.useState(null)
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
    })

    const handleCloseCalendar = (e?: any): void => {
      setCalendarVisiblity(false)
    }

    const handleCalendarChange = newValue => {
      setValue(newValue)
      if (!!newValue.from && !!newValue.to) {
        handleCloseCalendar()
        !!restProps.onChange && isFunction(restProps.onChange) && restProps.onChange(newValue)
      }
    }

    React.useLayoutEffect(() => {
      const eventHandler = (event: any) => {
        if (referenceElement && !referenceElement.contains(event.target)) {
          if (isNull(popperElement)) handleCloseCalendar(event)
          else if (popperElement && !popperElement.contains(event.target))
            handleCloseCalendar(event)
        }
      }

      document.addEventListener('click', eventHandler)

      return () => {
        document.removeEventListener('click', eventHandler)
      }
    }, [inputElement, popperElement, referenceElement])

    React.useEffect(() => {
      const toNativeDate = date => new Date(date.year, date.month - 1, date.day)

      setParsedValue({
        from: !!value.from ? window.moment(toNativeDate(value.from)).format() : '',
        to: !!value.to ? window.moment(toNativeDate(value.to)).format() : '',
      })
    }, [nextCalendarState, value.from, value.to])

    React.useEffect(() => {
      if (!!calendarState.activeDate) {
        if (calendarState.activeDate.year > nextCalendarState.activeDate.year) {
          setNextCalendarState({
            ...nextCalendarState,
            activeDate: {
              ...nextCalendarState.activeDate,
              year: calendarState.activeDate.year,
            },
          })
        } else if (calendarState.activeDate.month > nextCalendarState.activeDate.month) {
          setNextCalendarState({
            ...nextCalendarState,
            activeDate: {
              ...nextCalendarState.activeDate,
              month: calendarState.activeDate.month + 1,
              day: 1,
            },
          })
        }
      }
    }, [nextCalendarState, calendarState.activeDate])

    React.useEffect(() => {
      if (
        (has(valueProp, 'to') && valueProp.to !== value.to) ||
        (has(valueProp, 'from') && valueProp.from !== value.from)
      )
        setValue(valueProp)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueProp])

    return (
      <Row ref={setReferenceElement}>
        <Col {...colProps}>
          <Form.Input
            {...fromInputProps}
            append={
              <i className="mi-calendar" onClick={() => !!inputElement && inputElement.focus()}></i>
            }
            hasGrow={hasGrow}
            placeholder={
              has(fromInputProps, 'placeholder') ? fromInputProps?.placeholder : 'Pick a Date'
            }
            formGroupProps={{
              ...fromInputProps?.formGroupProps,
              className: clsx('mb-0', fromInputProps?.formGroupProps?.className),
            }}
            readOnly
            value={parsedValue.from || ''}
            ref={setInputElement}
            onFocus={() => setCalendarVisiblity(true)}
          />
        </Col>
        <Col {...colProps}>
          <Form.Input
            {...toInputProps}
            append={
              <i className="mi-calendar" onClick={() => !!inputElement && inputElement.focus()}></i>
            }
            hasGrow={hasGrow}
            placeholder={
              has(toInputProps, 'placeholder') ? toInputProps?.placeholder : 'Pick a Date'
            }
            formGroupProps={{
              ...toInputProps?.formGroupProps,
              className: clsx('mb-0', toInputProps?.formGroupProps?.className),
            }}
            readOnly
            value={parsedValue.to || ''}
            ref={setInputElement}
            onFocus={() => setCalendarVisiblity(true)}
          />
        </Col>
        {ReactDOM.createPortal(
          <Fade in={calendarVisibility} unmountOnExit>
            <div
              ref={setPopperElement}
              style={{ ...styles.popper, display: 'flex', zIndex: 9999 }}
              {...attributes.popper}>
              <Calendar
                {...calendarProps}
                value={value}
                onChange={handleCalendarChange}
                calendarClassName={clsx('shadow-none', 'border')}
                state={calendarState}
                selectorStartingYear={startingYear}
                selectorEndingYear={endingYear}
                onStateChange={state =>
                  !isEqual(state, calendarState) &&
                  setCalendarState({
                    ...calendarState,
                    ...state,
                    activeDate: { ...calendarState.activeDate, ...state.activeDate },
                  })
                }
              />
              <Calendar
                {...calendarProps}
                value={value}
                onChange={handleCalendarChange}
                calendarClassName={clsx('shadow-none', 'border')}
                state={nextCalendarState}
                onStateChange={state =>
                  !isEqual(state, nextCalendarState) &&
                  setNextCalendarState({
                    ...nextCalendarState,
                    ...state,
                    activeDate: { ...nextCalendarState.activeDate, ...state.activeDate },
                  })
                }
                selectorStartingYear={calendarState.activeDate.year}
                selectorEndingYear={getToday().year}
                minimumDate={
                  !!value.from
                    ? { year: calendarState.activeDate.year, month: value.from.month + 1, day: 1 }
                    : {}
                }
                maximumDate={{
                  year: getToday().year,
                  month: 12,
                  day: window.moment().year(getToday().year).month(11).endOf('month').date(),
                }}
              />
            </div>
          </Fade>,
          document.querySelector('#__manulifeApp')
        )}
      </Row>
    )
  }
)
;(DateRangePicker: typeof Form.Input).defaultProps = ({
  hasGrow: false,
  onChange: (value: any) => {},
  colProps: { sm: 6 },
}: DateRangePickerProps)

export default DateRangePicker
