// @flow
import * as React from 'react'

import { getValueType, isSameDay } from 'lib/shared/generalUtils'
import handleKeyboardNavigation from 'lib/shared/keyboardNavigation'
import { useLocaleUtils, useLocaleLanguage } from 'lib/shared/hooks'
import clsx from 'clsx'
import { TYPE_RANGE } from 'lib/shared/constants'

const MonthSelector = ({
  activeDate,
  maximumDate,
  minimumDate,
  onMonthSelect,
  isOpen,
  locale,
}: any): React$Element<any> => {
  const monthSelector = React.useRef<HTMLElement | null>(null)

  React.useEffect(() => {
    const classToggleMethod = isOpen ? 'add' : 'remove'

    ;(monthSelector.current: any).classList[classToggleMethod]('-open')
  }, [isOpen])

  const { getMonthNumber, isBeforeDate } = useLocaleUtils(locale)
  const { months: monthsList } = useLocaleLanguage(locale)

  const handleKeyDown = e => {
    handleKeyboardNavigation(e, { allowVerticalArrows: false })
  }

  const renderMonthSelectorItems = () => {
    const valueType = getValueType(activeDate)

    const selectedIndexes = monthsList
      .filter(persianMonth => {
        const monthNumber = getMonthNumber(persianMonth)

        return valueType === TYPE_RANGE
          ? [activeDate.from.month, activeDate.to.month].includes(monthNumber)
          : monthNumber === activeDate.month
      })
      .map(month => getMonthNumber(month))

    const isBetween = n =>
      valueType === TYPE_RANGE && n > selectedIndexes[0] && n < selectedIndexes[1]

    return monthsList.map(persianMonth => {
      const monthNumber = getMonthNumber(persianMonth)
      const monthDate = {
        day: 1,
        month: monthNumber,
        year: valueType === TYPE_RANGE ? activeDate.from.year : activeDate.year,
      }

      const isAfterMaximumDate =
        maximumDate && isBeforeDate(maximumDate, { ...monthDate, month: monthNumber })

      const isBeforeMinimumDate =
        minimumDate &&
        (isBeforeDate({ ...monthDate, month: monthNumber + 1 }, minimumDate) ||
          isSameDay({ ...monthDate, month: monthNumber + 1 }, minimumDate))

      const isSelected = selectedIndexes.includes(monthNumber)

      return (
        <li
          key={persianMonth}
          className={clsx(
            'calendar__month-selector-item',
            isSelected && '-active',
            isBetween(monthNumber) && '-selected-between',
            isSelected && monthNumber === selectedIndexes[0] && '-start',
            isSelected && monthNumber === selectedIndexes[1] && '-end',
            isSelected &&
              (isBetween(monthNumber + 1) || monthNumber === selectedIndexes[1]) &&
              '-mark',
          )}>
          <button
            tabIndex={isSelected && isOpen ? '0' : '-1'}
            onClick={() => {
              onMonthSelect(monthNumber)
            }}
            className="calendar__month-selector-item-text"
            type="button"
            disabled={isAfterMaximumDate || isBeforeMinimumDate}
            aria-pressed={isSelected}
            data-is-default-selectable={isSelected}>
            {persianMonth}
          </button>
        </li>
      )
    })
  }
  return (
    <div
      role="presentation"
      className="calendar__month-selector-animation-wrapper"
      {...(isOpen ? {} : { 'aria-hidden': true })}>
      <div
        role="presentation"
        data-testid="month-selector-wrapper"
        className="calendar__month-selector-wrapper"
        onKeyDown={handleKeyDown}>
        <ul ref={monthSelector} className="calendar__month-selector" data-testid="month-selector">
          {renderMonthSelectorItems()}
        </ul>
      </div>
    </div>
  )
}

export default MonthSelector
