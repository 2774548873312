/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable no-empty-pattern */
// @flow
import clsx from 'clsx'
import * as React from 'react'
import {
  Billboard,
  Badge,
  DataTable,
  Form,
  StatusText,
  Pagination,
  FilterableData,
} from 'components'
import { Col, Container, Row, Button, OverlayTrigger, Popover, Card } from 'react-bootstrap'
import { ReactComponent as SVGSmile } from 'assets/images/svgs/smile.svg'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import CountUp from 'react-countup'

export type HomeProps = { ... }

export const Home = ({}: HomeProps): React$Element<'section'> => {
  const [search, setSearch] = React.useState('')
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })
  const [downloads, setDownloads] = React.useState({
    head: [
      {
        title: 'Nama Dokumen',
        name: 'name',
        sortable: true,
      },
      { title: 'Waktu Pengajuan', name: 'date' },
      { title: 'Estimasi Selesai', name: 'estimation' },
      { title: 'Status', name: 'status' },
      { title: 'Tipe File', name: 'file_type' },
    ],
    data: [
      [
        { content: 1, name: 'id' },
        { content: 'Info Peserta_Alexander-12345_020221', name: 'name', as: 'attendee' },
        { content: '05/02/21', name: 'date' },
        { content: '02/02/21', name: 'estimation' },
        {
          content: <StatusText type="success" label="Siap Diunduh" />,
          name: 'status',
        },
        {
          content: (
            <Badge pill variant="pdfColor">
              PDF
            </Badge>
          ),
          name: 'file_type',
        },
      ],
      [
        { content: 2, name: 'id' },
        { content: 'Perpindahan Peserta_010121-010221', name: 'name', as: 'attendee' },
        { content: '01/02/21', name: 'date' },
        { content: '02/02/21', name: 'estimation' },
        {
          content: <StatusText type="success" label="Siap Diunduh" />,
          name: 'status',
        },
        {
          content: (
            <Badge pill variant="xlsColor">
              XLS
            </Badge>
          ),
          name: 'file_type',
        },
      ],
      [
        { content: 3, name: 'id' },
        { content: 'Laporan Iuran Premi_Rincian-280121', name: 'name', as: 'contribution' },
        { content: '01/02/21', name: 'date' },
        { content: '02/02/21', name: 'estimation' },
        {
          content: <StatusText type="warning" label="Dalam Proses" />,
          name: 'status',
        },
        {
          content: (
            <Badge pill disabled variant="xlsColor">
              XLS
            </Badge>
          ),
          name: 'file_type',
        },
      ],
      [
        { content: 4, name: 'id' },
        {
          content: (
            <span className="d-flex">
              Pengajuan Klaim Tidak Lengkap_All-240121
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={
                  <Popover>
                    <Popover.Content>
                      Terjadi kesalahan terhadap server. Silahkan coba muat ulang dokumen atau
                      hubungi Customer Service (021) 2555 7777 atau melalui email
                      CustomerServiceID@Manulife.com
                    </Popover.Content>
                  </Popover>
                }>
                <i className="mi-alert lg ml-2 cursor-pointer"></i>
              </OverlayTrigger>
            </span>
          ),
          name: 'name',
          as: 'claim',
        },
        { content: '01/02/21', name: 'date' },
        { content: '02/02/21', name: 'estimation' },
        {
          content: <StatusText type="danger" label="Gagal Proses" />,
          name: 'status',
        },
        {
          content: (
            <Badge pill disabled variant="pdfColor">
              PDF
            </Badge>
          ),
          name: 'file_type',
        },
      ],
      [
        { content: 5, name: 'id' },
        { content: 'Info Peserta_Julia-32145_301220', name: 'name', as: 'attendee' },
        { content: '01/02/21', name: 'date' },
        { content: '02/02/21', name: 'estimation' },
        {
          content: <StatusText type="warning" label="Dalam Proses" />,
          name: 'status',
        },
        {
          content: (
            <Badge pill disabled variant="xlsColor">
              XLS
            </Badge>
          ),
          name: 'file_type',
        },
      ],
      [
        { content: 6, name: 'id' },
        { content: 'Pengajuan Klaim Tidak Lengkap_Felicia-240121', name: 'name', as: 'claim' },
        { content: '01/02/21', name: 'date' },
        { content: '02/02/21', name: 'estimation' },
        {
          content: <StatusText type="success" label="Siap diunduh" />,
          name: 'status',
        },
        {
          content: (
            <Badge pill variant="pdfColor">
              PDF
            </Badge>
          ),
          name: 'file_type',
        },
      ],
    ],
  })

  const getColor = name => {
    let color = 'white'
    switch (name) {
      case 'attendee':
        color = 'warning'
        break
      case 'contribution':
        color = 'scarletGum'
        break
      case 'claim':
        color = 'eggBlue'
        break
      default:
        color = 'white'
        break
    }

    return color
  }

  const responsiveTableRender = (content, pagination = null) =>
    !isTabletOrMobile ? (
      <Row className={clsx('mb-4 justify-content-end')}>
        <Col sm={12}>{content}</Col>
        {!!pagination && <Col sm={4}>{pagination}</Col>}
      </Row>
    ) : (
      <div className={clsx('normalize-container-fluid')}>
        {content}
        {pagination}
      </div>
    )

  const handleFilterChanges = (newFilterData: typeof downloads.data) =>
    setDownloads({ ...downloads, data: newFilterData })

  return (
    <Container as="section" fluid>
      <h1 className="font-weight-light display-2 mt-4 mt-md-5">
        Selamat pagi, <span className="font-serif">PT. Sejahtera Abdi Angkasa</span>
      </h1>
      <h2 className="font-weight-light h4">
        No. Grup: <strong>15777</strong>
      </h2>
      <Row className="pt-4">
        <Col md={6}>
          <p className="pr-5 mb-4 font-weight-light">
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
            laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
            architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas
            sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
            voluptatem sequi nesciunt.
          </p>
          <Button as={Link} to="/" variant="anchor">
            Selengkapnya
          </Button>
        </Col>
        <Col md={6}>
          <Row>
            <Col md={6}>
              <Card body className={clsx('mb-4')}>
                <Button
                  as={Link}
                  to="/employer/ballance"
                  variant="anchor"
                  className={clsx('card-anchor')}
                />
                <Row>
                  <Col md={12}>
                    <span className="text-muted">Saldo rekening Anda</span>
                    <CountUp
                      start={0}
                      delay={0}
                      duration={1}
                      end={16316415}
                      formattingFn={val => window.numbro(val).format()}>
                      {({ countUpRef }) => (
                        <h2 className="mt-2 font-weight-bold" ref={countUpRef} />
                      )}
                    </CountUp>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col md={6}>
              <Card body className={clsx('mb-4')}>
                <Button
                  as={Link}
                  to="/employer/claim"
                  variant="anchor"
                  className={clsx('card-anchor')}
                />
                <Row>
                  <Col md={12}>
                    <span className="text-muted">Jumlah Klaim diproses</span>
                    <CountUp start={0} delay={0} duration={1} end={2}>
                      {({ countUpRef }) => (
                        <h2 className="mt-2 font-weight-bold" ref={countUpRef} />
                      )}
                    </CountUp>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={12} className={clsx('mt-4 mt-md-5')}>
          <h2 className="h3 mb-3 font-weight-light">Pusat Notifikasi</h2>
        </Col>
        <Col xs={6} className={clsx('py-3')}>
          <p className="pr-4 font-weight-light">
            A volutpat massa id interdum facilisis dui amet lectus. Tellus nisl neque, et et quam
            non nibh nulla. Mattis a sed gravida tristique.
          </p>
        </Col>
        <Col sm={{ offset: 0, span: 12 }} md={{ offset: 1, span: 5 }} lg={{ offset: 2, span: 4 }} className={clsx('py-3')}>
          <div className="d-flex align-items-center">
            <Form.Input
              value={search}
              onChange={({ currentTarget: { value } }) => setSearch(value)}
              label="Cari Dokumen di sini"
              prepend={<i className="mi-search"></i>}
              formGroupProps={{ className: clsx('w-100') }}
              append={
                !!search && (
                  <Button
                    variant="light4Navy-icon"
                    size="sm"
                    className="mi-cross mt-1"
                    onClick={() => setSearch('')}
                  />
                )
              }
            />
            <FilterableData data={downloads.data} onChanges={handleFilterChanges}>
              {({ events, props, countedFuncs }) => (
                <Button
                  {...events}
                  {...props}
                  size="sm"
                  variant={`rounded-${countedFuncs > 0 ? 'primary' : 'white'}`}
                  className="d-flex align-items-center white-space-nowrap py-2 px-3 px-md-4 ml-3 ml-md-4">
                  <i className={clsx('mi-filter md', !isTabletOrMobile && 'mr-1')}></i>
                  {!isTabletOrMobile && `Filter (${countedFuncs})`}
                </Button>
              )}
            </FilterableData>
          </div>
        </Col>
      </Row>

      {responsiveTableRender(
        <DataTable
          checkable
          headers={downloads.head}
          data={downloads.data}
          dataActions={{
            menu: [
              {
                name: 'download',
                content: (
                  <span className="d-flex">
                    <i className="mi-download lg mr-1"></i> Unduh
                  </span>
                ),
              },
              {
                name: 'delete',
                content: (
                  <span className="d-flex">
                    <i className="mi-trash lg mr-1"></i> Hapus
                  </span>
                ),
              },
            ],
          }}
          rowsOptions={data => ({
            className: clsx(
              'row-border -left',
              `row-border-${getColor((data.find(d => d.name === 'name'): any).as)}`
            ),
          })}
          className={clsx('on-boarding__target')}
        />,
        <Pagination className="mt-4 mt-md-1">
          <Pagination.Prev className="mr-auto" />
          <Pagination.Item active>{1}</Pagination.Item>
          <Pagination.Item>{2}</Pagination.Item>
          <Pagination.Item>{3}</Pagination.Item>
          <Pagination.Ellipsis />
          <Pagination.Item>{10}</Pagination.Item>
          <Pagination.Next className="ml-auto" />
        </Pagination>
      )}

      <Row className={clsx('mt-5 mt-md-4 pt-4 pt-md-0')}>
        <Col sm={12}>
          <h1 className={clsx('display-4', 'font-weight-light', 'mb-3')}>Berita Terkini</h1>
          <p className="pr-md-4 w-md-50 font-weight-light">
            Enim sagittis risus, diam faucibus a tristique justo, vitae, laoreet. Dui eu, urna
            tellus vitae felis mauris. Molestie purus purus turpis urna ut at sit quam.
          </p>
        </Col>
      </Row>

      {responsiveTableRender(
        <DataTable
          checkable
          headers={[
            { title: 'Tanggal', name: 'date' },
            {
              title: 'Informasi',
              name: 'info',
              attrs: {
                width: '70%',
              },
            },
          ]}
          data={[]}
          emptyData={
            <div className={clsx('d-flex', 'justify-content-center', 'align-items-center', 'py-3')}>
              <SVGSmile />
              <p className="mb-0 ml-3 font-weight-light">
                Belum ada berita baru untuk Anda. <br />
                Silahkan periksa lagi nanti.
              </p>
            </div>
          }
        />
      )}

      <div className={clsx('my-4', 'pt-5 pb-3')}>
        <Billboard.Contact />
      </div>
    </Container>
  )
}

export default Home
