// @flow
// eslint-disable-next-line no-unused-vars
import * as React from 'react'
import { Guest } from 'layouts'
import EBClickGuide from './EBClickGuide'

import type { Route } from 'router/routes'

export const pathname = '/guides'
export const route: Route = {
  component: Guest,
  asLayout: true,
  routes: {
    '/': {
      component: EBClickGuide,
    },
  },
}
