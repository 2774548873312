// @flow
import * as React from 'react'

import { isSameDay } from 'lib/shared/generalUtils'
import { useLocaleUtils, useLocaleLanguage } from 'lib/shared/hooks'
import { getSlideDate, animateContent, handleSlideAnimationEnd } from 'lib/shared/sliderHelpers'
import clsx from 'clsx'

const Header = ({
  maximumDate,
  minimumDate,
  onMonthChange,
  activeDate,
  monthChangeDirection,
  onMonthSelect,
  onYearSelect,
  isMonthSelectorOpen,
  isYearSelectorOpen,
  locale,
}: any): React$Element<'div'> => {
  const headerElement = React.useRef<HTMLElement | null>(null)
  const monthYearWrapperElement = React.useRef(null)

  const { getMonthName, isBeforeDate, getLanguageDigits } = useLocaleUtils(locale)
  const {
    isRtl,
    nextMonth,
    previousMonth,
    openMonthSelector,
    closeMonthSelector,
    openYearSelector,
    closeYearSelector,
  } = useLocaleLanguage(locale)

  React.useEffect(() => {
    if (!monthChangeDirection) return
    animateContent({
      direction: monthChangeDirection,
      parent: monthYearWrapperElement.current,
    })
  }, [monthChangeDirection])

  React.useEffect(() => {
    if (!!headerElement.current) {
      const isOpen = isMonthSelectorOpen || isYearSelectorOpen
      const monthText = headerElement.current.querySelector(
        '.calendar__month-year.-shown .calendar__month-text',
      )

      if (!!monthText) {
        const yearText: any = monthText.nextSibling
        const hasActiveBackground = (element: HTMLElement) =>
          element.classList.contains('-activeBackground')

        const isInitialRender =
          !isOpen && !hasActiveBackground(monthText) && !hasActiveBackground(yearText)
        if (isInitialRender) return

        const hasMonthSelectorToggled = isMonthSelectorOpen || hasActiveBackground(monthText)
        const primaryElement = hasMonthSelectorToggled ? monthText : yearText
        const secondaryElement = hasMonthSelectorToggled ? yearText : monthText

        if (!isOpen) {
          secondaryElement.removeAttribute('aria-hidden')
        } else {
          secondaryElement.setAttribute('aria-hidden', 'true')
        }

        secondaryElement.setAttribute('tabindex', isOpen ? '-1' : '0')
        secondaryElement.style.transform = ''
        primaryElement.classList.toggle('-activeBackground')
      }
    }
  }, [isMonthSelectorOpen, isRtl, isYearSelectorOpen])

  const getMonthYearText = isInitialActiveChild => {
    const date = getSlideDate({
      isInitialActiveChild,
      monthChangeDirection,
      activeDate,
      parent: monthYearWrapperElement.current,
    })
    const year = getLanguageDigits(date.year)
    const month = getMonthName(date.month)
    return { month, year }
  }

  const isNextMonthArrowDisabled =
    maximumDate && isBeforeDate(maximumDate, { ...activeDate, month: activeDate.month + 1, day: 1 })
  const isPreviousMonthArrowDisabled =
    minimumDate &&
    (isBeforeDate({ ...activeDate, day: 1 }, minimumDate) ||
      isSameDay(minimumDate, { ...activeDate, day: 1 }))

  const onMonthChangeTrigger = direction => {
    if (!!monthYearWrapperElement.current) {
      const isMonthChanging = Array.from(monthYearWrapperElement.current.children).some(child =>
        child.classList.contains('-shown-animated'),
      )
      if (isMonthChanging) return
      onMonthChange(direction)
    }
  }

  // first button text is the one who shows the current month and year(initial active child)
  const monthYearButtons = [true, false].map(isInitialActiveChild => {
    const { month, year } = getMonthYearText(isInitialActiveChild)
    const isActiveMonth = month === getMonthName(activeDate.month)
    const hiddenStatus = {
      ...(isActiveMonth ? {} : { 'aria-hidden': true }),
    }
    return (
      <div
        onAnimationEnd={handleSlideAnimationEnd}
        className={clsx(
          'calendar__month-year',
          isInitialActiveChild ? '-shown' : '-hidden-next',
        )}
        role="presentation"
        key={String(isInitialActiveChild)}
        {...hiddenStatus}>
        <button
          onClick={onMonthSelect}
          type="button"
          className="calendar__month-text"
          aria-label={isMonthSelectorOpen ? closeMonthSelector : openMonthSelector}
          tabIndex={isActiveMonth ? '0' : '-1'}
          {...hiddenStatus}>
          {month}
        </button>
        <button
          onClick={onYearSelect}
          type="button"
          className="calendar__year-text"
          aria-label={isYearSelectorOpen ? closeYearSelector : openYearSelector}
          tabIndex={isActiveMonth ? '0' : '-1'}
          {...hiddenStatus}>
          {year}
        </button>
      </div>
    )
  })

  return (
    <div ref={headerElement} className="calendar__header">
      <div
        className="calendar__month-year-container"
        ref={monthYearWrapperElement}
        data-testid="month-year-container">
        {monthYearButtons}
      </div>
      <button
        className="calendar__month-arrow-wrapper -right"
        onClick={() => {
          onMonthChangeTrigger('PREVIOUS')
        }}
        aria-label={previousMonth}
        type="button"
        disabled={isPreviousMonthArrowDisabled}>
        <span className="calendar__month-arrow" />
      </button>
      <button
        className="calendar__month-arrow-wrapper -left"
        onClick={() => {
          onMonthChangeTrigger('NEXT')
        }}
        aria-label={nextMonth}
        type="button"
        disabled={isNextMonthArrowDisabled}>
        <span className="calendar__month-arrow" />
      </button>
    </div>
  )
}

export default Header
