// @flow
import * as React from 'react'
import clsx from 'clsx'
import { ReactComponent as LogoIcon } from 'assets/images/svgs/logo/icon.svg'
import { ReactComponent as LogoBrand } from 'assets/images/svgs/logo/brand.svg'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'

// Styles
import styles from './style.module.scss'

const layoutTypes = ['common', 'dashboard']
const layoutTypesObj: any = layoutTypes.reduce(
  (agg, next) => ({ ...(agg: any), [(next: string)]: true }),
  {}
)

type Props = {
  layoutType: $Keys<typeof layoutTypesObj>,
  mode: 'employee' | 'employer',
  langSelector?: boolean,
  appendToNav?: React$Node,
  hideBrand?: boolean,
  logoIcon?: boolean,
  className?: Array<any> | Object | String,
  fluid?: boolean,
  headerRef?: React.Ref<any>,
  navShowed?: boolean,
  onNavToggleClick: (state: boolean) => void,
  ...
}

const Header = ({
  layoutType,
  langSelector,
  appendToNav,
  logoIcon,
  fluid,
  headerRef,
  hideBrand,
  mode,
  navShowed,
  onNavToggleClick,
  ...rest
}: Props): React$Element<'header'> => {
  const isTypeDashboard = layoutType === 'dashboard'

  const toggleNav = status => onNavToggleClick(status)

  return (
    <header
      {...rest}
      ref={headerRef}
      className={clsx(styles.header, isTypeDashboard ? styles.dark : styles.light, rest.className)}>
      {logoIcon && (
        <Link
          className={clsx(
            styles.header__icon,
            isTypeDashboard ? styles.dark__icon : styles.light__icon
          )}
          to={`/${mode}`}>
          <LogoIcon fill={isTypeDashboard ? '#FFF' : '#00a758'} />
        </Link>
      )}
      <div className={clsx(styles.header__brand)}>
        <LogoBrand fill={isTypeDashboard ? '#FFF' : '#34384b'} />
      </div>
      <Button
        variant={isTypeDashboard ? 'dark' : 'light'}
        className={clsx(`mi-${navShowed ? 'cross' : 'hamburger'} lg`, styles.header__toggle)}
        onClick={() => toggleNav(!navShowed)}></Button>
      {isTypeDashboard && (
        <Button
          variant="dark"
          className={clsx(['mi-notification lg', styles.header__notification])}
        />
      )}
    </header>
  )
}

Header.defaultProps = ({
  layoutType: 'common',
  mode: 'employee',
  langSelector: false,
  appendToNav: null,
  logoIcon: true,
  hideBrand: false,
  fluid: false,
  onNavToggleClick: () => {},
}: Props)

export default Header
