// @flow
import BsForm from 'react-bootstrap/Form'
import Input from './Input'
import Radio from './Radio'
import Select from './Select'
import Checkbox from './Checkbox'
import DatePicker from './DatePicker'
import PeriodPicker from './PeriodPicker'
import DateRangePicker from './DateRangePicker'

export type * from './Input'
export type * from './Select'
export type * from './Checkbox'

const Form: typeof BsForm & {
  Input: typeof Input,
  Radio: typeof Radio,
  Select: typeof Select,
  Checkbox: typeof Checkbox,
  DatePicker: typeof DatePicker,
  PeriodPicker: typeof PeriodPicker,
  DateRangePicker: typeof DateRangePicker,
} = BsForm

Form.Input = Input
Form.Radio = Radio
Form.Select = Select
Form.Checkbox = Checkbox
Form.DatePicker = DatePicker
Form.PeriodPicker = PeriodPicker
Form.DateRangePicker = DateRangePicker

export { default as InputGroup } from 'react-bootstrap/InputGroup'
export default Form
