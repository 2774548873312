// @flow

const employee = [
  {
    icon: 'cash',
    label: 'Saldo',
    to: '/employee/ballance',
  },
  {
    icon: 'form',
    label: 'Klaim',
    to: '/employee/claim',
  },
  {
    icon: 'funds',
    label: 'Investasi',
    to: '/employee/investment',
    submenu: [
      {
        label: 'Arahan Investasi',
        to: '/employee/investment/direction',
      },
      {
        label: 'Ubah Arahan Investasi',
        to: '/employee/investment/change-direction',
      },
      {
        label: 'Harga Unit',
        to: '/employee/investment/prices',
      },
      {
        label: 'Harga Nilai Tukar',
        to: '/employee/investment/conversion',
      },
    ],
  },
  {
    icon: 'resources',
    label: 'Panduan',
    to: '/employee/guide',
    submenu: [
      {
        label: 'Tentang EBClick',
        to: '/employee/guide/about',
      },
      {
        label: 'Fitur EBClick',
        to: '/employee/guide/features',
      },
      {
        label: 'Pertanyaan (FAQ)',
        to: '/employee/guide',
      },
      {
        label: 'Form/Download',
        to: '/employee/guide',
      },
    ],
  },
  {
    icon: 'user',
    label: 'Profil',
    to: '/employee/profile',
    submenu: [
      {
        label: 'Profil Saya',
        to: '/employee/profile/me',
      },
      {
        label: 'DPLK Manulife E-Card',
        to: '/employee/profile/e-card',
      },
      {
        label: 'Ubah Kata Sandi',
        to: '/employee/profile/change-password',
      },
    ],
  },
]

const employer = [
  {
    icon: 'spouse',
    label: 'Peserta',
    to: '/employer/attendee',
  },
  {
    icon: 'form',
    label: 'Klaim',
    to: '/employer/claim',
    submenu: [
      {
        label: 'Pengajuan Klaim Tidak Lengkap',
        to: '/employer/claim/incomplete-submission',
      },
      {
        label: 'Laporan Pembayaran Klaim',
        to: '/employer/claim/payment-report',
      },
      {
        label: 'Pemberitahuan Masa Pensiun',
        to: '/employer/claim/pension-notice',
      },
    ],
  },
  {
    icon: 'received',
    label: 'Iuran',
    to: '/employer/contribution',
    submenu: [
      {
        label: 'Informasi Transaksi',
        to: '/employer/contribution/transactions',
      },
      {
        label: 'Laporan Tagihan',
        to: '/employer/contribution/report',
      },
    ],
  },
  {
    icon: 'cash',
    label: 'Saldo',
    to: '/employer/ballance',
    submenu: [
      {
        label: 'Saldo Rekening Perusahaan',
        to: '/employer/ballance/company-account',
      },
      {
        label: 'Saldo Rekening Peserta',
        to: '/employer/ballance/attendee-account',
      },
    ],
  },
  {
    icon: 'funds',
    label: 'Investasi',
    to: '/employer/investment',
    submenu: [
      {
        label: 'Arahan Investasi',
        to: '/employer/investment/direction',
      },
      // {
      //   label: 'Perkembangan Investasi',
      //   to: '/employer/investment/growth',
      // },
      {
        label: 'Harga Unit',
        to: '/employer/investment/prices',
      },
      {
        label: 'Nilai Tukar (US Dollar)',
        to: '/employer/investment/conversion',
      },
    ],
  },
  {
    icon: 'resources',
    label: 'Panduan',
    to: '/employer/guide',
    submenu: [
      {
        label: 'Tentang EBClick',
        to: '/employer/guide/about',
      },
      {
        label: 'Fitur EBClick',
        to: '/employer/guide/features',
      },
      {
        label: 'Pertanyaan (FAQ)',
        to: '/employer/guide',
      },
      {
        label: 'Form/Download',
        to: '/employer/guide',
      },
    ],
  },
  {
    icon: 'user',
    label: 'Profil',
    to: '/employer/profile',
    submenu: [
      {
        label: 'Informasi Perusahaan',
        to: '/employer/profile/company',
      },
      {
        label: 'Informasi Grup',
        to: '/employer/profile/group',
      },
      {
        label: 'Kontak & Rekening Bank',
        to: '/employer/profile/contacts',
      },
      {
        label: 'Ubah Kata Sandi',
        to: '/employer/profile/change-password',
      },
    ],
  },
]

export const routes = { employee, employer }
export default routes
