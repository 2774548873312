// @flow
import * as React from 'react'
import { Redirect } from 'react-router'

import Me from './Me'

import type { Route } from 'router/routes'

export const pathname = '/profile'
export const route: Route = {
  routes: {
    '/company': {
      component: Me,
    },
    '/': {
      component: ({ layoutPath }) => <Redirect to={`${layoutPath}profile/company`} />,
    },
  },
}
