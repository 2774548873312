// @flow

import * as React from 'react'
import { usePlugins } from 'lib/plugins'
import { HashRouter } from 'react-router-dom'
import ReactNotification from 'react-notifications-component'
import RouterView from 'router'

const App = (): React$Element<typeof HashRouter> => {
  const pluginApplied = usePlugins()
  const PUBLIC_PATH = process.env.PUBLIC_PATH

  return (
    <HashRouter basename={PUBLIC_PATH?.concat('/')}>
      <ReactNotification isMobile />
      {pluginApplied && <RouterView />}
    </HashRouter>
  )
}

export default App
