// @flow
import ReactDOM from 'react-dom'
import * as React from 'react'
import clsx from 'clsx'
import { Button, Col, OverlayTrigger, Popover, Row } from 'react-bootstrap'
import { matchPath } from 'react-router'

import styles from './style.module.scss'
import { Link } from 'react-router-dom'
import { has, isEmpty } from 'lodash-es'

type Props = {
  history: any,
  location: any,
  routesBlock: ({}) => React$Element<any>,
  children?: React.Node,
}

export const OnBoarding = ({ children, history, location, ...rest }: Props): React$Element<any> => {
  const child = !!rest.routesBlock ? <rest.routesBlock {...rest} /> : children
  const steps = [
    {
      target: '.on-boarding__target',
      content: (
        <Row>
          <Col sm={4}>
            <i className="mi-form ultra text-onboardingIcon"></i>
          </Col>
          <Col>
            <h1 className={clsx('h5', 'font-weight-bold')}>Mendapatkan Dokumen</h1>
            <p className={clsx('font-weight-light')}>
              Sodales in velit sed tempor curabitur id consectetur nisl. Sollicitudin turpis pretium
              platea augue diam.
            </p>
          </Col>
        </Row>
      ),
      path: '/employer/claim/incomplete-submission',
    },
    {
      target:
        '.on-boarding__target thead, .on-boarding__target tbody tr:nth-child(1), .on-boarding__target tbody tr:nth-child(2)',
      content: (
        <Row>
          <Col sm={4}>
            <i className="mi-download ultra text-onboardingIcon"></i>
          </Col>
          <Col>
            <h1 className={clsx('h5', 'font-weight-bold')}>Mengunduh Dokumen</h1>
            <p className={clsx('font-weight-light')}>
              Sodales in velit sed tempor curabitur id consectetur nisl. Sollicitudin turpis pretium
              platea augue diam.
            </p>
          </Col>
        </Row>
      ),
      path: '/employer/home',
    },
    {
      target: '.on-boarding__target',
      content: (
        <Row>
          <Col sm={4}>
            <i className="mi-badge-dollar ultra text-onboardingIcon"></i>
          </Col>
          <Col>
            <h1 className={clsx('h5', 'font-weight-bold')}>Cek Saldo Rekening</h1>
            <p className={clsx('font-weight-light')}>
              Sodales in velit sed tempor curabitur id consectetur nisl. Sollicitudin turpis pretium
              platea augue diam.
            </p>
          </Col>
        </Row>
      ),
      path: '/employer/ballance',
    },
    {
      target: '.on-boarding__target-2',
      content: (
        <Row>
          <Col sm={4}>
            <i className="mi-form ultra text-onboardingIcon"></i>
          </Col>
          <Col>
            <h1 className={clsx('h5', 'font-weight-bold')}>Lihat Informasi Klaim</h1>
            <p className={clsx('font-weight-light')}>
              Sodales in velit sed tempor curabitur id consectetur nisl. Sollicitudin turpis pretium
              platea augue diam.
            </p>
          </Col>
        </Row>
      ),
      path: '/employer/claim/payment-report',
    },
    {
      target: '.on-boarding__target',
      content: (
        <Row>
          <Col sm={4}>
            <i className="mi-form ultra text-onboardingIcon"></i>
          </Col>
          <Col>
            <h1 className={clsx('h5', 'font-weight-bold')}>Melihat Dana Investasi</h1>
            <p className={clsx('font-weight-light')}>
              Sodales in velit sed tempor curabitur id consectetur nisl. Sollicitudin turpis pretium
              platea augue diam.
            </p>
          </Col>
        </Row>
      ),
      path: '/employer/investment/profile',
    },
  ]

  const [isOnBoarding, setIsOnBoarding] = React.useState(false)
  const [popoverState, setPopoverState] = React.useState(true)
  const [currentStep, setCurrentStep] = React.useState(
    steps.findIndex(step => matchPath(location.pathname, { path: step.path })) + 1,
  )

  const [spotlightProp, setSpotlightProp] = React.useState({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
  })

  React.useEffect(() => {
    const target = has(steps, currentStep - 1) ? steps[currentStep - 1].target : null

    !!target &&
      setTimeout(() => {
        const targets = document.querySelectorAll(target)
        const spotlightProperties = {
          width: 0,
          height: 0,
          top: 0,
          left: 0,
        }

        if (targets.length > 0) {
          targets.forEach(el => {
            const rect = el.getBoundingClientRect()

            // Sizing
            spotlightProperties.height += rect.height
            if (spotlightProperties.width < rect.width) spotlightProperties.width = rect.width
          })

          const rect = targets[0].getBoundingClientRect()

          // Positioning
          if (spotlightProperties.top < rect.top) spotlightProperties.top = rect.top
          if (spotlightProperties.left < rect.left) spotlightProperties.left = rect.left

          setSpotlightProp(spotlightProperties)
          setPopoverState(true)
        }
      }, 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep])

  React.useEffect(() => {
    if (new URLSearchParams(location.search).get('onboarding') === 'start') {
      setIsOnBoarding(true)
    }

    if (isOnBoarding) {
      setPopoverState(false)

      setCurrentStep(
        (steps.findIndex(step => matchPath(location.pathname, { path: step.path })) || 0) + 1,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, isOnBoarding])

  const step = steps[currentStep - 1]

  return (
    <div className={clsx('on-boarding__box')}>
      {child}
      {isOnBoarding &&
        !isEmpty(step) &&
        matchPath(location.pathname, { path: step.path }) &&
        ReactDOM.createPortal(
          <div
            style={{
              zIndex: 9999,
              cursor: 'pointer',
              pointerEvents: 'auto',
              inset: '0px',
              overflow: 'hidden',
              position: 'absolute',
              backgroundColor: 'rgba(12, 13, 19, 0.55)',
              mixBlendMode: 'hard-light',
              height: document.body?.offsetHeight,
            }}>
            <OverlayTrigger
              show={popoverState}
              trigger={['click']}
              placement="auto"
              overlay={
                <Popover style={{ zIndex: 99999, maxWidth: '25rem' }}>
                  <Popover.Title>{step.content}</Popover.Title>
                  <Popover.Content>
                    <Row className={clsx('align-items-center')}>
                      <Col sm={4}>
                        <ul className={clsx(styles.dots)}>
                          {steps.map((item, index) => (
                            <li
                              key={index}
                              className={clsx(currentStep - 1 === index && styles.active)}>
                              <Link to={item.path} />
                            </li>
                          ))}
                        </ul>
                      </Col>
                      {currentStep >= steps.length ? (
                        <Col className="d-flex">
                          <Button
                            size="sm"
                            variant="coral"
                            className="ml-auto"
                            onClick={() => {
                              setIsOnBoarding(false)
                              window.location.href = `/#/employer`
                              window.location.reload()
                            }}>
                            Selesai
                          </Button>
                        </Col>
                      ) : (
                        <Col>
                          <Button
                            size="sm"
                            variant="white"
                            className="mx-1"
                            onClick={() => {
                              setIsOnBoarding(false)
                              window.location.href = `/#/employer`
                              window.location.reload()
                            }}>
                            Lewati
                          </Button>
                          <Button
                            size="sm"
                            variant="coral"
                            className="mx-1"
                            onClick={() => {
                              if (currentStep < steps.length) history.push(steps[currentStep].path)
                            }}>
                            Lanjutkan
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Popover.Content>
                </Popover>
              }>
              <div
                style={{
                  opacity: popoverState ? 1 : 0,
                  position: 'absolute',
                  backgroundColor: 'gray',
                  pointerEvents: 'auto',
                  transition: 'opacity 0.2s ease',
                  ...spotlightProp,
                }}></div>
            </OverlayTrigger>
          </div>,
          document.body,
        )}
    </div>
  )
}

export default OnBoarding
