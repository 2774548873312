// @flow
import * as React from 'react'
import clsx from 'clsx'
import { Billboard } from 'components'
import { Col, Container, Row } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'

// Styles
// import styles from './style.module.scss'

const Profile = (): React$Element<'section'> => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })

  const data = {
    details: [
      {
        label: 'No. Grup',
        value: 15777,
      },
      {
        label: 'Nama Perusahaan',
        value: 'CMPY NM 7354',
      },
      {
        label: 'Kode Perusahaan',
        value: 30668759,
      },
      {
        label: 'NPWP Perusahaan',
        value: '0153353735753000',
      },
    ],
    address: [
      {
        label: 'Alamat Kantor',
        value: 'Jl. Sudirman Kav.47 Jakarta Pusat No.124',
        xs: 12,
      },
      {
        label: 'Kode Pos',
        value: '10720',
        xs: 12,
      },
      {
        label: 'Alamat Penagihan',
        value: '-',
      },
      {
        label: 'Kode Pos',
        value: '-',
      },
    ],
    contact: [
      {
        label: 'Kantor 1',
        value: '021 4537393',
      },
      {
        label: 'Kantor 2',
        value: '-',
      },
      {
        label: 'Fax',
        value: '-',
        xs: 12,
      },
      {
        label: 'Email',
        value: 'info@sejahtera.com',
        xs: 12,
      },
    ],
  }

  return (
    <Container as="section" fluid>
      <Row className="mb-5 mt-3">
        <Col md={12}>
          <h1 className="mb-1">PT. Sejahtera Abdi Angkasa</h1>
        </Col>
        <Col md={12} className="pt-5">
          <Row>
            <Col md={12}>
              <h2 className="mb-4">Data Perusahaan</h2>
            </Col>
            {data.details.map(({ label, value }, index) => (
              <Col key={index} xs={6} className="mb-4">
                <span className="d-block font-weight-light mb-1">{label}</span>
                <h3 className={clsx(isTabletOrMobile ? 'h6' : 'h5')}>{value}</h3>
              </Col>
            ))}
          </Row>
        </Col>
        <Col md={12} className="pt-5">
          <Row>
            <Col md={12}>
              <h2 className="mb-4">Alamat</h2>
            </Col>
            {data.address.map(({ label, value, ...props }, index) => (
              <Col key={index} xs={6} className="mb-4" {...props}>
                <span className="d-block font-weight-light mb-1">{label}</span>
                <h3 className={clsx(isTabletOrMobile ? 'h6' : 'h5')}>{value}</h3>
              </Col>
            ))}
          </Row>
        </Col>
        <Col md={12} className="pt-5">
          <Row>
            <Col md={12}>
              <h2 className="mb-4">Telepon & Email</h2>
            </Col>
            {data.contact.map(({ label, value, ...props }, index) => (
              <Col key={index} xs={6} className="mb-4" {...props}>
                <span className="d-block font-weight-light mb-1">{label}</span>
                <h3 className={clsx(isTabletOrMobile ? 'h6' : 'h5')}>{value}</h3>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <div className={clsx('my-4', 'pt-2 pb-3')}>
        <Billboard.Contact
          label={
            <>
              Pengajuan <br />
              Perubahan Data
            </>
          }
        />
      </div>
    </Container>
  )
}

export default Profile
