// @flow
import * as React from 'react'
import clsx from 'clsx'
import { Billboard, Form, Stepper } from 'components'
import { Button, Card, Col, Container, OverlayTrigger, Popover, Row } from 'react-bootstrap'
import useInvestFields from 'lib/hooks/useInvestFields'
import { values } from 'lodash'
import OTP from './OTP'

// Styles
import styles from './style.module.scss'

export type ChangeDirectionProps = { ... }

export const ChangeDirection = ({
  ...restProps
}: ChangeDirectionProps): React$Element<'section'> => {
  const [verifyWith, setVerifyWith] = React.useState(null)
  const [investValues, setInvestValues] = useInvestFields()
  const totalInvest = values(investValues).reduce((total, val) => total + val, 0)

  const renderInfoPopover = () => (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="top"
      overlay={
        <Popover>
          <Popover.Content>
            Est consectetur sit est enim voluptate ex cupidatat non.
          </Popover.Content>
        </Popover>
      }>
      <i className="mi-info lg ml-1 cursor-pointer"></i>
    </OverlayTrigger>
  )

  const handleInvestFieldChange = (type: $Keys<typeof investValues>) => (
    e: SyntheticEvent<HTMLInputElement>
  ) => {
    const { value } = e.currentTarget
    setInvestValues({ ...investValues, [(type: any)]: parseFloat(!!value ? value : 0) })
  }

  return (
    <Container as="section" className={clsx(styles.wrapper)} fluid>
      <Stepper
        initialStep="verify"
        steps={[
          {
            step: 1,
            label: 'Verifikasi Data',
            key: 'verify',
            meta: {
              disabled: (item, currentStep) => currentStep < item.step,
            },
          },
          {
            step: 2,
            label: 'Isi Formulir',
            key: 'form',
            meta: {
              disabled: (item, currentStep) => currentStep < item.step,
            },
          },
          {
            step: 3,
            label: 'Pengajuan Selesai',
            key: 'done',
            meta: {
              disabled: (item, currentStep) => currentStep < item.step,
              forcePassed: true,
            },
          },
        ]}>
        <Stepper.Content key="verify">
          {({ next }) => (
            <>
              <h1 className="font-weight-light display-2 mb-4 mt-5">
                Halaman <span className="font-serif">Verifikasi</span>
              </h1>
              {verifyWith !== null ? (
                <OTP type={verifyWith} onSubmit={next} />
              ) : (
                <Row className={clsx('pb-5 mb-5')}>
                  <Col sm={12}>
                    <Row>
                      <Col xl={8} md={7}>
                        <h2 className="font-weight-light mb-5 mt-3 h3">
                          Pilih salah satu metode dibawah ini untuk mendapatkan kode verifikasi
                        </h2>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={12} md={5} xl={4}>
                    <Row>
                      <Col xs={6}>
                        <Card
                          as="a"
                          onClick={() => setVerifyWith('email')}
                          className={clsx(styles.cardLink, 'cursor-pointer')}
                          body>
                          <i className="mi-envelope xxl d-block"></i>
                          <span className="d-block">Email</span>
                        </Card>
                      </Col>
                      <Col xs={6}>
                        <Card
                          as="a"
                          onClick={() => setVerifyWith('SMS')}
                          className={clsx(styles.cardLink, 'cursor-pointer')}
                          body>
                          <i className="mi-phone xxl d-block"></i>
                          <span className="d-block">SMS</span>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </>
          )}
        </Stepper.Content>
        <Stepper.Content key="form">
          {({ next, prev }) => (
            <Form>
              <h1 className="font-weight-light display-2 my-5">
                Formulir Perubahan <span className="font-serif">Arah Investasi</span>
              </h1>

              <Card className="my-5 shadow-sm">
                <Card.Header className={clsx('-left')}>
                  <i className={clsx('mi mi-info xl')}></i>
                </Card.Header>
                <Card.Body>
                  <p className="mb-0">
                    For Participant who is registered by Employer, investment option follows the
                    provision in the applicable company regulation. Referring to the Pension Fund
                    Regulation (PDP) and prevailing laws and regulations, DPLK Manulife Indonesia is
                    required to manage the assets of Participants in accordance with the life cycle
                    group so that asset management for Participants who have reached the age of 5
                    (five) years and at the earliest 2 (two) years before the Normal Pension Age is
                    placed on the GRO Money Market Fund and/or GRO Syariah Fund.
                  </p>
                </Card.Body>
              </Card>
              <fieldset>
                <h2 className="font-weight-light mb-4 h3">
                  Saya ingin mengubah arahan investasi saya dengan:
                </h2>
                <Form.Radio
                  checked
                  name="direction"
                  value="option_1"
                  label={
                    <span className="d-flex">
                      Mengubah arahan investasi dari dana yang telah terhimpun
                      {renderInfoPopover()}
                    </span>
                  }
                />
                <Form.Radio
                  name="direction"
                  value="option_2"
                  label={
                    <span className="d-flex">
                      Mengubah arahan investasi untuk iuran masuk yang akan datang
                      {renderInfoPopover()}
                    </span>
                  }
                />
                <Form.Radio
                  name="direction"
                  value="option_3"
                  label={
                    <span className="d-flex">
                      Mengubah keduanya, baik dana yang telah terhimpun maupun iuran masuk yang akan
                      datang
                      {renderInfoPopover()}
                    </span>
                  }
                />
              </fieldset>

              <h2 className="font-weight-light mt-5 mb-4 h3">
                Arahan paket investasi atau kombinasi arahan paket investasi <br /> baru yang saya
                pilih adalah
              </h2>
              <p className="text-muted">
                Isi jumlah persentase paket arahan investasi atau kombinasinya, dimana keseluruhan
                total jumlah persentase wajib 100%
              </p>

              <Row as="fieldset" className="pt-3 pb-4">
                <Col xl={9} md={8} xs={12}>
                  <Row>
                    <Col className={clsx(styles.input__wrapper)}>
                      <span className="vertical-align-top">
                        Dana Penghasilan Tetap (FIX)
                        <span className={clsx('vertical-align-sub')}>{renderInfoPopover()}</span>
                      </span>
                      <Form.Input
                        percentage
                        hasValidation
                        isValid={totalInvest >= 100}
                        isInvalid={totalInvest < 100}
                        formGroupProps={{ className: 'mt-0' }}
                        onChange={handleInvestFieldChange('FIX')}
                        value={investValues.FIX}
                        isAllowed={val => val}
                      />
                    </Col>
                    <Col className={clsx(styles.input__wrapper)}>
                      <span className="vertical-align-top">
                        Dana Saham (SHM)
                        <span className={clsx('vertical-align-sub')}>{renderInfoPopover()}</span>
                      </span>
                      <Form.Input
                        percentage
                        hasValidation
                        isValid={totalInvest >= 100}
                        isInvalid={totalInvest < 100}
                        formGroupProps={{ className: 'mt-0' }}
                        onChange={handleInvestFieldChange('SHM')}
                        value={investValues.SHM}
                        isAllowed={val => val}
                      />
                    </Col>
                    <Col className={clsx(styles.input__wrapper)}>
                      <span className="vertical-align-top">
                        Dana US Dollar (UTD)
                        <span className={clsx('vertical-align-sub')}>{renderInfoPopover()}</span>
                      </span>
                      <Form.Input
                        percentage
                        hasValidation
                        isValid={totalInvest >= 100}
                        isInvalid={totalInvest < 100}
                        formGroupProps={{ className: 'mt-0' }}
                        onChange={handleInvestFieldChange('UTD')}
                        value={investValues.UTD}
                        isAllowed={val => val}
                      />
                    </Col>
                    <Col className={clsx(styles.input__wrapper)}>
                      <span className="vertical-align-top">
                        Dana Pasar Uang (RTD)
                        <span className={clsx('vertical-align-sub')}>{renderInfoPopover()}</span>
                      </span>
                      <Form.Input
                        percentage
                        hasValidation
                        isValid={totalInvest >= 100}
                        isInvalid={totalInvest < 100}
                        formGroupProps={{ className: 'mt-0' }}
                        onChange={handleInvestFieldChange('RTD')}
                        value={investValues.RTD}
                        isAllowed={val => val}
                      />
                    </Col>
                    <Col className={clsx(styles.input__wrapper)}>
                      <span className="vertical-align-top">
                        Dana Syariah (SYH)
                        <span className={clsx('vertical-align-sub')}>{renderInfoPopover()}</span>
                      </span>
                      <Form.Input
                        percentage
                        hasValidation
                        isValid={totalInvest >= 100}
                        isInvalid={totalInvest < 100}
                        formGroupProps={{ className: 'mt-0' }}
                        onChange={handleInvestFieldChange('SYH')}
                        value={investValues.SYH}
                        isAllowed={val => val}
                      />
                    </Col>
                    {totalInvest < 100 && (
                      <Col xs={12}>
                        <span
                          className={clsx('text-danger', 'd-block pr-5', styles.input__wrapper)}>
                          *Total jumlah persentase belum mencapai 100%
                        </span>
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col xl={3} md={4} xs={12}>
                  <Card className={clsx(styles.summaryCard)} body>
                    <span className="text-muted">Total jumlah persentase</span>
                    <h2
                      className={clsx(
                        'mb-4 mt-2 text-xl',
                        totalInvest >= 100 ? 'text-success' : 'text-danger'
                      )}>
                      {totalInvest} %
                    </h2>
                    <p className="mb-1">
                      Arahan Investasi <span className="font-serif">saat ini</span>
                    </p>
                    <Row className="row-cols-5">
                      <Col className="py-1 font-weight-lighter" sm={4}>
                        FIX <br /> <b className={clsx('font-weight-bolder')}>0%</b>
                      </Col>
                      <Col className="py-1 font-weight-lighter" sm={4}>
                        SHM <br /> <b className={clsx('font-weight-bolder')}>100%</b>
                      </Col>
                      <Col className="py-1 font-weight-lighter" sm={4}>
                        UTD <br /> <b className={clsx('font-weight-bolder')}>0%</b>
                      </Col>
                      <Col className="py-1 font-weight-lighter" sm={4}>
                        RTD <br /> <b className={clsx('font-weight-bolder')}>0%</b>
                      </Col>
                      <Col className="py-1 font-weight-lighter" sm={4}>
                        SYH <br /> <b className={clsx('font-weight-bolder')}>0%</b>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              <Form.Checkbox
                className="top-0 mb-5"
                label={
                  <div className="w-75 font-weight-light">
                    <p>
                      Dengan menekan tombol ini saya menyatakan telah mengetahui resiko-resiko dari
                      arahan paket investasi yang saya pilih dalam formulir ini dan bertanggung
                      jawab atas resiko-resiko dari pilihan arahan paket investasi yang saya pilih.
                      Dan saya bertanggungjawab sepenuhnya atas kebenaran dari informasi yang saya
                      berikan dalam formulir ini dan saya setuju untuk tunduk kepada Peraturan Dana
                      Pensiun yang berlaku.
                    </p>
                    <Row>
                      <Col md={3}>Alamat Email</Col>
                      <Col md={9}>: useremail@company.com</Col>
                      <Col md={3}>No. Handphone</Col>
                      <Col md={9}>: 081234567890</Col>
                    </Row>
                  </div>
                }
              />

              <div className="mb-5">
                <Button variant="outline-coral" onClick={() => prev()}>
                  Batal
                </Button>
                <Button
                  variant="coral"
                  disabled={totalInvest < 100}
                  className="ml-2"
                  onClick={() => next()}>
                  Lanjut
                </Button>
              </div>
            </Form>
          )}
        </Stepper.Content>
        <Stepper.Content key="done">
          {({ next }) => (
            <Row>
              <Col sm={12}>
                <h1 className="font-weight-light display-2 mb-5 mt-5">
                  Pengajuan <span className="font-serif">Berhasil</span>
                </h1>
              </Col>
              <Col sm={12} md={10} xl={6}>
                <h2 className="font-weight-light mb-5 h3">
                  Selamat! Pengajuan perubahan arahan investasi Anda berhasil. Arahan investasi Anda
                  akan berubah secara otomatis paling lambat 2x24 jam.
                </h2>
              </Col>
              <Col sm={12} className={clsx('mt-4', 'pt-2')}>
                <Billboard.Contact />
              </Col>
              {/* <div> */}
              {/* </div> */}
            </Row>
          )}
        </Stepper.Content>
      </Stepper>
    </Container>
  )
}

export default ChangeDirection
