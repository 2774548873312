// @flow
import * as React from 'react'
import { OTP as BaseOTP } from 'views'

export const OTP = (props: any): React$Element<typeof BaseOTP> => (
  <BaseOTP
    {...props}
    title="Atur Ulang Kata Sandi"
    nextPath={`${props.layoutPath}/reset-password/set-password`}
  />
)
export default OTP
