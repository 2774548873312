// @flow
import * as React from 'react'
import clsx from 'clsx'
import { Form } from 'components'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Button, Col, OverlayTrigger, Popover } from 'react-bootstrap'

import styles from './style.module.scss'

export const ResetPassword = ({
  parentPath,
  layoutPath,
  history,
}: any): React$Element<'section'> => {
  const { register, handleSubmit, errors } = useForm()

  const onSubmit = () => history.push(`${parentPath}/verification`)

  return (
    <section className={clsx('container', 'py-4', styles.container)}>
      <h1 className="display-1">Atur Ulang Kata Sandi</h1>
      <Form noValidate className={clsx(styles.form)} onSubmit={handleSubmit(onSubmit)}>
        <Form.Row className="align-items-center">
          <Form.Input
            hasGrow
            type="text"
            hasValidation
            appendCursorHelp
            name="attendee_id"
            label="Nomor Peserta"
            isInvalid={!!errors.attendee_id}
            ref={register({ required: true })}
            formGroupProps={{ as: Col, sm: 12 }}
            append={
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={
                  <Popover>
                    <Popover.Content>
                      Sunt veniam excepteur amet tempor deserunt voluptate sint.
                    </Popover.Content>
                  </Popover>
                }>
                <i className="mi-info px-1"></i>
              </OverlayTrigger>
            }
          />
          <Col xs={12}>
            <Button as="button" variant="coral" type="submit">
              Selanjutnya
            </Button>
          </Col>
        </Form.Row>
      </Form>
      <p className={styles.text__register}>
        Belum punya akun EBClick?{' '}
        <Link to={`${layoutPath}/register`} className={clsx('decoration-underlined')}>
          <strong>Daftar Sekarang</strong>
        </Link>
      </p>
    </section>
  )
}

ResetPassword.defaultProps = {}

export default ResetPassword
