// @flow
import * as React from 'react'
import clsx from 'clsx'
import { has, isArray } from 'lodash-es'
import { ProgressBar } from 'react-bootstrap'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

const StepperContent = ({ children, handler }: any): React$Element<'div'> => {
  return <div>{children(handler)}</div>
}

const Stepper = ({ steps, initialStep, ...restProps }: any): React$Element<'div'> => {
  const childrens = isArray(restProps.children) ? restProps.children : [restProps.children]
  const sortedSteps = steps.filter(step => !!step.key).sort((a, b) => a.step - b.step)
  const findStepNumber = key => sortedSteps.find(item => item.key === key).step
  const getStepNumber = numOrKey =>
    typeof numOrKey === 'string' ? findStepNumber(numOrKey) : numOrKey

  const [currentStep, setCurrentStep] = React.useState(getStepNumber(initialStep) || 1)

  const renderStepContent = ({ key, step }) => {
    const content: any = childrens.find(child => child.key === key)

    return (
      currentStep === step && (
        <content.type
          {...content.props}
          key={key}
          handler={{
            prev: () => {
              setCurrentStep(currentStep - 1)
            },
            next: () => {
              setCurrentStep(currentStep + 1)
            },
            goTo: stepOrKey => {
              setCurrentStep(getStepNumber(stepOrKey) || currentStep)
            },
          }}
        />
      )
    )
  }

  const handleStepClick = ({ step }, isDisabled) => e => {
    e.preventDefault()
    !isDisabled && setCurrentStep(step)
  }

  const renderStepsActions = item => {
    const { key, label, step, ...rest } = item

    const isForcePassed =
      has(rest, 'meta') &&
      has(rest.meta, 'forcePassed') &&
      rest.meta.forcePassed &&
      currentStep >= step

    const isDisabled =
      has(rest, 'meta') && has(rest.meta, 'disabled') && rest.meta.disabled(item, currentStep)

    return (
      <li key={key} className="stepper__step">
        <a
          href={`#${key}`}
          aria-label={label}
          disabled={isDisabled}
          onClick={handleStepClick(item, isDisabled)}
          className={clsx(
            'stepper__step__anchor',
            isDisabled && '-disabled',
            currentStep === step && '-active',
            (isForcePassed || currentStep > step) && '-passed'
          )}>
          {label}
        </a>
      </li>
    )
  }

  const stepItemProgress = 100 / (steps.length * 2)

  return (
    <div className="stepper">
      <div className="stepper__steps__wrapper">
        <div className="stepper__steps__wrapper__inner">
          <ul className="stepper__steps">{sortedSteps.map(renderStepsActions)}</ul>
          <ProgressBar
            now={
              stepItemProgress * (currentStep * 2) -
              (currentStep === steps.length ? 0 : stepItemProgress)
            }
          />
        </div>
      </div>
      <SwitchTransition>
        <CSSTransition key={currentStep} timeout={300} classNames="transition-slide">
          <div className={clsx('stepper__step__content')}>
            {[...sortedSteps].map(renderStepContent)}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  )
}

Stepper.Content = StepperContent

export default Stepper
