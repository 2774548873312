// @flow
import * as React from 'react'
import clsx from 'clsx'
import { Form } from 'components'
import { useForm } from 'react-hook-form'
import { Button, Col, OverlayTrigger, Popover } from 'react-bootstrap'

import styles from './style.module.scss'

export const ResetPassword = ({
  parentPath,
  layoutPath,
  history,
}: any): React$Element<'section'> => {
  const { register, handleSubmit, errors, formState } = useForm({ mode: 'all' })

  const onSubmit = () => history.push(`${parentPath}/verification`)

  return (
    <section className={clsx('container', 'py-4', styles.container)}>
      <h1 className="display-1">Atur Ulang Kata Sandi</h1>
      <p className="mb-0">Silakan isi informasi dibawah untuk mengatur ulang kata sandi.</p>
      <Form noValidate className={clsx(styles.form)} onSubmit={handleSubmit(onSubmit)}>
        <Form.Row className="align-items-center">
          <Form.Input
            hasGrow
            type="text"
            hasValidation
            appendCursorHelp
            name="group_id"
            label="Nomor Group"
            isInvalid={!!errors.group_id}
            ref={register({ required: true })}
            formGroupProps={{ as: Col, sm: 12 }}
            append={
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={
                  <Popover>
                    <Popover.Content>
                      Sunt veniam excepteur amet tempor deserunt voluptate sint.
                    </Popover.Content>
                  </Popover>
                }>
                <i className="mi-info px-1"></i>
              </OverlayTrigger>
            }
          />
          <Form.Input
            hasGrow
            type="text"
            hasValidation
            appendCursorHelp
            name="company_code"
            label="Kode Perusahaan"
            isInvalid={!!errors.company_code}
            ref={register({ required: true })}
            formGroupProps={{ as: Col, sm: 12 }}
            append={
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={
                  <Popover>
                    <Popover.Content>
                      Sunt veniam excepteur amet tempor deserunt voluptate sint.
                    </Popover.Content>
                  </Popover>
                }>
                <i className="mi-info px-1"></i>
              </OverlayTrigger>
            }
          />
          <Col sm={12}>
            <Form.Label className="mb-0 label-grow">Tanggal Lahir</Form.Label>
            <Form.Row>
              <Form.Select
                name="date"
                placeholder="Tanggal"
                formGroupProps={{ as: Col, xs: 4, className: 'mt-0' }}
                menuProps={{ className: clsx('w-100') }}
                items={Array.from(Array(31).keys()).map(i => ({ key: i, value: i + 1 }))}
              />
              <Form.Select
                name="month"
                placeholder="Bulan"
                formGroupProps={{ as: Col, xs: 4, className: 'mt-0' }}
                menuProps={{ className: clsx('w-100') }}
                items={[
                  'Januari',
                  'Februari',
                  'Maret',
                  'April',
                  'Mei',
                  'Juni',
                  'Juli',
                  'Agustus',
                  'September',
                  'Oktober',
                  'November',
                  'Desember',
                ].map(month => ({ key: month, value: month }))}
              />
              <Form.Select
                name="year"
                placeholder="Tahun"
                formGroupProps={{ as: Col, xs: 4, className: 'mt-0' }}
                menuProps={{ className: clsx('w-100') }}
                items={Array.from(Array(22).keys()).map(i => ({
                  key: i,
                  value: `20${i.toString().length >= 2 ? i : `0${i}`}`,
                }))}
              />
            </Form.Row>
          </Col>

          <Form.Input
            hasGrow
            type="text"
            hasValidation
            appendCursorHelp
            name="npwp"
            label="NPWP Perusahaan"
            isInvalid={!!errors.npwp}
            ref={register({ required: true })}
            formGroupProps={{ as: Col, sm: 12 }}
            append={
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={
                  <Popover>
                    <Popover.Content>
                      Sunt veniam excepteur amet tempor deserunt voluptate sint.
                    </Popover.Content>
                  </Popover>
                }>
                <i className="mi-info px-1"></i>
              </OverlayTrigger>
            }
          />

          <Col xs={12}>
            <Button as="button" variant="coral" disabled={!formState.isValid} type="submit">
              Selanjutnya
            </Button>
          </Col>
        </Form.Row>
      </Form>
    </section>
  )
}

ResetPassword.defaultProps = {}

export default ResetPassword
