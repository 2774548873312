// @flow
/* eslint-disable react/no-multi-comp */
import clsx from 'clsx'
import * as React from 'react'
import SafeAnchor from 'react-bootstrap/SafeAnchor'

export type PageItemProps = {
  disabled?: boolean,
  active?: boolean,
  activeLabel?: string,
  href?: string,
  className: any,
  children: any,
  style: any,
  ...
}

type PageItemType = React$AbstractComponent<any, PageItemProps> & {
  defaultProps?: any,
}

const defaultProps = {
  active: false,
  disabled: false,
  activeLabel: '(current)',
}

const PageItem: PageItemType = ((React.forwardRef<any, PageItemProps>(
  ({ active, disabled, className, style, activeLabel, children, ...props }: PageItemProps, ref) => {
    const Component: any = active || disabled ? 'span' : SafeAnchor

    return (
      <li ref={ref} style={style} className={clsx(className, 'page-item', { active, disabled })}>
        <Component {...props} className={clsx('page-link')} disabled={disabled}>
          {children}
          {active && activeLabel && <span className="sr-only">{activeLabel}</span>}
        </Component>
      </li>
    )
  },
): any): PageItemType)

PageItem.displayName = 'PageItem'
PageItem.defaultProps = defaultProps

export default PageItem

function createButton(name: string, defaultValue: React$Node, label = name) {
  function Button({ children, ...props }: PageItemProps) {
    return (
      <PageItem {...props} className={clsx(props.className, `page-item__${name.toLowerCase()}`)}>
        <span aria-hidden="true">{children || defaultValue}</span>
        <span className="sr-only">{label}</span>
      </PageItem>
    )
  }

  Button.displayName = name

  return Button
}

export const First: any => React$Element<PageItemType> = createButton(
  'First',
  <i className="mi-double-chevron-left"></i>,
  'First',
)
export const Prev: any => React$Element<PageItemType> = createButton(
  'Prev',
  <i className="mi-chevron-left"></i>,
  'Previous',
)
export const Ellipsis: any => React$Element<PageItemType> = createButton('Ellipsis', '…', 'More')
export const Next: any => React$Element<PageItemType> = createButton(
  'Next',
  <i className="mi-chevron-right"></i>,
  'Next',
)
export const Last: any => React$Element<PageItemType> = createButton(
  'Last',
  <i className="mi-double-chevron-right"></i>,
  'Last',
)
