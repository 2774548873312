// @flow
import clsx from 'clsx'
import * as React from 'react'
import Form from '..'

export type RadioProps = {
  id?: string,
  name?: string,
  checked?: boolean,
  formGroupProps?: any,
  label?: React$Node | string,
  className?: string | Array<any> | Object,
  onChange?: (event: SyntheticEvent<HTMLInputElement>) => void,
  ...
}

export const Radio: React.AbstractComponent<RadioProps, mixed> = React.forwardRef(
  (
    { name, id, onChange, className, label, formGroupProps, checked: checkedProp, ...restProps },
    ref,
  ) => {
    const [checked, setChecked] = React.useState<boolean | typeof undefined>(checkedProp)

    const handleOnChange = (e: SyntheticEvent<HTMLInputElement>): void => {
      console.log('e: ', e)
      setChecked(e.currentTarget.checked)
      typeof onChange === 'function' && onChange(e)
    }

    return (
      <Form.Group {...formGroupProps}>
        <Form.Check.Label htmlFor={id} className={clsx(className)}>
          <Form.Check.Input
            {...restProps}
            id={id}
            ref={ref}
            name={name}
            type="radio"
            defaultChecked={checked}
            onChange={handleOnChange}
          />
          <span className="indicator"></span>
          <span className="indicator__back"></span>
          {!!label && <span className={clsx('description')}>{label}</span>}
        </Form.Check.Label>
      </Form.Group>
    )
  },
)
;(Radio: typeof Form.Group).defaultProps = ({}: RadioProps)

export default Radio
