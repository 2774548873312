// @flow
import clsx from 'clsx'
import * as React from 'react'
import { ChangePassword as BaseChangePassword } from 'views'

// Styles
import styles from './style.module.scss'

export const ChangePassword = (props: {}): React$Element<typeof BaseChangePassword> => (
  <BaseChangePassword {...props} wrapperProps={{ className: clsx(styles.wrapper, 'pb-5 mb-5') }} />
)

export default ChangePassword
