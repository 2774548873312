// @flow
import * as React from 'react'
import clsx from 'clsx'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Accordion } from 'components'
import { useMediaQuery } from 'react-responsive'

// Styles
import styles from './style.module.scss'

export type FeaturesProps = { ... }

export const Features = ({ ...restProps }: FeaturesProps): React$Element<'section'> => {
  const guides = [
    {
      icon: 'briefcase',
      title: 'Informasi Perusahaan',
      subtitle: 'Informasi dan profil perusahaan',
    },
    {
      icon: 'cash',
      title: 'Saldo Rekening Perusahaan',
      subtitle: 'Cek informasi saldo dana pensiun perusahaan',
    },
    {
      icon: 'funds',
      title: 'Dana Investasi Perusahaan',
      subtitle: 'Cek arahan investasi dana pensiun perusahaan',
    },
    {
      icon: 'form',
      title: 'Laporan Pembayaran Klaim',
      subtitle: 'Cari laporan pembayaran klaim perusahaan',
    },
    {
      icon: 'save-file',
      title: 'Laporan Tagihan/Iuran',
      subtitle: 'Cari laporan tagihan/iuran perusahaan',
    },
    {
      icon: 'spouse',
      title: 'Informasi Peserta',
      subtitle: 'Informasi kepesertaan dan mutasi karyawan',
    },
    {
      icon: 'cash',
      title: 'Saldo Rekening Peserta',
      subtitle: 'Cek informasi saldo dana pensiun peserta',
    },
    {
      icon: 'old-people',
      title: 'Masa Pensiun Peserta',
      subtitle: 'Cek pemberitahuan masa pensiun peserta',
    },
    {
      icon: 'long-form',
      title: 'Klaim Tidak Lengkap',
      subtitle: 'Informasi pengajuan klaim yang tidak lengkap',
    },
    {
      icon: 'received',
      title: 'Iuran/Premi dan Biaya',
      subtitle: 'Cek transaksi iuran/premi dan biaya peserta',
    },
  ].map(({ icon, title, subtitle, ...rest }: any) => ({
    name: title.toLowerCase().split(' ').join('-'),
    title: (
      <>
        <i className={clsx(`mi-${icon}`, 'xl', 'ml-2')}></i>
        <span className="d-block font-weight-light h4 mb-0">{title}</span>
        <span className="d-block font-weight-light text-md">{subtitle}</span>
        <i aria-hidden className="accordion-chevron -right"></i>
      </>
    ),
    content: rest?.content || (
      <div className="-eq-padding-x font-weight-light">
        <p>
          Tart souffle cotton candy cotton candy biscuit gummi bears. Dragee tart brownie gummies
          gummies halvah.
        </p>
        <span className="h5">Cara Akses Halaman:</span>
        <ol className="pl-4 mt-3">
          <li>Apple pie sesame snaps.</li>
          <li>Tart souffle cotton candy.</li>
        </ol>
      </div>
    ),
  }))

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })

  const responsiveRowRender = content =>
    !isTabletOrMobile ? (
      <Row className={clsx('mt-4 mb-5')}>
        <Col sm={12}>{content}</Col>
      </Row>
    ) : (
      <div className={clsx('normalize-container-fluid', 'mt-4 mb-5 pb-5')}>{content}</div>
    )

  return (
    <Container as="section" className={clsx(styles.wrapper)} fluid>
      <h1 className="font-weight-light display-2 mb-4">
        Fitur <span className="font-serif">EBClick</span>
      </h1>
      <Row className={clsx('mt-3')}>
        <Col md={7}>
          <p className="mb-4 pr-md-5 mr-md-5 font-weight-light">
            Enim sagittis risus, diam faucibus a tristique justo, vitae, laoreet. Dui eu, urna
            tellus vitae felis mauris. Molestie purus purus turpis urna ut at sit quam.
          </p>
          <Button variant="coral">Lihat Demo Panduan</Button>
        </Col>
      </Row>
      {responsiveRowRender(
        <Accordion.Rowed
          cols={2}
          items={guides}
          itemsPerRow={((guides.length + 1) / 2).toFixed()}
          buttonIcon="element-inside"
          className={clsx('gap-x-lg')}
        />,
      )}
    </Container>
  )
}

export default Features
