// @flow
import * as React from 'react'
import { Redirect } from 'react-router'
import { Authentication } from 'layouts'
import sideBg from 'assets/images/featured-buildings-bg.jpg'

import SignIn from './SignIn'
import * as ResetPassword from './ResetPassword'

import type { Route } from 'router/routes'

export const pathname = '/auth'
export const route: Route = {
  component: props => <Authentication {...props} mode="employer" bgImageSide={sideBg} />,
  asLayout: true,
  routes: {
    '/signin': {
      component: SignIn,
    },
    '/reset-password': {
      routes: {
        '/set-password': {
          component: ResetPassword.Password,
        },
        '/verification': {
          component: ResetPassword.OTP,
        },
        '/': {
          component: ResetPassword.Main,
        },
      },
    },
    '/': {
      component: ({ layoutPath }: any) => <Redirect to={`${layoutPath}/signin`} />,
    },
  },
}
