// @flow
import Prices from './Prices'
import Direction from './Direction'
import Conversion from './Conversion'
import ChangeDirection from './ChangeDirection'

// Types
export type * from './Prices'
export type * from './Direction'
export type * from './Conversion'
export type * from './ChangeDirection'

const Investment = {
  Prices: Prices,
  Direction: Direction,
  Conversion: Conversion,
  ChangeDirection: ChangeDirection,
}

export default Investment
