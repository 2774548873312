// @flow
import clsx from 'clsx'
import * as React from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'

import styles from './style.module.scss'

const Contact = ({ label }: any): React$Element<typeof Card> => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1200px)' })

  return (
    <Card body className={clsx(styles.billboard)}>
      <Row className={clsx(styles.wrapper)}>
        <Col md={4}>
          <h1 className="h6 mb-0">{label}</h1>
        </Col>
        <Col className={clsx(styles.wrapper__info)}>
          {!isTabletOrMobile && (
            <a href="tel:02125557777" className="h4 font-weight-bold text-white d-block mb-1">
              021 2555 7777
            </a>
          )}
          <a href="to:CustomerServiceID@Manulife.com" className={clsx(styles.heading)}>
            <span>CustomerServiceID@Manulife.com</span>
          </a>
        </Col>
      </Row>
      {isTabletOrMobile && (
        <Button variant="white" as="a" href="tel:02125557777" block>
          <strong>021 2555 7777</strong>
        </Button>
      )}
    </Card>
  )
}

Contact.defaultProps = ({
  label: (
    <>
      Layanan <br />
      Customer Service <br />
      untuk Bantuan
    </>
  ),
}: any)

const Billboard = {
  Contact,
}

export default Billboard
