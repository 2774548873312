//@flow
import clsx from 'clsx'
import * as React from 'react'
import { Button, Card, Fade } from 'react-bootstrap'

export type AlertProps = {
  icon?: string,
  children?: React$Node,
  closable?: boolean,
  initialShow?: boolean,
  className?: any,
  ...
}

export const Alert = ({
  children,
  icon,
  closable,
  className,
  initialShow,
  ...restProps
}: AlertProps): React$Element<typeof Card> | null => {
  const [shown, setShown] = React.useState(initialShow)

  return (
    <Fade in={shown} timeout={1000} unmountOnExit>
      <Card className={clsx('shadow-sm', className)}>
        <Button variant="icon" className={clsx('card-close')} onClick={() => setShown(!shown)}>
          <i className={clsx('mi mi-cross')}></i>
        </Button>
        <Card.Header className={clsx('-left', '-bg-danger')}>
          <i className={clsx('mi xl', `mi-${icon ? icon : ''}`)}></i>
        </Card.Header>
        <Card.Body>{children}</Card.Body>
      </Card>
    </Fade>
  )
}

Alert.defaultProps = ({
  icon: 'alert',
  initialShow: true,
}: AlertProps)

export default Alert
