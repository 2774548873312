// @flow
import * as React from 'react'
import ReactDOM from 'react-dom'
import { Calendar } from 'components'
import { Fade } from 'react-bootstrap'
import { has, isFunction, isNull } from 'lodash-es'
import { usePopper } from 'react-popper'
import Form from '..'
import type { InputProps } from '..'

export type DatePickerProps = InputProps & {
  value?: any,
  calendarProps?: any,
  onChange?: (event: any) => void,
  ...
}

export const DatePicker: React.AbstractComponent<DatePickerProps, mixed> = React.forwardRef(
  ({ calendarProps, value: valueProp, ...restProps }, ref) => {
    const [value, setValue] = React.useState<any>(valueProp || null)
    const [parsedValue, setParsedValue] = React.useState<any>(valueProp || null)

    const [calendarVisibility, setCalendarVisiblity] = React.useState(false)

    const [inputElement, setInputElement] = React.useState(null)
    const [referenceElement, setReferenceElement] = React.useState(null)
    const [popperElement, setPopperElement] = React.useState(null)
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
    })

    const handleCloseCalendar = (e?: any): void => {
      setCalendarVisiblity(false)
    }

    const handleCalendarChange = newValue => {
      setValue(newValue)
      handleCloseCalendar()

      !!restProps.onChange && isFunction(restProps.onChange) && restProps.onChange(newValue)
    }

    React.useLayoutEffect(() => {
      const eventHandler = (event: any) => {
        if (referenceElement && !referenceElement.contains(event.target)) {
          if (isNull(popperElement)) handleCloseCalendar(event)
          else if (popperElement && !popperElement.contains(event.target))
            handleCloseCalendar(event)
        }
      }

      document.addEventListener('click', eventHandler)

      return () => {
        document.removeEventListener('click', eventHandler)
      }
    }, [inputElement, popperElement, referenceElement])

    React.useEffect(() => {
      if (!!value) {
        const toNativeDate = date => new Date(date.year, date.month - 1, date.day)

        setParsedValue(window.moment(toNativeDate(value)).format("D MMMM Y"))
      }
    }, [value])

    return (
      <>
        <Form.Input
          {...restProps}
          append={
            <i className="mi-calendar" onClick={() => !!inputElement && inputElement.focus()}></i>
          }
          hasGrow={has(restProps, 'hasGrow') ? restProps.hasGrow : true}
          placeholder={has(restProps, 'placeholder') ? restProps.placeholder : 'Pick a Date'}
          formGroupProps={{ ...restProps.formGroupProps, ref: setReferenceElement }}
          readOnly
          value={parsedValue || ''}
          ref={setInputElement}
          onFocus={() => setCalendarVisiblity(true)}
        />
        {ReactDOM.createPortal(
          <Fade in={calendarVisibility} unmountOnExit>
            <div
              {...attributes.popper}
              ref={setPopperElement}
              style={{ ...styles.popper, zIndex: 9999 }}>
              <Calendar {...calendarProps} value={value} onChange={handleCalendarChange} />
            </div>
          </Fade>,
          document.querySelector('#__manulifeApp'),
        )}
      </>
    )
  },
)
;(DatePicker: typeof Form.Input).defaultProps = ({
  onChange: (value: any) => {},
}: DatePickerProps)

export default DatePicker
