/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable no-empty-pattern */
// @flow
import * as React from 'react'
import clsx from 'clsx'
import CountUp from 'react-countup'
import { Link } from 'react-router-dom'
import { Billboard, DataTable, StatusText } from 'components'
import { Col, Container, Row, Button, Card } from 'react-bootstrap'
import { ReactComponent as SVGSmile } from 'assets/images/svgs/smile.svg'
import { useMediaQuery } from 'react-responsive'

export type HomeProps = { ... }

export const Home = ({}: HomeProps): React$Element<'section'> => {
  const downloads = {
    head: [
      { title: 'Nama Dokumen', name: 'name' },
      { title: 'Waktu Pengajuan', name: 'time' },
      { title: 'Estimasi Selesai', name: 'estimation' },
      { title: 'Status', name: 'status' },
    ],
    data: [
      [
        { content: 1, name: 'id' },
        { content: '24/11/20', name: 'time' },
        { content: '24/11/20', name: 'estimation' },
        { content: 'Personal Account Summary_Jan-Jun20', name: 'name' },
        {
          content: (
            <StatusText
              type="success"
              label="Siap Diunduh"
              append={<Button variant="icon" className={clsx('mi-download lg')} />}
            />
          ),
          name: 'status',
        },
      ],
      [
        { content: 2, name: 'id' },
        { content: 'Bukti Potong Pajak_201126', name: 'name' },
        { content: '24/11/20', name: 'time' },
        { content: '24/11/20', name: 'estimation' },
        {
          content: (
            <StatusText
              type="success"
              label="Siap Diunduh"
              append={<Button variant="icon" className={clsx('mi-download lg')} />}
            />
          ),
          name: 'status',
        },
      ],
      [
        { content: 3, name: 'id' },
        { content: 'Bukti Potong Pajak_191126', name: 'name' },
        { content: '24/11/20', name: 'time' },
        { content: '24/11/20', name: 'estimation' },
        {
          content: <StatusText type="warning" label="Dalam proses" />,
          name: 'status',
        },
      ],
    ],
  }

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })

  const responsiveTableRender = content =>
    !isTabletOrMobile ? (
      <Row>
        <Col sm={12} className={clsx('mb-4')}>
          {content}
        </Col>
      </Row>
    ) : (
      <div className={clsx('normalize-container-fluid')}>{content}</div>
    )

  return (
    <Container as="section" fluid>
      <h1 className="font-weight-light display-2 mb-4 mt-5">
        Selamat pagi, <span className="font-serif">John</span>
      </h1>
      <Row>
        <Col md={6}>
          <p className="pr-5 mb-4 font-weight-light">
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
            laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
            architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas
            sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
            voluptatem sequi nesciunt.
          </p>
          <Button as={Link} to="/" variant="anchor">
            Selengkapnya
          </Button>
        </Col>
        <Col md={6}>
          <Row>
            <Col md={6}>
              <Card body className={clsx('mb-4')}>
                <Button
                  as={Link}
                  to="/employee/ballance"
                  variant="anchor"
                  className={clsx('card-anchor')}
                />
                <Row>
                  <Col md={12}>
                    <span className="text-muted">Saldo rekening Anda</span>
                    <CountUp
                      start={0}
                      delay={0}
                      duration={1}
                      end={16316415}
                      formattingFn={val => window.numbro(val).format()}>
                      {({ countUpRef }) => (
                        <h2 className="mt-2 font-weight-bold" ref={countUpRef} />
                      )}
                    </CountUp>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col md={6}>
              <Card body className={clsx('mb-4')}>
                <Button
                  as={Link}
                  to="/employee/claim"
                  variant="anchor"
                  className={clsx('card-anchor')}
                />
                <Row>
                  <Col md={12}>
                    <span className="text-muted">Jumlah Klaim diproses</span>
                    <CountUp start={0} delay={0} duration={1} end={2}>
                      {({ countUpRef }) => (
                        <h2 className="mt-2 font-weight-bold" ref={countUpRef} />
                      )}
                    </CountUp>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col sm={12} className={clsx('my-4')}>
          <h1 className={clsx('display-4', 'font-weight-light', 'mb-3 mt-5')}>Unduh Dokumen</h1>
          {responsiveTableRender(
            <DataTable
              checkable
              headers={downloads.head}
              data={downloads.data}
              dataActions={{
                menu: [
                  ...(isTabletOrMobile
                    ? [
                        {
                          name: 'download',
                          content: (
                            <span className="d-flex">
                              <i className="mi-download lg mr-1"></i> Unduh
                            </span>
                          ),
                        },
                      ]
                    : []),
                  {
                    name: 'delete',
                    content: (
                      <span className="d-flex">
                        <i className="mi-trash lg mr-1"></i> Hapus
                      </span>
                    ),
                  },
                ],
              }}
            />
          )}
        </Col>
        <Col sm={12} className={clsx('my-4')}>
          <h1 className={clsx('display-4', 'font-weight-light', 'mb-3')}>Berita Terkini</h1>
          <DataTable
            checkable
            headers={[
              { title: 'Tanggal', name: 'date' },
              {
                title: 'Informasi',
                name: 'info',
                attrs: {
                  width: '70%',
                },
              },
            ]}
            data={[]}
            emptyData={
              <div
                className={clsx('d-flex', 'justify-content-center', 'align-items-center', 'py-3')}>
                <SVGSmile />
                <p className="mb-0 ml-3 font-weight-light">
                  Belum ada berita baru untuk Anda. <br />
                  Silahkan periksa lagi nanti.
                </p>
              </div>
            }
          />
        </Col>
      </Row>
      <div className={clsx('my-4', 'pt-2 pb-3')}>
        <Billboard.Contact />
      </div>
    </Container>
  )
}

export default Home
