// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react'
import ReactDOM from 'react-dom'
import clsx from 'clsx'
import { has } from 'lodash'
import { Nav } from 'react-bootstrap'
import { CSSTransition } from 'react-transition-group'
import { Link, matchPath, NavLink, useLocation } from 'react-router-dom'
import { ReactComponent as LogoIcon } from 'assets/images/svgs/logo/icon.svg'
import { ReactComponent as LogoBrand } from 'assets/images/svgs/logo/brand.svg'

// Styles
import styles from './style.module.scss'

type NavItem = {
  to: string,
  label: string,
  icon?: string,
  submenu?: Array<NavItem>,
}

type Props = {
  mode: 'employee' | 'employer',
  position?: 'absolute' | 'fixed' | 'relative' | 'sticky',
  navItems?: Array<NavItem>,
  homeLink?: string,
  onChildNavToggleState: (state: boolean) => any,
  childNavSelector: string,
}

// eslint-disable-next-line no-empty-pattern
const Sidebar = ({
  mode,
  position,
  navItems,
  homeLink,
  onChildNavToggleState,
  childNavSelector,
}: Props): React$Element<'nav'> => {
  const location = useLocation()
  const [childNavs, setChildNavs] = React.useState(null)

  React.useEffect(() => {
    onChildNavToggleState(!!childNavs)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childNavs])

  React.useLayoutEffect(() => {
    const hasChildsNavs = navItems?.filter(
      item =>
        !!matchPath(location.pathname, { path: item.to }) &&
        has(item, 'submenu') &&
        !!item.submenu?.length,
    )

    if (!!hasChildsNavs?.length) setChildNavs(hasChildsNavs[0].submenu)
    else setChildNavs(null)
  }, [location, navItems])

  return (
    <nav className={clsx('sidebar', styles.sidebar)} style={{ position }}>
      <NavLink
        to={homeLink || '/'}
        className={clsx(styles.sidebar__brand)}
        activeClassName={styles.sidebar__brand__active}>
        <LogoIcon fill="#FFF"/>
      </NavLink>
      <Nav as="ul" className={clsx(styles.sidebar__nav)}>
        {navItems?.map((item, k) => {
          const { label, icon, to } = item

          return (
            <Nav.Item as="li" key={k}>
              <Nav.Link
                to={to}
                as={NavLink}
                className={clsx(styles.sidebar__nav__link)}
                activeClassName={styles['has-active']}>
                {!!icon && <i className={clsx(`mi-${icon}`)} />}
                <span className={clsx(styles.sidebar__nav__item__label)}>{label}</span>
              </Nav.Link>
            </Nav.Item>
          )
        })}
      </Nav>
      {!!document.querySelector(childNavSelector) &&
        ReactDOM.createPortal(
          <CSSTransition
            in={!!childNavs?.length}
            timeout={300}
            classNames="transition-slide"
            unmountOnExit>
            <div className={clsx(styles.sidebar__childNav)}>
              <Link
                className={clsx(styles.sidebar__childNav__link, styles.sidebar__childNav__brand)}
                to="/">
                <LogoBrand fill="currentColor" />
              </Link>
              <Nav as="ul">
                {childNavs?.map(({ label, to }, k) => (
                  <Nav.Item as="li" key={k}>
                    <Nav.Link
                      exact
                      to={to}
                      as={NavLink}
                      className={clsx(styles.sidebar__childNav__link)}
                      activeClassName={styles['has-active']}>
                      <span>{label}</span>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </div>
          </CSSTransition>,
          document.querySelector(childNavSelector),
        )}
    </nav>
  )
}

Sidebar.defaultProps = ({
  mode: 'employee',
  position: 'absolute',
  onChildNavToggleState: state => {},
  childNavSelector: '#__manulifeApp',
}: Props)

export default Sidebar
