// @flow
import * as React from 'react'
import BasePagination from 'react-bootstrap/Pagination'
import PageItem, { Ellipsis, First, Last, Next, Prev } from './PageItem'

type PaginationType = React$AbstractComponent<any, any> & {
  First: typeof First,
  Prev: typeof Prev,
  Ellipsis: typeof Ellipsis,
  Item: typeof PageItem,
  Next: typeof Next,
  Last: typeof Last,
}

const Pagination: PaginationType = ((React.forwardRef<any, any>(
  ({ ...restProps }, ref) => <BasePagination {...restProps} />,
): any): PaginationType)

Pagination.First = First
Pagination.Prev = Prev
Pagination.Ellipsis = Ellipsis
Pagination.Item = PageItem
Pagination.Next = Next
Pagination.Last = Last

export default Pagination
